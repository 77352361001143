import { useState, useEffect } from 'react';
import { Avatar } from '../components';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useFuro } from 'furo-react';

const UploadLogo = (props) => {
  const { project, setImage } = props;
  const { getAccessTokenSilently } = useFuro();
  const [token, setToken] = useState(null);
  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
      console.log('Ready to upload');
    };
    getToken();
  }, []);

  const [fileList, setFileList] = useState(
    project
      ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: project.logo,
          },
        ]
      : [],
  );

  const onChange = ({ file: newFile, fileList: newFileList }) => {
    setFileList([newFile]);
    console.log(newFile);
    if (newFile.status === 'done') {
      setImage(newFile.response.url);
    }
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const consoleApiBaseUrl =
    process.env.REACT_APP_CONSOLE_API_BASE_URL ||
    'https://api.furo.one/upload/image';

  return (
    <ImgCrop rotate>
      <Upload
        action={`${consoleApiBaseUrl}/projects/${project?.pid}/upload/logo`}
        name="image"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        style={{ opacity: 1 }}
        headers={{ Authorization: `Bearer ${token}` }}
      >
        {fileList.length < 2 && '+ Upload'}
      </Upload>
    </ImgCrop>
  );
};

export default UploadLogo;
