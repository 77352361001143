import React, { useState, useEffect, createContext } from 'react';
import { useFuro } from 'furo-react';
import { searchTeams } from '../hooks/useAPI';

export const TeamsContext = createContext(null);

/**
 * @summary Billing 모듈 사용을 위한 Teams를 제공하는 Provider
 */
export const TeamsProvider = ({ children }) => {
  const teamsProps = useTeams();

  return (
    <TeamsContext.Provider value={teamsProps}>
      <>{children}</>
    </TeamsContext.Provider>
  );
};

export const useTeams = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { user, getAccessTokenSilently } = useFuro();

  useEffect(async () => {
    try {
      const teams = await searchTeams(user.uid, {
        getToken: getAccessTokenSilently,
      });
      setTeams(teams);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return { teams, error, loading };
};
