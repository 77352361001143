import { useState, useEffect } from 'react';
import { Modal, Upload, notification, Space, Radio, Switch } from 'antd';
import UploadImage from '../UploadImage';
import { createProject, searchTeams } from '../../hooks/useAPI';
import { useFuro } from 'furo-react';
import PresetSetting from './PresetSetting';
import { presets } from '../../data';

export default function CreateProjectModal(props) {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { user, getAccessTokenSilently } = useFuro();
  const [form] = Form.useForm();
  const [preset, setPreset] = useState('slack');

  const requestCreateProject = async () => {
    setConfirmLoading(true);
    try {
      // get values from form
      const {
        name,
        basicLogin = 'magic_link',
        workspace,
      } = await form.validateFields();

      const options = presets.filter((ps) => ps.name === preset)[0].setting;

      const dto = {
        name,
        sign_in_methods: {
          [basicLogin]: { enabled: true, ...options },
        },
        enable_workspace: workspace,
      };

      // call search temas api
      const teams = await searchTeams(user.uid, {
        getToken: getAccessTokenSilently,
      });
      // call create project api
      const { project, error } = await createProject(dto, {
        tid: teams[0].tid,
        getToken: getAccessTokenSilently,
      });
      // handle error
      if (error) {
        console.error(error);
        setConfirmLoading(false);
        notification.error({
          message: '프로젝트 생성에 실패하였습니다.',
          description: '이 현상이 지속될 시 관리자에게 문의하세요',
        });
      }
      // handle success
      else if (project) {
        setConfirmLoading(false);
        setVisible(false);
        notification.success({
          message: '프로젝트가 생성되었습니다.',
          description:
            '생성된 프로젝트의 제목을 클릭하여 자세한 내용을 살펴보세요.',
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: '프로젝트 생성에 실패하였습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        {/* + Create Project */}
        프로젝트 생성
      </Button>
      <Modal
        // title="Create a new project"
        title="새 프로젝트 생성"
        centered
        open={visible}
        onOk={requestCreateProject}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        okText="생성"
        cancelText="취소"
        width={600}
      >
        <FormLayoutDemo form={form} setPreset={setPreset} preset={preset} />
      </Modal>
    </>
  );
}

import { Form, Input } from 'antd';
import { Button } from '../../components';

const FormLayoutDemo = (props) => {
  // const initialValues = {
  //   basicLogin: 'magic_link',
  // };

  return (
    <Form
      form={props.form}
      layout="vertical"
      // initialValues={initialValues}
      requiredMark={true}
    >
      <Space direction="vertical" size={0} style={{ width: '100%' }}>
        <Form.Item
          label="프로젝트 이름"
          required
          help="프로젝트가 생성된 이후에도 변경할 수 있어요."
          name="name"
        >
          <Input placeholder="프로젝트 이름" />
        </Form.Item>
        <Form.Item
          label="워크스페이스"
          name="workspace"
          valuePropName="checked"
          initialValue={false}
          tooltip="서비스에 워크스페이스를 활성화하여 사용자의 권한을 역할 기반으로 부여합니다."
        >
          <Switch placeholder="input placeholder" />
        </Form.Item>
        <Form.Item required label="기본 로그인 방식">
          <PresetSetting {...props} />
        </Form.Item>

        {/* <Form.Item
          required
          label="기본 로그인 방식"
          name="basicLogin"
          // help="매직링크 방식은 회원가입 전환율을 40% 높일 수 있어요."
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="magic_link">매직링크</Radio.Button>
            <Radio.Button value="idpw">ID/PW</Radio.Button>
          </Radio.Group>
        </Form.Item> */}

        {/* <Form.Item required label="Type" name="type" style={{ marginTop: 16 }}>
          <Radio.Group defaultValue="web2" buttonStyle="solid">
            <Radio.Button value="web2">Web2</Radio.Button>
            <Radio.Button value="web3" disabled>
              Web3
            </Radio.Button>
          </Radio.Group>
        </Form.Item> */}
        {/* <Form.Item
          label="Logo"
          name="logo"
          valuePropName="fileList"
          style={{ marginTop: 16 }}
        >
          <UploadImage form={form} />
        </Form.Item> */}
      </Space>
    </Form>
  );
};
