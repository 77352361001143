import { Avatar, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function Logo() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={() => (window.location.href = '/')}
    >
      <Avatar size={50} src="/logo.svg" />
      <Text style={{ color: '#64605F', fontWeight: 600, fontSize: 20 }}>
        Furo
      </Text>
    </div>
  );
}

export default Logo;
