import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import useSWR, { useSWRConfig } from 'swr';
import { hashPlainText } from '../utils/crypto';

export const instance = axios.create({
  baseURL:
    process.env.REACT_APP_CONSOLE_API_BASE_URL ||
    'https://console-api.furo.one',
  // withCredentials: true,
});

export const fetchWithToken = async (url, getToken) => {
  const token = await getToken();

  return instance
    .get(url, {
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.data) return res.data;
      else throw Error(res);
    });
};

export const createTeam = async (name, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance.post(
      `/teams`,
      { name },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    const team = response.data;
    return team;
  } catch (error) {
    throw error;
  }
};

export const searchTeams = async (uid, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance.get(`/teams/search/${uid}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const teams = response.data.result;
    return teams;
  } catch (error) {
    throw error;
  }
};

export function getProjects(tid, { getToken }) {
  if (!getToken) throw Error('Needs params');
  const { data, error } = useSWR(
    [`/projects/search/${tid}`, getToken],
    tid ? fetchWithToken : null,
    { refreshInterval: 1000 },
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function getProject(pid, { getToken }, shouldFetch = true) {
  if (!pid || !getToken) throw Error('Needs params');
  const { data, error } = useSWR(
    shouldFetch ? [`/projects/${pid}`, getToken] : null,
    fetchWithToken,
  );
  return {
    project: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function getSocial(pid, { getToken }) {
  if (!pid || !getToken) throw Error('Needs params');
  const { data, error } = useSWR(
    [`/projects/${pid}`, getToken],
    fetchWithToken,
    {
      refreshInterval: 1000,
    },
  );
  return {
    social: data.social,
    isLoading: !error && !data,
    isError: error,
  };
}

export function getSigninMethods(pid, { getToken }) {
  if (!pid || !getToken) throw Error('Needs params');
  const { data, error } = useSWR(
    [`/projects/${pid}`, getToken],
    fetchWithToken,
    {
      refreshInterval: 1000,
    },
  );
  return {
    methods: data.sign_in_methods,
    isLoading: !error && !data,
    isError: error,
  };
}

export function getIntegration(pid, { getToken }) {
  if (!pid || !getToken) throw Error('Needs params');
  const { data, error } = useSWR(
    [`/projects/${pid}`, getToken],
    fetchWithToken,
    {
      refreshInterval: 1000,
    },
  );
  return {
    integration: data.integration,
    isLoading: !error && !data,
    isError: error,
  };
}

export function getUserCount(pid, { getToken }) {
  if (!pid || !getToken) throw Error('Needs params');
  const { data, error } = useSWR(
    [`/projects/${pid}/users/count`, getToken],
    fetchWithToken,
  );
  return {
    count: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function getUsers(pid, { getToken }) {
  if (!pid || !getToken) throw Error('Needs params');
  const { data, error } = useSWR(
    // TODO: will be aplied by cursor
    [`/projects/${pid}/users?limit=1000`, getToken],
    fetchWithToken,
    { refreshInterval: 3000 },
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}

// 소셜 부분
// export function getSocialCard(pid, {token: accessToken}){
//   const {
//     data: social,
//     loading,
//     error,
//   } = useFetch(`/projects/${pid}`, {token: accessToken})
//   return { social, loading, error};
// }

/*
 * dto: {
 *  name: string,
 *  sign_in_methods: object
 * }
 */
export const createProject = async (dto, { tid, getToken }) => {
  try {
    let teamId = tid;
    if (!teamId) throw Error('Team ID is required');
    const token = await getToken();
    const config = {};
    const response = await instance.post(`/projects?tid=${tid}`, dto, {
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log(response);
    return { project: response.data, error: null };
  } catch (err) {
    console.error(err);
    return { project: null, error: err };
  }
};

export const deleteProject = async (pid, { getToken }) => {
  try {
    if (!pid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'DELETE',
      url: `/projects/${pid}`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        social: {},
      },
    });
    // console.log(response);
    return { res: response, err: null };
  } catch (err) {
    console.error(err);
    return { res: null, err: err };
  }
};

export const deleteUser = async (pid, uid, { getToken }) => {
  try {
    if (!uid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'DELETE',
      url: `/projects/${pid}/users/${uid}`,
      headers: { Authorization: `Bearer ${token}` },
      data: {},
    });
    return { res: response, err: null };
  } catch (err) {
    console.error(err);
    return { res: null, err: err };
  }
};

export const approveUser = async (pid, uid, { getToken }) => {
  try {
    if (!uid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'PUT',
      url: `/projects/${pid}/users/${uid}?action=approve`,
      headers: { Authorization: `Bearer ${token}` },
      data: {},
    });
    return { res: response, err: null };
  } catch (err) {
    console.error(err);
    return { res: null, err: err };
  }
};

// Social

export const updateProject = async (pid, data, { getToken }) => {
  try {
    if (!pid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'PUT',
      url: `/projects/${pid}`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    });
    return [response, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

// Privacy

export const setPrivacy = async (pid, data, { getToken }) => {
  try {
    if (!pid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'PATCH',
      url: `/projects/${pid}/privacy`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    });
    // console.log(response.data.privacy);
    return [response, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

export const setIntegration = async (pid, data, { getToken }) => {
  try {
    if (!pid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'PATCH',
      url: `/projects/${pid}/integration`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    });
    return [response, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

// Analytics

export const setAnalytics = async (pid, data, { getToken }) => {
  try {
    if (!pid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'PATCH',
      url: `/projects/${pid}/analytics`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    });
    // console.log(response.data);
    return [response, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

export const getAnalytics = async (pid, { getToken }) => {
  try {
    if (!pid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'GET',
      url: `/projects/${pid}/analytics`,
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log(response.data);
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

// SWR

// export function getAnalytics(pid, { getToken }) {
//   if (!pid || !getToken) throw Error('Needs params');
//   const { data, error } = useSWR(
//     [`/projects/${pid}/analytics`, getToken],
//     fetchWithToken,
//     {
//       refreshInterval: 1000,
//     },
//   );
//   console.log(data);
//   return {
//     analytics: data,
//     isLoading: !error && !data,
//     isError: error,
//   };
// }

export const updateUser = async (uid, tid, data, { getToken }) => {
  try {
    if (!uid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'PUT',
      url: `/users/${uid}`,
      params: { tid },
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

export const createApiKey = async (pid, tid, { getToken }, opts) => {
  try {
    if (!pid) throw Error('Needs params');
    const token = await getToken();
    let data = opts ?? {
      type: 'admin',
      env: 'live',
    };
    const response = await instance({
      method: 'POST',
      url: `/projects/${pid}/api_keys`,
      params: { tid, ...opts },
      headers: { Authorization: `Bearer ${token}` },
      data,
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

export const getApiKeys = async (pid, tid, { getToken }, opts) => {
  try {
    if (!pid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'GET',
      url: `/projects/${pid}/api_keys`,
      params: { tid, env: 'live', ...opts },
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

export const deleteApiKey = async (pid, apikey, tid, { getToken }) => {
  try {
    if (!pid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'DELETE',
      url: `/projects/${pid}/api_keys/${apikey}`,
      params: { tid },
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

export const updateApiOrigins = async (
  pid,
  apikey,
  tid,
  data,
  { getToken },
) => {
  try {
    if (!pid) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'PUT',
      url: `/projects/${pid}/api_keys/${apikey}`,
      params: { tid },
      headers: { Authorization: `Bearer ${token}` },
      data: {
        api_key_restrictions: {
          app_restriction_type: 'Origin',
          app_restrictions: data,
        },
      },
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

/**
 * @param {string} tid 팀 아이디
 * @returns 팀에 할당된 계정(account) 생성
 */
export const createAccount = async (tid, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'POST',
      url: `/billing/accounts`,
      headers: { Authorization: `Bearer ${token}` },
      data: {
        account_id: tid,
      },
    });
    return [response.data, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
};

/**
 *
 * @param {string} tid 팀 아이디
 * @returns 팀에 할당된 계정(account) 가져오기
 */
export const getAccount = async (tid, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'GET',
      url: `/billing/accounts/${tid}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
};

/**
 *
 * @param {string} accountId 계정 아이디(팀 아이디와 일치)
 * @param {Function} getToken 유저의 JWT를 반환하는 함수
 * @returns 계정 Overview 객체(결제수단, 크레딧 등)
 */
export const getAccountOverview = async (accountId, { getToken }) => {
  try {
    if (!accountId) throw Error('Needs params');
    const token = await getToken();
    const response = await instance({
      method: 'GET',
      url: `/billing/accounts/${accountId}/overview`,
      // params: { tid },
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

/**
 *
 * @param {string} accountId 계정 아이디(팀 아이디와 일치)
 * @param {object} data 카드 등록 정보
 * @returns 등록된 카드 객체(카드번호, 유효기간 등)
 */
export const registerCard = async (
  accountId,
  data,
  { getToken },
  isDefault,
) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'POST',
      url: `/billing/accounts/${accountId}/cards/?set_default=${isDefault}`,
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
};

/**
 *
 * @param {string} accountId 계정 아이디(팀 아이디와 일치)
 * @param {object} data 구독 플랜 코드(ex. PRYP01)
 * @returns 갱신된 플랜 객체
 */
export const changeSubscriptionPlan = async (accountId, data, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'POST',
      url: `/billing/accounts/${accountId}/subscription`,
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
};

/**
 *
 * @param {*} accountId 계정 아이디(팀 아이디와 일치)
 * @param {string} cardId 카드 아이디
 * @returns
 */
export const deleteCard = async (accountId, cardId, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'DELETE',
      url: `/billing/accounts/${accountId}/cards/${cardId}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
};

/**
 *
 * @param {string} accountId
 * @returns 계정이 소유한 카드 목록
 */
export const getCards = async (accountId, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'GET',
      url: `/billing/accounts/${accountId}/cards`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
};

/**
 *
 * @param {string} accountId
 * @param {object} data 업데이트할 계정 정보(ex. 이름, 기본 결제 수단 등)
 * @returns 업데이트된 계정 객체
 */
export const updateAccount = async (accountId, data, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'PUT',
      url: `/billing/accounts/${accountId}`,
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

/**
 *
 * @param {string} accountId
 * @returns 계정의 청구서 목록
 */
export const getInvoices = async (accountId, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'GET',
      url: `/billing/invoices?account_id=${accountId}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
};

/**
 *
 * @param {string} accountId
 * @param {Function} getToken 유저의 JWT를 반환하는 함수
 * @returns 크레딧 잔고(balance) 및 보유한 전체 크레딧을 담은(credits) 객체
 */
export const getCredits = (accountId, { getToken }) => {
  if (!accountId || !getToken) throw Error('Needs params');
  const { data, error } = useSWR(
    [`/billing/accounts/${accountId}/credits`, getToken],
    fetchWithToken,
    { refreshInterval: 1000 },
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

/**
 *
 * @param {string} accountId
 * @param {*} subscriptionPlanCode 플랜 코드(DVMT01 등)
 * @param {*} getToken 유저의 JWT를 반환하는 함수
 * @returns 청구서 미리보기 객체
 */
export const getInvoicePreview = async (
  accountId,
  subscriptionPlanCode,
  { getToken },
) => {
  if (!accountId || !getToken) throw Error('Needs params');
  try {
    const token = await getToken();
    const response = await instance({
      method: 'GET',
      url: `/billing/invoices/preview?subscription_plan_code=${subscriptionPlanCode}&account_id=${accountId}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.error('err :::', err);
    return [null, err];
  }
};

/**
 *
 * @param {*} accountId
 * @param {object} data 카드 아이디(card_id)
 * @param {*} getToken
 * @returns
 */
export const changeDefaultCard = async (accountId, data, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'PUT',
      url: `/billing/accounts/${accountId}/cards/default_card`,
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

/**
 *
 * @param {number} count 조회할 이미지 갯수
 * @param {string} query 검색할 이미지 (ex. coffee)
 * @param {*} getToken
 * @returns
 */
export const getUnsplashImages = async (count, query, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'GET',
      url: `/photos/random?count=${count}&query=${query}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

/**
 *
 * @param {*} downloadLocation
 * @param {*} getToken
 * @returns
 */
export const pickUnsplashImage = async (downloadLocation, { getToken }) => {
  try {
    const token = await getToken();
    const response = await instance({
      method: 'GET',
      url: `/photos/download?download_location=${downloadLocation}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};

/**
 * @summary 유저의 비밀번호를 업데이트함
 * @param {string} uid 유저의 UID
 * @param {object} data {newPassword: string, oldPassword: string, clearPassword:boolean}
 * @param {*} getToken
 * @returns
 */
export const setPassword = async (uid, data, { getToken }) => {
  if (data.oldPassword) {
    data.oldPassword = await hashPlainText(data.oldPassword);
  }
  if (data.newPassword) {
    data.newPassword = await hashPlainText(data.newPassword);
  }
  try {
    const token = await getToken();
    const response = await instance({
      method: 'POST',
      url: `/users/${uid}/password`,
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return [response.data, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
};
