import React from 'react';
import { OverviewProvider, AccountProvider } from '../contexts/BillingContext';
import { General } from '../screens/Settings/tabs';

const GeneralContainer = () => {
  return (
    <AccountProvider>
      <OverviewProvider>
        <General />
      </OverviewProvider>
    </AccountProvider>
  );
};

export default GeneralContainer;
