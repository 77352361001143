import { Row, Col, Typography, Skeleton, Empty, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import CreateProjectModal from './CreateProject';

const { Text } = Typography;
import {
  getProjects,
  createTeam,
  searchTeams,
  getUserCount,
} from '../../hooks/useAPI';
import { Avatar, EnvTag } from '../../components';

import { useFuro } from 'furo-react';

const openNotification = () => {
  notification.info({
    message: `Develop 플랜이 활성화되었습니다.`,
    description: '설정 탭을 확인해 보세요.',
  });
};

function ProjectListContainer() {
  useEffect(() => {
    // openNotification();
  }, []);

  return (
    <div style={{ padding: '0px 20px' }}>
      <Title
        // title="Projects"
        title="내 프로젝트"
        // description="Setup a mobile, web or IoT application to use Furo for
        //     Authentication."
        description="Furo를 사용하여 모바일, 웹, IoT 어플리케이션의 사용자 인증을 구현해보세요."
        right={<CreateProjectModal />}
      />
      <Row
        style={{
          marginTop: 30,
          borderRadius: 8,
        }}
      >
        <Col span={24}>
          <ProjectList />
        </Col>
      </Row>
    </div>
  );
}

import { Card, Statistic } from 'antd';
import { useEffect, useState } from 'react';
import { useTeams } from '../../contexts/TeamsContext';

function ProjectList() {
  const { user, getAccessTokenSilently } = useFuro();
  const { teams, loading, error } = useTeams();

  if (!user.survey?.modified_at) {
    const TYPEFORM_URI =
      process.env.REACT_APP_FURO_TYPEFORM_URI ||
      'https://lwgsu0y2lqm.typeform.com/to/S3upZpwh';
    window.location.href = `${TYPEFORM_URI}#uid=${user.uid}&email=${user.email}`;
  }

  const { data, isLoading, isError } = getProjects(teams[0]?.tid, {
    getToken: getAccessTokenSilently,
  });

  if (isLoading) {
    return (
      <Row gutter={16}>
        {Array(12)
          .fill(0)
          .map((_, index) => (
            <Col key={index} xs={24} sm={24} md={12} lg={8}>
              <Card style={{ marginTop: 16 }}>
                <Skeleton avatar paragraph={{ rows: 2 }} active />
              </Card>
            </Col>
          ))}
      </Row>
    );
  }

  if (!data?.result?.length)
    return <Empty style={{ marginTop: 40 }} description={'No Project'}></Empty>;

  return (
    <Row gutter={16}>
      {data?.result &&
        data?.result.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
    </Row>
  );
}

const Title = (props) => {
  const { title, description, right } = props;
  return (
    <Row align="middle" justify="space-between">
      <Col xs={16} sm={16}>
        <Typography.Title level={2} style={{ marginBottom: 6 }}>
          {title}
        </Typography.Title>
        <Text type="secondary">{description}</Text>
      </Col>
      <Col>{right}</Col>
    </Row>
  );
};

const ProjectCard = ({ project, ...props }) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useFuro();
  const { count, isLoading, isError } = getUserCount(project.pid, {
    getToken: getAccessTokenSilently,
  });

  const _handleClick = () => {
    navigate(`/projects/${project.pid}`);
  };

  return (
    <Col xs={24} sm={24} md={12} lg={8}>
      <Card style={{ marginTop: 16 }} hoverable onClick={_handleClick}>
        <Card.Meta
          avatar={<Avatar raised size={48} src={project.logo} />}
          title={project.name}
          description={<EnvTag project={project} />}
        />
        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={12}>
            <Statistic title="Active Users" value={count} loading={isLoading} />
          </Col>
          <Col span={12}>
            <Statistic title="New Signups" value={count} loading={isLoading} />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default ProjectListContainer;
