import { useState } from 'react';
import { Space, Form, Input } from 'antd';
import { CheckCircleTwoTone, SaveOutlined } from '@ant-design/icons';

const MetamaskLoginForm = ({ initialValues, apiKey, onSave, onChange }) => {
  const [form] = Form.useForm();
  const [currentKey, setCurrentKey] = useState(apiKey);

  const _handleChange = (event) => {
    onChange(event);
    setCurrentKey(event.target.value);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      requiredMark={true}
    >
      <Space direction="vertical" size={20} style={{ width: '100%' }}>
        <Form.Item
          label={'API Key'}
          name="api-key"
          // rules={[
          //   {
          //     required: true,
          //     message: 'Please input your clientId!',
          //   },
          // ]}
          initialValue={apiKey}
        >
          <Input
            value={currentKey}
            onChange={_handleChange}
            addonAfter={
              <a>
                {apiKey && apiKey === currentKey ? (
                  <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ cursor: 'default' }}
                  />
                ) : (
                  <SaveOutlined onClick={() => onSave(currentKey)} />
                )}
              </a>
            }
          />
        </Form.Item>
      </Space>
    </Form>
  );
};

export default MetamaskLoginForm;
