import { useState, useEffect, useContext } from 'react';
import { Space, Form, Typography, Row, Col, Switch, Modal } from 'antd';
import PresetSetting from '../Project/PresetSetting';
import { presets } from '../../data';
import { VisibleContext } from '../Project/SettingModal';

const { Text } = Typography;

const getPreset = (checkedOptionsArray) => {
  const checkedOptionsMap = new Map(checkedOptionsArray);

  const preset = presets.filter((preset) => {
    let flag = false;
    // preset의 setting의 개별 요소가 checkedOptionsMap에 모두 해당되는 경우 true, 하나라도 다를 경우 false 반환
    for (let [k, v] of Object.entries(preset.setting)) {
      flag = checkedOptionsMap.has(k) && checkedOptionsMap.get(k) === v;
      if (!flag) return false;
    }
    return flag;
  });
  return preset[0]?.name ?? 'custom';
};

const MagicLink = (props) => {
  const { visible } = useContext(VisibleContext);
  const [preset, setPreset] = useState(getPreset(Object.entries(props.method)));

  useEffect(() => {
    if (visible === false) {
      setPreset(getPreset(Object.entries(props.method)));
    }
  }, [visible]);

  useEffect(() => {
    const presetOptions = presets.filter((ps) => ps.name === preset)[0]
      ?.setting;
    props.form.setFieldsValue(presetOptions);
  }, [preset]);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={15}>
      {/* <Space direction="vertical" style={{ width: '100%' }}>
        <Text>프리셋</Text>
        <PresetSetting
          // except={['custom']}
          setPreset={setPreset}
          preset={preset}
        />
      </Space> */}
      <FormLayout form={props.form} setPreset={setPreset} {...props} />
    </Space>
  );
};

const FormLayout = (props) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Text>설정</Text>
      <Form
        form={props.form}
        colon={false}
        initialValues={props.method}
        onFieldsChange={(changedFields, allFields) => {
          const checkedOptions = allFields.map((field) => {
            return [field.name[0], !!field.value];
          });
          props.setPreset(getPreset(checkedOptions));
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }} size={15}>
          <FormItem
            name={'allow_otp'}
            label={'OTP 사용'}
            description={`사용자가 이메일에 포함된 코드를 사용하여 로그인할 수 있습니다. 여러
            다른 기기(데스크탑, 모바일, 태블릿에서 동시에)에서 많이 로그인 하는
            경우 사용하는걸 추천드려요.`}
          />
          <FormItem
            name={'show_pw_prompt'}
            label={'비밀번호 프롬프트'}
            description={`로그인 화면에서 사용자가 이메일과 비밀번호 사용하여 로그인할 수 있도록 합니다. 가장 보편적으로 사용되는 로그인 방식입니다.`}
          />
          <FormItem
            name={'use_existing_password'}
            label={'비밀번호 우선 적용'}
            description={`사용자가 비밀번호가 있는 경우, 비밀번호로만 로그인할 수 있도록 합니다.`}
          />
          <FormItem
            name={'allow_ip_detection'}
            label={'IP Detection 사용(Premium)'}
            description={`사용자가 수신한 메일링 서비스의 IP와 실제 서비스에 접속한 IP를 비교하여, 국가가 다른 경우 등 비정상적인 접근에 대해 해당 접속을 차단합니다.`}
          />
        </Space>
      </Form>
    </Space>
  );
};

const FormItem = (props) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }} size={5}>
      <Row>
        <Col span={2}>
          <Form.Item name={props.name} valuePropName="checked" noStyle>
            <Switch size="small" />
          </Form.Item>
        </Col>
        <Col>
          <Text style={{ cursor: 'default' }}>{props.label}</Text>
        </Col>
      </Row>
      <Row>
        <Col offset={2}>
          <Text type="secondary">{props.description}</Text>
        </Col>
      </Row>
    </Space>
  );
};

export default MagicLink;
