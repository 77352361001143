import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { updateUser } from '../hooks/useAPI';
import { useFuro } from 'furo-react';
import { useTeams } from '../contexts/TeamsContext';
import { Loading } from '../components';

const Success = () => (
  <Result
    status="success"
    title="Successfully Logged In!"
    subTitle="If this page does not automatically redirect, please go back to the website to continue."
    extra={[
      <Button type="primary" key="console">
        Go Console
      </Button>,
      <Button key="buy">Buy Again</Button>,
    ]}
  />
);
const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Button type="primary">
        <Link to="/projects">Back Home</Link>
      </Button>
    }
  />
);

/**
 * @summary 온보딩(설문조사) 이후 유저 객체를 업데이트하는 페이지
 */
const SurveySuccess = () => {
  const { getAccessTokenSilently, user } = useFuro();
  const { teams } = useTeams();
  useEffect(async () => {
    if (user && teams) {
      try {
        const data = { survey: { modified_at: new Date() } };
        const [res, error] = await updateUser(user.uid, teams[0].tid, data, {
          getToken: getAccessTokenSilently,
        });
        if (res.survey.modified_at) {
          window.location.href = window.location.origin + '/projects';
        }
      } catch {
        console.error('Something went wrong...');
      } finally {
      }
    }
  }, [user, teams]);
  return <Loading />;
};
export { NotFound, Success, SurveySuccess };
