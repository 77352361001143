import React, { useState } from 'react';
import Preview from './Preview';
import { ContactModal } from '../../../components';

const Captcha = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const _contact = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Preview
        mainTitle={`CAPTCHA 캡챠`}
        tag={`NEW`}
        color={'magenta'}
        subTitle={`웹사이트에 봇이 접근하는걸 막으세요.\n20분이면 됩니다.`}
        description={`별도의 주민번호 또는 개인정보 수집 없이 악성 봇이 접근하는걸 막을 수 있어요.`}
        // disabled
        buttonText={`CAPTCHA 활성화`}
        imgUrl={'../../images/captcha.svg'}
        setStep={_contact}
      />
      <ContactModal isOpen={isModalOpen} setOpen={setModalOpen} />
    </>
  );
};

export default Captcha;
