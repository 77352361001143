import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal, Form, Space, notification, Typography } from 'antd';
import { useFuro } from 'furo-react';
import { Preview } from '../../components';
import { LayoutOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { updateProject } from '../../hooks/useAPI';
import DesignView from '../../views/DesignView';
import mixpanel from 'mixpanel-browser';
import { PRIMARY_COLOR } from '../../data';

const { Text, Link } = Typography;
const { confirm } = Modal;

/**
 *
 * @Summary 로그인 화면 디자인 에디터. 좌측에 Iframe으로 프로젝트의 Auth 화면을 띄우고 우측에 Form으로 디자인을 적용
 */
const DesignModal = (props) => {
  /**
   *
   * @param {object} styleObj
   * @returns 스타일 객체를 받아 URL 반환
   */
  const getUrl = (styleObj) => {
    return `${process.env.REACT_APP_FURO_DOMAIN}/login/${
      props.project.pid
    }?action=preview&style=${encodeURIComponent(
      JSON.stringify(styleObj ?? {}),
    )}`;
  };

  // const { project } = props;

  const { user, getAccessTokenSilently } = useFuro();

  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [initialUrl, setInitialUrl] = useState(
    getUrl({ ...props.project.style, logo: props.project.logo } ?? {}),
  );
  const [previewUrl, setPreviewUrl] = useState(initialUrl);
  const [project, setProject] = useState(props.project);

  const _hasChanges = () => {
    return initialUrl !== previewUrl;
  };

  const _onSaveChanges = async () => {
    const { pid } = props.project;
    setConfirmLoading(true);
    try {
      // get values from form
      const values = await form.validateFields();

      console.log(values);

      // // call update project api
      const [response, error] = await updateProject(
        pid,
        { style: { ...props.project.style, ...values }, logo: values.logo },
        {
          getToken: getAccessTokenSilently,
        },
      );

      // // handle error
      if (error) {
        console.error(error);
        // setConfirmLoading(false);
        const { message } = error.response.data;
        notification.error({
          message: '디자인 설정변경에 실패하였습니다.',
          description: JSON.stringify(message),
        });
      }
      // // handle success
      else if (response.data) {
        // setConfirmLoading(false);
        notification.success({
          message: '디자인 설정변경이 완료 되었습니다.',
          description: (
            <Space direction="vertical" size={5}>
              <Text>변경된 디자인을 바로 확인해 보세요.</Text>
              <Button
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_FURO_DOMAIN}/login/${props.project.pid}`,
                  )
                }
                type="primary"
              >
                변경된 화면 보러가기
              </Button>
              {/* <Link
                href={`${process.env.REACT_APP_FURO_DOMAIN}/login/${props.project.pid}`}
                target={'_blank'}
              >
                로그인 화면 Go
              </Link> */}
            </Space>
          ),
        });
        setProject(response.data);
        setInitialUrl(previewUrl);
        setHasCompleted(true);
        setTimeout(() => setHasCompleted(false), 3000);
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: '디자인 설정변경에 실패하였습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    } finally {
      setConfirmLoading(false);
    }
  };

  const update = (styleObj) => {
    setPreviewUrl(getUrl(styleObj));
    // console.log('update: ', getUrl(styleObj));
  };

  const _onCloseModal = () => {
    if (_hasChanges()) showConfirmModal(_close);
    else _close();
  };

  const _close = () => {
    mixpanel.track('Design Modal Opened', {
      UID: user.uid,
      distinct_id: user.uid,
    });
    form.resetFields();
    setPreviewUrl(initialUrl);
    props.setVisible(false);
  };

  return (
    <>
      {/* <Button
        onClick={() => {
          setVisible(true);
        }}
      >
        디자인 모달
      </Button> */}
      <Modal
        title={
          <Header
            onOk={_onSaveChanges}
            onCancel={_onCloseModal}
            confirmLoading={confirmLoading}
            hasCompleted={hasCompleted}
            isStyleChanged={_hasChanges()}
          />
        }
        centered
        open={props.visible}
        width={'90%'}
        closable={false}
        footer={null}
        onCancel={_onCloseModal}
        wrapClassName="design-modal"
      >
        <div style={{ display: 'flex' }}>
          <div
            style={{
              flex: 'auto',
            }}
          >
            <Preview previewUrl={previewUrl} />
          </div>
          <div
            style={{
              overflowY: 'auto',
              height: '80vh',
              width: 300,
              padding: 16,
              borderLeft: '1px solid #eee',
            }}
          >
            <DesignView.FormLayout
              form={form}
              update={update}
              project={project}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const showConfirmModal = (onOk) => {
  confirm({
    title: '변경 사항이 저장되지 않았어요.',
    icon: <ExclamationCircleOutlined />,
    content: '지금 나가시면 변경한 사항들이 반영되지 않습니다.',
    onOk: onOk,
    onCancel() {},
    okText: '확인',
    cancelText: '취소',
    centered: true,
  });
};

const Header = (props) => {
  const getButtonText = () => {
    if (props.confirmLoading) {
      return '저장중';
    }
    if (props.hasCompleted) {
      return '저장 완료';
    }
    if (props.isStyleChanged) {
      return '저장하기';
    } else {
      return '최신 상태';
    }
  };

  return (
    <div style={styles.header}>
      <Text style={{ color: '#1890FF' }}>
        <LayoutOutlined style={{ marginRight: 5 }} />
        Furo Design Editor
      </Text>
      <Space>
        <Button style={{ width: 100 }} onClick={props.onCancel}>
          나가기
        </Button>
        <Button
          type="primary"
          style={{
            ...styles.btn,
            backgroundColor: props.hasCompleted
              ? '#52c41a'
              : props.isStyleChanged
              ? PRIMARY_COLOR
              : '#91d5ff',
          }}
          onClick={props.onOk}
          loading={props.confirmLoading}
          disabled={!props.isStyleChanged || props.hasCompleted}
        >
          {getButtonText()}
        </Button>
      </Space>
    </div>
  );
};

export default DesignModal;

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    padding: 15,
    borderBottom: '1px solid #eeeeee',
  },
  btn: {
    color: '#fff',
    border: 'none',
    width: 100,
  },
};
