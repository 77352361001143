import { useState } from 'react';
import { Space, Form, Input, Typography } from 'antd';
import { CheckCircleTwoTone, CopyTwoTone } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const { Password } = Input;

const { Paragraph } = Typography;

const CommonLoginForm = ({
  platformName,
  method,
  onSave,
  onChange,
  onSecretChange,
}) => {
  const { client_id, client_secret } = method;
  const [form] = Form.useForm();
  const [currentKey, setCurrentKey] = useState(client_id);
  const [currentSecret, setCurrentSecret] = useState(client_secret);
  const [isHovered, setIsHovered] = useState([false, false]);
  const [isCopied, setIsCopied] = useState([false, false]);
  let { projectId } = useParams();

  const handleCopyClick = (index) => {
    let arr = [false, false];
    arr[index] = true;
    setIsCopied(arr);
    setTimeout(() => {
      arr[index] = false;
      setIsCopied(arr);
    }, 3000);
  };

  const handleMouseEnter = (index) => {
    let arr = [false, false];
    arr[index] = true;
    setIsHovered(arr);
  };

  const handleMouseLeave = () => {
    setIsHovered([false, false]);
  };

  const _handleKeyChange = (event) => {
    onChange(event);
    setCurrentKey(event.target.value);
  };

  const _handleSecretChange = (event) => {
    onSecretChange(event);
    setCurrentSecret(event.target.value);
  };

  return (
    <Form form={form} layout="vertical" initialValues={{}} requiredMark={true}>
      <Space direction="vertical" size={20} style={{ width: '100%' }}>
        <Form.Item
          label={platformName === 'kakao' ? 'REST API Key' : 'Client ID'}
          name="cliend-id"
          initialValue={client_id}
          required={true}
        >
          <Input
            value={currentKey}
            onChange={_handleKeyChange}
            autoComplete={'off'}
          />
        </Form.Item>
        {platformName !== 'kakao' && (
          <Form.Item
            label={'Client Secret'}
            name="client_secret"
            initialValue={method.client_secret}
            required={true}
            style={{ marginBottom: 0 }}
          >
            <Password
              value={currentSecret}
              onChange={_handleSecretChange}
              autoComplete={'off'}
            />
          </Form.Item>
        )}
        <Form.Item
          label={'Furo Redirect URL'}
          name="redirect-url"
          initialValue={client_id}
          style={{ marginBottom: 0 }}
        >
          <div style={{ position: 'relative' }}>
            <Paragraph>
              {isCopied[0] ? (
                <CheckCircleTwoTone
                  style={{
                    position: 'absolute',
                    top: '7px',
                    right: '5px',
                  }}
                />
              ) : (
                isHovered[0] === true && (
                  <CopyTwoTone
                    style={{ position: 'absolute', top: '7px', right: '5px' }}
                  />
                )
              )}
              <pre
                onMouseEnter={() => handleMouseEnter(0)}
                onMouseLeave={handleMouseLeave}
                style={{
                  cursor: isHovered[0] ? 'pointer' : 'default',
                }}
                onClick={(v) => {
                  navigator.clipboard.writeText(v.currentTarget.textContent);
                  handleCopyClick(0);
                }}
              >
                {`${process.env.REACT_APP_FURO_API_BASE_URL}/oauth/callback/${projectId}`}
              </pre>
            </Paragraph>
          </div>
        </Form.Item>
        {method.login_url && (
          <Form.Item
            label={'Implement OAuth'}
            name="oauth_login_url"
            style={{ marginBottom: 0 }}
            tooltip={{
              title: `아래의 URL을 복사하여 어플리케이션의 ${platformName} 로그인 버튼에
                임베딩 하세요.`,
              placement: 'right',
            }}
          >
            <div style={{ position: 'relative' }}>
              <Paragraph>
                {isCopied[1] ? (
                  <CheckCircleTwoTone
                    style={{
                      position: 'absolute',
                      top: '7px',
                      right: '5px',
                    }}
                  />
                ) : (
                  isHovered[1] && (
                    <CopyTwoTone
                      style={{ position: 'absolute', top: '7px', right: '5px' }}
                    />
                  )
                )}
                <pre
                  onMouseEnter={() => handleMouseEnter(1)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    cursor: isHovered[1] ? 'pointer' : 'default',
                  }}
                  onClick={(v) => {
                    navigator.clipboard.writeText(v.currentTarget.textContent);
                    handleCopyClick(1);
                  }}
                >
                  {method.login_url}
                </pre>
              </Paragraph>
            </div>
          </Form.Item>
        )}
      </Space>
    </Form>
  );
};

export default CommonLoginForm;
