import React, { useState, useContext } from 'react';
import { Card, Typography, Table, Tag, Space, notification } from 'antd';
import PaymentMethodModal from './PaymentMethodModal';
import { OverviewContext, AccountContext } from '../../contexts/BillingContext';
import moment from 'moment';
import { deleteCard, changeDefaultCard } from '../../hooks/useAPI';

const { Text, Title } = Typography;

const PaymentMethod = ({ method }) => {
  const [visible, setVisible] = useState(false);
  const {
    data: overview,
    setData: setOverview,
    getAccessTokenSilently,
  } = useContext(OverviewContext);
  const { data: account, setData: setAccount } = useContext(AccountContext);

  const onCardDelete = async (cardId) => {
    try {
      const [cards, error] = await deleteCard(account.account_id, cardId, {
        getToken: getAccessTokenSilently,
      });
      if (error) {
        notification.error({
          message: '결제수단을 삭제하는 데 실패했습니다.',
          description: '이 현상이 지속될 시 관리자에게 문의하세요',
        });
      } else if (cards) {
        setOverview({ ...overview, cards });
        notification.success({
          message: '결제수단이 성공적으로 삭제되었습니다.',
        });
      }
    } catch (e) {
      console.error(e);
      notification.error({
        message: '결제수단을 삭제하는 데 실패했습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    }
  };

  const onCardUpate = async (cardId) => {
    try {
      // const [updatedAccount, error] = await updateAccount(
      //   account.account_id,
      //   {
      //     default_card_id: cardId,
      //   },
      //   {
      //     getToken: getAccessTokenSilently,
      //   },
      // );
      const [updatedCards, error] = await changeDefaultCard(
        account.account_id,
        {
          card_id: cardId,
        },
        {
          getToken: getAccessTokenSilently,
        },
      );
      if (error) {
        notification.error({
          message: '결제수단을 기본으로 설정하는 데 실패했습니다.',
          description: '이 현상이 지속될 시 관리자에게 문의하세요',
        });
      } else if (updatedCards) {
        // setAccount(updatedAccount);
        setOverview({ ...overview, cards: updatedCards });
        notification.success({
          message: '기본 결제수단이 성공적으로 설정되었습니다.',
        });
      }
    } catch (e) {
      console.error(e);
      notification.error({
        message: '결제수단을 기본으로 설정하는 데 실패했습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    }
  };

  const paymentColumns = [
    {
      title: '구분',
      dataIndex: 'card_name',
      key: 'card_name',
      render: (cardName, record) => (
        <Text>
          {cardName} (****{record.last_4digit})
        </Text>
      ),
      width: '40%',
    },
    {
      title: '기본 수단',
      dataIndex: 'is_default',
      key: 'is_default',
      render: (isDefault) => (isDefault ? <Tag>기본</Tag> : <></>),
      width: '20%',
    },
    {
      title: '카드 유효기간',
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      width: '20%',
      render: (date) => moment(date).format('MM/YY'),
    },
    {
      title: '',
      dataIndex: 'opt',
      key: 'opt',
      render: (_, record) =>
        record.is_default ? (
          <></>
        ) : (
          <Space size="middle">
            <a onClick={() => onCardUpate(record.card_id)}>기본으로 설정</a>
            <a onClick={() => onCardDelete(record.card_id)}>삭제</a>
          </Space>
        ),
      width: '20%',
    },
  ];

  return (
    <div style={styles.section}>
      <Title level={5}>결제 수단</Title>
      <Card bodyStyle={{ padding: 0 }}>
        <Table
          columns={paymentColumns}
          dataSource={method}
          footer={() => (
            <PaymentMethodModal
              button={true}
              visible={visible}
              setVisible={setVisible}
              isDefault={overview.cards.length === 0}
            />
          )}
        />
      </Card>
    </div>
  );
};

export default PaymentMethod;

const styles = {
  section: { margin: '20px 0px' },
};
