import {
  Table,
  Tag,
  Space,
  Descriptions,
  Popconfirm,
  notification,
  Tabs,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { elapsedTime, truncateEthAddress } from '../../../utils/util';

import { useEffect, useState } from 'react';
import {
  deleteUser,
  approveUser,
  getProject,
  getUsers,
} from '../../../hooks/useAPI';
import { useFuro } from 'furo-react';
import { Button } from '../../../components';

export default function UserList(props) {
  const { projectId, userTab } = useParams();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useFuro();
  const { project, loading, error } = getProject(projectId, {
    getToken: getAccessTokenSilently,
  });
  const { data, isLoading, isError } = getUsers(projectId, {
    getToken: getAccessTokenSilently,
  });
  const [activeUsers, setActiveUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [inactiveUsers, setInactiveUsers] = useState([]);

  useEffect(() => {
    const classifyUserByStatus = async () => {
      if (!data) return;
      const activeUserList = [];
      const pendingUserList = [];
      const inactiveUserList = [];
      await Promise.all(
        data?.result.map((user) => {
          switch (user?.status) {
            case 'active':
              activeUserList.push(user);
              break;
            case 'pending':
              pendingUserList.push(user);
              break;
            case 'inactive':
              inactiveUserList.push(user);
              break;
            default:
              activeUserList.push(user);
          }
        }),
      );
      setActiveUsers(activeUserList);
      setPendingUsers(pendingUserList);
      setInactiveUsers(inactiveUserList);
    };
    classifyUserByStatus();
  }, [data]);

  useEffect(() => {
    if (project.type === 'web3') columns.unshift(addressColumn);
  }, [project]);

  const addressColumn = {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: (text) => (
      <a
        onClick={async () => {
          await navigator.clipboard.writeText(text);
        }}
        style={{ fontFamily: 'monospace' }}
      >
        {truncateEthAddress(text)}
      </a>
    ),
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'name',
    },
    // {
    //   title: 'Roles',
    //   key: 'tags',
    //   dataIndex: 'tags',
    //   render: (tags) => (
    //     <>
    //       {tags &&
    //         tags.map((tag) => {
    //           let color = tag.length > 5 ? 'geekblue' : 'green';
    //           if (tag === 'admin') color = 'volcano';
    //           else if (tag === 'owner') color = 'purple';
    //           return (
    //             <Tag color={color} key={tag}>
    //               {tag.toUpperCase()}
    //             </Tag>
    //           );
    //         })}
    //     </>
    //   ),
    // },
    //  소셜 부분
    {
      title: 'Social',
      dataIndex: 'social',
      key: 'social',
      render: (social) => {
        let color;
        if (social === 'google') color = 'magenta';
        else if (social === 'naver') color = 'green';
        else if (social === 'kakao') color = 'gold';
        else if (social === 'facebook') color = 'geekblue';
        else if (social === 'metamask') color = 'orange';
        return social ? (
          <Tag color={color} key={social}>
            {social?.toUpperCase()}
          </Tag>
        ) : null;
      },
    },
    {
      title: 'Latest Login',
      dataIndex: 'last_sign_in_at',
      key: 'last_sign_in_at',
      render: (text, record) => (
        <Space size="middle">
          <span>{elapsedTime(text)}</span>
        </Space>
      ),
    },
    {
      title: 'Workspace',
      dataIndex: 'workspaces',
      key: 'workspaces',
      render: (workspaces) => {
        const workspaceName =
          workspaces[0] != null
            ? workspaces.length > 1
              ? workspaces[0].name + ' 외 ' + (workspaces.length - 1)
              : workspaces[0].name
            : '미설정';
        return (
          <Space size="middle">
            <span>{workspaceName}</span>
          </Space>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="유저를 삭제하시겠습니까?"
            okType={'danger'}
            okText={'삭제'}
            cancelText={'취소'}
            onConfirm={async () => {
              const { res, err } = await deleteUser(project.pid, text.uid, {
                getToken: getAccessTokenSilently,
              });
              if (err) {
                return notification.error({
                  message: '유저를 삭제하는데 실패하였습니다.',
                  description: '이 현상이 지속될 시 관리자에게 문의하세요',
                });
              } else {
                return notification.success({
                  message: '유저 삭제를 완료 되었습니다.',
                });
              }
            }}
          >
            <a>Delete</a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onApproveUser = async (text) => {
    const { res, err } = await approveUser(project.pid, text.uid, {
      getToken: getAccessTokenSilently,
    });
    if (err) {
      return notification.error({
        message: '유저를 승인하는데 실패하였습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    } else {
      const index = pendingUsers.findIndex(
        (element) => element.uid === text.uid,
      );
      // setPendingUsers((prev) => prev.splice(index, 1));
      pendingUsers.splice(index, 1);
      // setActiveUsers((prev) => {
      //   const pushed = prev.unshift(text);
      //   return pushed;
      // });
      activeUsers.unshift(text);
      return notification.success({
        message: '유저 승인을 완료 되었습니다.',
      });
    }
  };
  const pendingColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'name',
    },
    {
      title: 'Social',
      dataIndex: 'social',
      key: 'social',
      render: (social) => {
        let color;
        if (social === 'google') color = 'magenta';
        else if (social === 'naver') color = 'green';
        else if (social === 'kakao') color = 'gold';
        else if (social === 'facebook') color = 'geekblue';
        else if (social === 'metamask') color = 'orange';
        return social ? (
          <Tag color={color} key={social}>
            {social?.toUpperCase()}
          </Tag>
        ) : null;
      },
    },
    {
      title: 'Latest Signup',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, record) => (
        <Space size="middle">
          <span>{elapsedTime(text)}</span>
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="유저를 삭제하시겠습니까?"
            okType={'danger'}
            okText={'삭제'}
            cancelText={'취소'}
            onConfirm={async () => {
              const { res, err } = await deleteUser(project.pid, text.uid, {
                getToken: getAccessTokenSilently,
              });
              if (err) {
                return notification.error({
                  message: '유저를 삭제하는데 실패하였습니다.',
                  description: '이 현상이 지속될 시 관리자에게 문의하세요',
                });
              } else {
                const index = pendingUsers.findIndex(
                  (element) => element.uid === text.uid,
                );
                setPendingUsers((prev) => prev.splice(index, 1));
                return notification.success({
                  message: '유저 삭제를 완료 되었습니다.',
                });
              }
            }}
          >
            <Button type={'default'} danger style={{ height: 'auto' }}>
              요청삭제
            </Button>
          </Popconfirm>
          <Button
            type={'primary'}
            style={{ height: 'auto' }}
            onClick={() => onApproveUser(text)}
          >
            가입승인
          </Button>
        </Space>
      ),
    },
  ];

  const UserTable = ({ users, columns }) => {
    return (
      <Table
        rowKey={(user) => user.uid}
        loading={isLoading}
        dataSource={users}
        columns={columns}
        pagination={{ showSizeChanger: true }}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <Descriptions title={'User Info'} bordered>
        //       <Descriptions.Item label={'name'}>{record.name}</Descriptions.Item>
        //       <Descriptions.Item label={'age'}>{record.age}</Descriptions.Item>
        //       <Descriptions.Item label={'email'}>
        //         {record.email}
        //       </Descriptions.Item>
        //     </Descriptions>
        //   ),
        //   rowExpandable: (record) => record.name !== 'Not Expandable',
        // }}
      />
    );
  };

  const tabs = [
    {
      key: 'active',
      label: `전체 사용자 ${activeUsers.length}`,
      children: <UserTable users={activeUsers} columns={columns} />,
    },
    {
      key: 'pending',
      label: `승인대기 ${pendingUsers.length}`,
      children: <UserTable users={pendingUsers} columns={pendingColumns} />,
    },
    {
      key: 'inactive',
      label: `휴면 사용자 ${inactiveUsers.length}`,
      children: <UserTable users={inactiveUsers} columns={columns} />,
    },
  ];

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [isNarrowView, setNarrowView] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if (windowSize[0] > 860) setNarrowView(false);
    else setNarrowView(true);
  });

  const infoMessage = (tab) => {
    switch (tab) {
      case 'pending':
        return (
          <>
            회원가입 신청은 연동된 슬랙으로 알려드립니다.{' '}
            <a onClick={() => navigate(`/projects/${projectId}/10`)}>
              연동하러 가기
            </a>
          </>
        );
      case 'inactive':
        return `휴면 계정은 대한민국 법에 따라 1년 후 자동으로 물리적으로 분리된 DB에 이관됩니다.`;
      default:
        return null;
    }
  };

  return (
    <>
      {infoMessage(userTab) && (
        <div
          style={{
            position: isNarrowView ? 'relative' : 'absolute',
            top: 0,
            right: 0,
            backgroundColor: '#F5F5F5',
            marginTop: 24,
            padding: 6,
            paddingLeft: 32,
            paddingRight: 32,
            zIndex: 1,
          }}
        >
          {infoMessage(userTab)}
        </div>
      )}

      <Tabs
        defaultActiveKey={1}
        activeKey={userTab}
        tabBarStyle={{
          marginTop: 20,
          marginBottom: 24,
          maxWidth: 344,
        }}
        onTabClick={(key) => navigate(`/projects/${projectId}/3/${key}`)}
        items={tabs}
        type={'card'}
      />
    </>
  );
}
