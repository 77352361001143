import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  Divider,
  Row,
  Col,
  Typography,
  Switch,
} from 'antd';
import UploadImage from '../../UploadImage';
import { useFuro } from 'furo-react';
import { searchTeams, updateProject } from '../../../hooks/useAPI';
import { CopyButton, APIKeys } from '../../../components';
import DeleteProjectModal from '../DeleteProjectModal';

const { Title } = Typography;

export default function Detail(props) {
  const { project } = props;
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { user, getAccessTokenSilently } = useFuro();
  const [teams, setTeams] = useState();
  const [workspaceUpdated, setWorkspaceUpdated] = useState(false);

  useEffect(async () => {
    const teamList = await searchTeams(user.uid, {
      getToken: getAccessTokenSilently,
    });
    setTeams(teamList);
  }, []);

  const requestUpdateProject = async (pid) => {
    setConfirmLoading(true);
    try {
      // get values from form
      const values = await form.validateFields();
      // reverse signup auto approval value
      values.signup_approval = !values.signup_approval;

      // call update project api
      const [response, error] = await updateProject(
        pid,
        { ...values, workspace: { enabled: values.workspace } },
        {
          getToken: getAccessTokenSilently,
        },
      );
      // handle error
      if (error) {
        console.error(error);
        setConfirmLoading(false);
        const { message } = error.response.data;
        notification.error({
          message: '프로젝트 설정변경에 실패하였습니다.',
          description: JSON.stringify(message),
        });
      }
      // handle success
      else if (response.data) {
        // when workspace activate, disable switch
        if(values.workspace === true){
          setWorkspaceUpdated(true);
        }
        setConfirmLoading(false);
        notification.success({
          message: '프로젝트가 설정변경이 완료 되었습니다.',
          description: '변경된 프로젝트를 확인해 보세요.',
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: '프로젝트 설정변경에 실패하였습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
        <Form.Item
          label="프로젝트 이름"
          name="name"
          required
          tooltip="로그인 화면에 나타날 프로젝트 이름입니다."
          initialValue={project.name}
        >
          <Input placeholder={project.name} />
        </Form.Item>
        {/* <Form.Item
          label="Domain"
          tooltip="회원 인증을 진행하는 인증 서버의 도메인입니다."
        >
          <Input
            placeholder="input placeholder"
            disabled
            value={'https://auth.furo.one'}
            style={{ color: 'black' }}
          />
        </Form.Item>
        <Form.Item
          label="Client ID"
          tooltip="애플리케이션에 부여된 고유한 식별자입니다"
        >
          <Input
            placeholder="input placeholder"
            value={project.pid}
            disabled
            style={{ color: 'black' }}
          />
          <CopyButton
            _onClick={() => {
              navigator.clipboard.writeText(project.pid);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Default Callback URI"
          name="callback_url"
          initialValue={project.callback_url}
          required
          tooltip="로그인 후 사용자를 애플리케이션으로 이동시킬 때 사용하는 URI입니다."
        >
          <Input placeholder="input placeholder" />
        </Form.Item> */}

        <Form.Item
          label="워크스페이스"
          name="workspace"
          valuePropName="checked"
          initialValue={project?.workspace?.enabled ?? false}
          tooltip="서비스에 워크스페이스를 활성화하여 사용자의 권한을 역할 기반으로 부여합니다."
        >
          <Switch
            placeholder="input placeholder"
            disabled={project?.workspace?.enabled || workspaceUpdated}
          />
        </Form.Item>

        <Form.Item
          label="회원가입 자동승인"
          tooltip="사용자가 회원가입시 자동으로 승인됩니다. 비활성화시 회원가입이 제한됩니다."
        >
          <Form.Item
            name="signup_approval"
            valuePropName="checked"
            initialValue={!project.signup_approval ?? true}
            noStyle
          >
            <Switch placeholder="input placeholder" />
          </Form.Item>
          <span style={{ marginLeft: 8 }}>
            대기 목록은
            <span
              style={{ fontWeight: 'bold' }}
            >{` [사용자 목록] > 승인대기`}</span>
            에서 확인하실 수 있습니다.
          </span>
        </Form.Item>

        <Form.Item
          label="휴면계정 자동관리"
          name="dormant_accounts_management"
          valuePropName="checked"
          initialValue={project.dormant_accounts_management ?? true}
          tooltip="사용자가 장기간 로그인이 없을 시 휴면계정으로 자동 관리됩니다."
        >
          <Switch placeholder="input placeholder" />
        </Form.Item>

        <Form.Item
          label="휴면전환 안내 메일 발송"
          name="dormant_accounts_notification"
          valuePropName="checked"
          initialValue={project.dormant_accounts_notification ?? true}
          tooltip="휴면계정으로 전환되기 전 사용자에게 안내 메일을 보냅니다."
        >
          <Switch placeholder="input placeholder" />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 14, offset: 5 }}>
          <Button
            type="primary"
            onClick={() => requestUpdateProject(project.pid)}
          >
            변경사항 저장
          </Button>
        </Form.Item>
      </Form>
      <Divider plain />
      <Row justify="space-between">
        <Title level={4}>프로젝트 관리</Title>
      </Row>
      <Row>
        <Col>
          <DeleteProjectModal projectId={project.pid} />
        </Col>
      </Row>
    </>
  );
}
