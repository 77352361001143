import React, { useState, useContext, useEffect } from 'react';
import { Button as ButtonComponent } from '../../components';
import {
  Modal,
  Space,
  Typography,
  Card,
  Col,
  Row,
  Switch,
  Divider,
  Badge,
  notification,
  Tooltip,
} from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { OverviewContext } from '../../contexts/BillingContext';
import PaymentMethodModal from './PaymentMethodModal';
import { changeSubscriptionPlan } from '../../hooks/useAPI';
import { SALES_CONTACT_URI, pricing } from '../../data';
import InvoiceModal from './InvoiceModal';
import mixpanel from '../../libs/mixpanel';
import { useFuro } from 'furo-react';
import { PRIMARY_COLOR } from '../../data';

import FAQ from './FAQ';
import PricingTable from './PricingTable';

const { Title, Text } = Typography;

const getPlanCode = (PlanName, BillingCycle) => {
  let planCode;

  switch (PlanName) {
    case 'Developer':
      planCode = 'DVMT01';
      break;
    case 'Essential':
      planCode = 'ESMT01';
      break;
    case 'Business':
      planCode = 'BS_P01';
      break;
    case 'Enterprise':
      planCode = 'ET_P01';
      break;
    default:
      planCode = 'DV_T01';
      break;
  }

  return BillingCycle === 'yearly'
    ? planCode.replace('_', 'Y')
    : planCode.replace('_', 'M');
};

export const PricingPlan = ({ currentPlan, setModalVisible, screen }) => {
  const [billingCycle, setBillingCycle] = useState('yearly');

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <Title style={{ fontSize: 36 }}>Pricing Plan</Title>
        <Switch
          checkedChildren="연간 플랜"
          unCheckedChildren="월간 플랜"
          defaultChecked
          onChange={(checked) =>
            checked ? setBillingCycle('yearly') : setBillingCycle('monthly')
          }
        />
      </div>
      <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
        <Text style={{ color: '#999' }}>(VAT 별도)</Text>
      </div>
      <Row gutter={[16, 16]}>
        {pricing.plans.map((plan, idx) => (
          <Col lg={8} md={24} sm={24} xs={24} key={idx}>
            <PricingCard
              idx={idx}
              plan={plan}
              currentPlan={currentPlan}
              billingCycle={billingCycle}
              setModalVisible={setModalVisible}
              screen={screen}
            />
          </Col>
        ))}
      </Row>
      <PricingTable />
      <FAQ />
    </div>
  );
};

const PricingButtonWrapper = ({ ShouldWrapped, wrapper, children }) =>
  ShouldWrapped ? wrapper(children) : children;

const PricingCard = ({
  plan,
  billingCycle,
  currentPlan,
  setModalVisible,
  idx,
  screen,
}) => {
  const {
    data: overview,
    setData: setOverview,
    getAccessTokenSilently,
  } = useContext(OverviewContext) ?? {};

  const { user } = useFuro();

  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState([false, false, false]);
  // const { data } = useContext(AccountContext);
  const { cards, account } = overview ?? {};
  const [isCardRegistered, setCardRegistered] = useState(
    cards ? cards.length > 0 : false,
  );
  const [isInvoiceChecked, setInvoiceChecked] = useState(false);
  const [hasbuttonClicked, setButtonClicked] = useState(false);

  // if invoice confirmed, change subscription
  useEffect(async () => {
    if (
      (plan.tier === 'Business' && isInvoiceChecked) ||
      (plan.tier === 'Essential' && isInvoiceChecked)
    ) {
      try {
        const [res, error] = await changeSubscriptionPlan(
          account.account_id,
          {
            subscription_plan_code: getPlanCode(plan.tier, billingCycle),
          },
          {
            getToken: getAccessTokenSilently,
          },
        );
        if (res) {
          setOverview({ ...overview, ...res });
          notification.success({
            message: `${plan.tier} 플랜이 활성화되었습니다.`,
            description: '설정 탭을 확인해 보세요.',
          });
        } else {
          notification.error({
            message: '플랜 업그레이드에 실패하였습니다.',
            description: '이 현상이 지속될 시 관리자에게 문의하세요',
          });
          setInvoiceChecked(false);
        }
      } catch (error) {
        console.error(error);
        notification.error({
          message: '플랜 업그레이드에 실패하였습니다.',
          description: '이 현상이 지속될 시 관리자에게 문의하세요',
        });
        setInvoiceChecked(false);
      } finally {
        setBtnLoading([false, false, false]);
        setModalVisible(false);
      }
    }
  }, [isInvoiceChecked]);

  // if card registered, show invoice
  useEffect(() => {
    if (plan.tier === 'Business' && isCardRegistered && hasbuttonClicked) {
      setInvoiceModalVisible(true);
    }
  }, [isCardRegistered]);

  currentPlan = currentPlan?.split(' ')[0];

  const onSubscriptionPlanChange = async () => {
    if (screen) {
      window.location.href = './projects';
    }

    setButtonClicked(true);
    switch (plan.tier) {
      case 'Developer':
        setBtnLoading([true, false, false]);
        mixpanel.track('Change Plan Button Clicked', {
          UID: user.uid,
          distinct_id: user.uid,
          plan: 'Developer',
        });
        break;
      case 'Essential':
        // if no cards
        if (!isCardRegistered) {
          setPaymentModalVisible(true);
        }
        setBtnLoading([false, true, false]);
        mixpanel.track('Change Plan Button Clicked', {
          UID: user.uid,
          distinct_id: user.uid,
          plan: 'Essential',
        });
        break;
      case 'Business':
        // if no cards
        if (!isCardRegistered) {
          setPaymentModalVisible(true);
        } else {
          setInvoiceModalVisible(true);
        }
        mixpanel.track('Change Plan Button Clicked', {
          UID: user.uid,
          distinct_id: user.uid,
          plan: 'Business',
        });
        setBtnLoading([false, true, false]);
        break;
      case 'Enterprise':
        setBtnLoading([false, false, true]);
        mixpanel.track('Change Plan Button Clicked', {
          UID: user.uid,
          distinct_id: user.uid,
          plan: 'Enterprise',
        });
        window.open(SALES_CONTACT_URI);
        return;
      default:
        break;
    }

    if (plan.tier === 'Business' && isCardRegistered && isInvoiceChecked) {
      try {
        const [res, error] = await changeSubscriptionPlan(
          account.account_id,
          {
            subscription_plan_code: getPlanCode(plan.tier, billingCycle),
          },
          {
            getToken: getAccessTokenSilently,
          },
        );
        if (res) {
          mixpanel.track('Change Plan Success', {
            UID: user.uid,
            distinct_id: user.uid,
            plan: plan.tier,
          });
          setOverview({ ...overview, ...res });
          notification.success({
            message: `${plan.tier} 플랜이 활성화되었습니다.`,
            description: '설정 탭을 확인해 보세요.',
          });
        } else {
          mixpanel.track('Change Plan Fail', {
            UID: user.uid,
            distinct_id: user.uid,
            plan: plan.tier,
          });
          notification.error({
            message: '플랜 업그레이드에 실패하였습니다.',
            description: '이 현상이 지속될 시 관리자에게 문의하세요',
          });
          setInvoiceChecked(false);
        }
      } catch (error) {
        mixpanel.track('Change Plan Fail', {
          UID: user.uid,
          distinct_id: user.uid,
          plan: plan.tier,
          Error: JSON.stringify(error.response.data),
        });
        console.error(error);
        notification.error({
          message: '플랜 업그레이드에 실패하였습니다.',
          description: '이 현상이 지속될 시 관리자에게 문의하세요',
        });
        setInvoiceChecked(false);
      } finally {
        setBtnLoading([false, false, false]);
        setModalVisible(false);
      }
    } else if (plan.tier === 'Developer') {
      // try {
      //   const [res, error] = await changeSubscriptionPlan(
      //     account.account_id,
      //     {
      //       subscription_plan_code: getPlanCode(plan.tier, billingCycle),
      //     },
      //     {
      //       getToken: getAccessTokenSilently,
      //     },
      //   );
      //   if (res) {
      //     setOverview({ ...overview, subscription: res });
      //     notification.success({
      //       message: `${plan.tier} 플랜이 활성화되었습니다.`,
      //       description: '설정 탭을 확인해 보세요.',
      //     });
      //   } else {
      //     console.error(error);
      //     notification.error({
      //       message: '플랜 업그레이드에 실패하였습니다.',
      //       description: '이 현상이 지속될 시 관리자에게 문의하세요',
      //     });
      //   }
      // } catch (error) {
      //   console.error(error);
      //   notification.error({
      //     message: '플랜 업그레이드에 실패하였습니다.',
      //     description: '이 현상이 지속될 시 관리자에게 문의하세요',
      //   });
      // } finally {
      //   setBtnLoading([false, false, false]);
      //   setModalVisible(false);
      // }
    }
  };

  return (
    <PricingCardWrapper
      ShouldBeTagged={plan.tier === 'Business'}
      wrapper={(children) => (
        <Badge.Ribbon text="BEST">{children}</Badge.Ribbon>
      )}
    >
      <Card
        hoverable={currentPlan !== plan.tier}
        style={{
          borderRadius: 15,
          height: '100%',
          backgroundColor: currentPlan === plan.tier ? '#f5f5f5' : '#fff',
        }}
        bodyStyle={{
          height: '100%',
          position: 'relative',
          paddingBottom: 80,
        }}
      >
        <Row>
          <Text style={{ fontSize: 18, fontWeight: 700 }}>{plan.tier}</Text>
        </Row>
        <br />
        <Row>
          <Price pricing={plan.price[billingCycle]} tier={plan.tier} />
        </Row>
        <br />
        <div>
          <Row>
            <Text style={styles.description}>{plan.discription}</Text>
          </Row>
        </div>
        <Divider />
        <Row>
          <Space direction="vertical" style={{ marginBottom: 40 }}>
            <Row>
              <Description plan={plan.tier} />
            </Row>
            {/* <Divider style={{ margin: 15 }} /> */}
            {plan.features.map((feat, idx) => (
              <Row key={idx}>
                <Text key={idx}>
                  <CheckCircleFilled
                    style={{ color: PRIMARY_COLOR, marginRight: 10 }}
                  />
                  {feat}
                </Text>
              </Row>
            ))}
          </Space>
        </Row>
        <div style={styles.btnWrapper}>
          <PricingButtonWrapper
            ShouldWrapped={plan.tier === 'Developer' && !screen}
            // ShouldWrapped={false}
            wrapper={(children) => (
              <Tooltip
                placement="bottom"
                title={'오른쪽 하단의 채널톡을 이용해 주세요.'}
              >
                {children}
              </Tooltip>
            )}
          >
            <ButtonComponent
              type="primary"
              onClick={onSubscriptionPlanChange}
              disabled={currentPlan === plan.tier}
              loading={btnLoading[idx - 1]}
            >
              {currentPlan === plan.tier ? '사용중' : plan.action}
            </ButtonComponent>
          </PricingButtonWrapper>
        </div>
      </Card>
      {!screen && (
        <>
          <PaymentMethodModal
            visible={paymentModalVisible}
            setVisible={setPaymentModalVisible}
            button={false}
            setCardRegistered={setCardRegistered}
            isDefault={!isCardRegistered}
          />
          <InvoiceModal
            visible={invoiceModalVisible}
            setVisible={setInvoiceModalVisible}
            setInvoiceChecked={setInvoiceChecked}
            subscriptionPlanCode={getPlanCode(plan.tier, billingCycle)}
            accountId={account?.account_id}
          />
        </>
      )}
    </PricingCardWrapper>
  );
};

const Description = ({ plan }) => {
  if (plan === 'Business') {
    return (
      <div style={{ marginBottom: 10 }}>
        <Text style={{ color: PRIMARY_COLOR, fontWeight: 600 }}>
          Developer의 모든 기능 +
        </Text>
        {/* <br /> */}
        {/* <Text>월간 활성 사용자 50,000명</Text> */}
        {/* <Text>50,000 MAU</Text> */}
      </div>
    );
  } else if (plan === 'Enterprise') {
    return (
      <div style={{ marginBottom: 10 }}>
        <Text style={{ color: PRIMARY_COLOR, fontWeight: 600 }}>
          Business의 모든 기능 +
        </Text>
        {/* <br /> */}
        {/* <Text>월간 활성 사용자 500,000명까지</Text> */}
        {/* <Text>500,000 MAU</Text> */}
      </div>
    );
  }

  return (
    <div
      style={{
        height: 32,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {/* <Text>5,000 MAU</Text> */}
    </div>
  );
};

const PricingCardWrapper = ({ ShouldBeTagged, wrapper, children }) =>
  ShouldBeTagged ? wrapper(children) : children;

const Price = ({ pricing, tier }) => {
  const { net, discount, sale } = pricing;

  if (discount && sale) {
    return (
      <div style={{ textAlign: 'left' }}>
        {/* <Text type="secondary" style={{ textDecoration: 'red line-through' }}> */}
        {/* {net} */}
        {/* </Text> */}
        {/* <br /> */}
        <div style={{ height: 22 }}>
          <Badge count={'6.5%'} />
        </div>
        <Text style={{ ...styles.price, letterSpacing: -1.4 }}>{sale}</Text>
        {tier !== 'Enterprise' && <Text style={{ marginLeft: 5 }}>/ 월</Text>}
      </div>
    );
  }

  return (
    <div style={{ marginTop: 22 }}>
      {/* <Text /> */}
      {/* <br /> */}
      <Text style={{ ...styles.price, letterSpacing: -1.4 }}>{net}</Text>
      {tier !== 'Enterprise' && <Text style={{ marginLeft: 5 }}>/ 월</Text>}
    </div>
  );
};

export default PricingPlan;

const styles = {
  btnWrapper: {
    // padding: '15px 0px',
    padding: '24px 0px',
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% - 48px)',
  },
  price: {
    fontSize: 32,
    fontWeight: 800,
    lineHeight: 1,
  },
  description: {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'pre-line',
  },
};
