import React, { useState } from 'react';
import Preview from './Preview';
import DesignModal from '../DesignModal';
import mixpanel from 'mixpanel-browser';
import { useFuro } from 'furo-react';

const Design = (props) => {
  const [visible, setVisible] = useState(false);
  const { user } = useFuro();

  return (
    <>
      <Preview
        mainTitle={`디자인 설정`}
        tag={`NEW`}
        color={'magenta'}
        subTitle={`로그인 화면을 직접 커스텀할 수 있어요.`}
        description={`지금 우리 사이트만의 로그인 화면을 직접 디자인 해보세요. \n
        다크모드를 포함해 원하는 로그인 페이지를 단 1분만에 구현할 수 있어요.`}
        // disabled
        buttonText={`시작하기`}
        imgUrl={'../../images/design.svg'}
        setStep={() => {
          mixpanel.time_event('Design Modal Opened', {
            UID: user.uid,
            distinct_id: user.uid,
          });
          setVisible(true);
        }}
      />
      <DesignModal visible={visible} setVisible={setVisible} {...props} />
    </>
  );
};

export default Design;
