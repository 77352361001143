import React, { useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import { Invoice } from './tabs/Invoice';
import { getInvoicePreview } from '../../hooks/useAPI';
import { useFuro } from 'furo-react';

const InvoiceModal = ({
  visible,
  setVisible,
  setInvoiceChecked,
  subscriptionPlanCode,
  accountId,
}) => {
  const [invoicePreview, setInvoicePreivew] = useState();
  const { getAccessTokenSilently } = useFuro();

  useEffect(async () => {
    try {
      const [res, err] = await getInvoicePreview(
        accountId,
        subscriptionPlanCode,
        {
          getToken: getAccessTokenSilently,
        },
      );
      if (err) {
        notification.error({
          message: '청구서 미리보기 생성에 실패했습니다.',
          description: '이 현상이 지속될 시 관리자에게 문의하세요',
        });
      } else if (res) {
        setInvoicePreivew(res);
      }
    } catch (error) {
      notification.error({
        message: '청구서 미리보기 생성에 실패했습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    } finally {
    }
  }, [accountId]);

  return (
    <Modal
      centered
      open={visible}
      onCancel={() => setVisible(false)}
      okText="확인"
      cancelText="취소"
      width={'clamp(300px, 70%, 700px)'}
      onOk={() => {
        setInvoiceChecked(true);
        setVisible(false);
      }}
    >
      <Invoice selectedInvoice={invoicePreview} preview />
    </Modal>
  );
};

export default InvoiceModal;
