import React, { useState, useEffect } from 'react';
import { Dropdown, Space, Typography } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import ImagePicker from './ImagePicker';
import { Button } from 'antd';
import UploadImage from '../screens/UploadImage';

const { Text } = Typography;

const ImagePickerGroup = (props) => {
  const [error, setError] = useState(false);

  return (
    <Space direction="vertical">
      <Space size={10}>
        <UnsplashUploadButton {...props} />
        <ImageUploadButton setError={setError} {...props} />
      </Space>
      {error && <Warning />}
    </Space>
  );
};

const UnsplashUploadButton = (props) => {
  const items = [
    {
      label: <ImagePicker {...props} />,
      key: '0',
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={['click']}
      placement="bottom"
      overlayClassName="unsplash"
    >
      <Button icon={<UploadOutlined />}>Unsplash</Button>
    </Dropdown>
  );
};

const ImageUploadButton = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <UploadImage
      type="side_image"
      listType="text"
      crop={false}
      project={props.project}
      showUploadList={false}
      setError={props.setError}
      setLoading={setLoading}
      {...props}
    >
      <Button icon={<UploadOutlined />} loading={loading}>
        {loading ? 'Uploading...' : '이미지 업로드'}
      </Button>
    </UploadImage>
  );
};

const Warning = () => {
  return (
    <Text style={{ marginRight: 15 }} type="danger">
      <ExclamationCircleOutlined style={{ marginRight: 5 }} />
      파일이 너무 큽니다. (50MB 초과)
    </Text>
  );
};

export default ImagePickerGroup;
