import { Link, useParams, useNavigate } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';
import { getProject } from '../../hooks/useAPI';
import { useFuro } from 'furo-react';
import {
  Typography,
  Row,
  Col,
  Breadcrumb,
  Spin,
  Tabs,
  Tag,
  Tooltip,
  Space,
} from 'antd';
const { Title, Text } = Typography;
const { TabPane } = Tabs;
import {
  UserList,
  Settings,
  SelfAuth,
  Captcha,
  Statistics,
  Detail,
  Mailing,
  Tutorial,
  LoginMethod,
  Design,
  Develop,
} from './tabs';
import { Avatar, Button, EnvTag } from '../../components';
import { PRIMARY_COLOR } from '../../data';

function Project() {
  const navigate = useNavigate();
  let { projectId, tab } = useParams();
  const { getAccessTokenSilently } = useFuro();

  const { project, loading, error } = getProject(projectId, {
    getToken: getAccessTokenSilently,
  });

  if (loading) return <Spin />;
  if (!project) return <div>No Project</div>;

  const tabs = [
    // { key: '1', label: 'Get Started', children: <Tutorial /> },
    {
      key: '2',
      label: renderTabMenu('디자인 설정', { new: true }),
      children: <Design project={project} />,
    },
    {
      key: '3',
      label: '사용자 목록',
      children: <UserList project={project} />,
    },
    {
      key: '4',
      label: '프로젝트 설정',
      children: <Detail project={project} />,
    },
    {
      key: '5',
      label: renderTabMenu('로그인 방식', { new: true }),
      children: <LoginMethod project={project} />,
    },
    // { key: '6', label: '본인인증', children: <SelfAuth /> },
    { key: '7', label: 'Captcha', children: <Captcha /> },
    {
      key: '8',
      label: '애널리틱스',
      children: <Statistics active={!!project.analytics} />,
    },
    { key: '9', label: '메일링', children: <Mailing /> },
    {
      key: '10',
      label: '추가 기능',
      children: <Settings project={project} />,
    },
    {
      key: '11',
      label: '개발자 도구',
      children: <Develop project={project} />,
    },
  ];

  return (
    <div style={{ padding: '0px 20px' }}>
      {/* breadcrumb */}
      <Breadcrumb style={{ marginBottom: 16 }}>
        <Breadcrumb.Item>
          <Link to="/projects" style={{ color: PRIMARY_COLOR }}>
            {/* Projects */}내 프로젝트
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="">{`${project.name}-${project.client_id}`}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      {/* Title */}
      <Row gutter={32} style={{ marginBottom: 16 }}>
        <Col>
          <Avatar
            raised
            src={project.logo}
            size={48}
            style={{ margin: 8, marginRight: -8 }}
          />
        </Col>
        <Col flex={'auto'}>
          <div style={{ display: 'inline-block' }}>
            <Title level={2} style={{ marginBottom: 0 }}>
              {project.name}
            </Title>
            <div style={{ marginTop: 4, marginBottom: 4 }}>
              <EnvTag project={project} tooltip />
            </div>
          </div>
        </Col>
        <Col>
          <Space align="center">
            {/* <Button
              border={'true'}
              onClick={() => {
                window.open(SENDTIME_URI, '_blank').focus();
              }}
            >
              프리미엄 플랜 도입
              <DollarOutlined />
            </Button> */}
            <Button
              border={'true'}
              onClick={() => {
                window
                  .open(
                    `${
                      process.env.REACT_APP_FURO_DOMAIN ||
                      'https://auth.furo.one'
                    }/login/${project.pid}`,
                    '_blank',
                  )
                  .focus();
              }}
            >
              로그인 페이지 보기
              <ExportOutlined />
            </Button>
          </Space>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="tutorial"
        activeKey={tab}
        tabBarStyle={{ marginBottom: 24 }}
        onTabClick={(key) => navigate(`/projects/${projectId}/${key}`)}
        items={tabs}
      />
    </div>
  );
}

const renderTabMenu = (name, opts) => {
  if (opts?.new)
    return (
      <>
        {`${name} `}
        <Tag
          color="#f50"
          style={{
            width: 14,
            height: 14,
            padding: 0,
            margin: 0,
            fontSize: 10,
            lineHeight: '12px',
            textAlign: 'center',
          }}
        >
          N
        </Tag>
      </>
    );
  else return name;
};

export default Project;
