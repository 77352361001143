import { useState, useEffect } from 'react';
import { Progress, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useFuro } from 'furo-react';
import { getUpdatedUrl } from '../utils/util';

const UploadImage = (props) => {
  const {
    type = 'logo',
    crop = true,
    children,
    listType = 'picture-card',
    showUploadList = true,
  } = props;

  const { getAccessTokenSilently } = useFuro();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
      console.log('Ready to upload');
    };
    getToken();
  }, []);

  const [fileList, setFileList] = useState(
    type === 'logo' && props.project
      ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: props.project.logo,
          },
        ]
      : [],
  );

  const onChange = async ({ file: newFile, fileList: newFileList }) => {
    setFileList([newFile]);

    // set loading
    if (newFile.status === 'uploading') {
      props.setLoading(true);
    } else {
      props.setLoading(false);
    }

    if (newFile.status === 'done') {
      props.setImage(newFile.response.url);
      // props.form.setFieldsValue({ [type]: newFile.response.url });
      // props.setPreviewUrl(
      //   getUpdatedUrl(props.previewUrl, { [type]: newFile.response.url }),
      // );
      // props.setStyleChanged(
      //   props.initialUrl !==
      //     getUpdatedUrl(props.previewUrl, { [type]: newFile.response.url }),
      // );
      props.setError && props.setError(false);
    } else if (newFile.status === 'error') {
      props.setError && props.setError(true);
    }
  };

  // const onPreview = async (file) => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   const imgWindow = window.open(src);
  //   imgWindow.document.write(image.outerHTML);
  // };

  const consoleApiBaseUrl =
    process.env.REACT_APP_CONSOLE_API_BASE_URL ||
    'https://api.furo.one/upload/image';

  return (
    <UploadWrapper
      ShouldWrapped={crop}
      wrapper={(children) => <ImgCrop rotate>{children}</ImgCrop>}
    >
      <Upload
        action={`${consoleApiBaseUrl}/projects/${props.project?.pid}/upload/logo`}
        name="image"
        listType={listType}
        fileList={fileList}
        onChange={onChange}
        // onPreview={onPreview}
        style={{ opacity: 1 }}
        headers={{ Authorization: `Bearer ${token}` }}
        showUploadList={showUploadList}
        accept="image/*"
        // accept=".png,.jpg,.jpe,.jpeg"
      >
        {children ? children : fileList.length < 2 && '+ Upload'}
      </Upload>
    </UploadWrapper>
  );
};

const UploadWrapper = ({ ShouldWrapped, wrapper, children }) =>
  ShouldWrapped ? wrapper(children) : children;

export default UploadImage;
