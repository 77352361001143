import { Typography, Space, Row, Col, Card, Tag } from 'antd';
import Button from '../../../components/Button';
const { Title, Text, Paragraph } = Typography;

const Preview = (props) => {
  return (
    <Card>
      <Row gutter={32}>
        <Col span={12}>
          <Space direction="vertical">
            <Title level={2}>
              {props.mainTitle}
              {props.tag && (
                <Tag style={{ marginLeft: 6 }} color={props.color}>
                  {props.tag}
                </Tag>
              )}
            </Title>
            <Title level={4} style={{ whiteSpace: 'pre-wrap' }}>
              {props.subTitle}
            </Title>
            <Paragraph>{props.description}</Paragraph>
            {/* <Title level={5}>
              <Link to="#">
                자세히 알아보기
                <ArrowRightOutlined />
              </Link>
            </Title> */}
            <Button
              type="primary"
              disabled={props.disabled}
              style={{ width: 200, fontWeight: 600 }}
              onClick={props.setStep}
            >
              {props.buttonText}
            </Button>
          </Space>
        </Col>
        <Col span={12}>
          <img
            src={props.imgUrl}
            style={{
              maxHeight: 300,
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Preview;
