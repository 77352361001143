import React from 'react';
import { Space, Typography, Col, Row, Tooltip, Tag } from 'antd';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { pricing } from '../../data';

const { Title, Text } = Typography;

const PricingTable = () => {
  return (
    <Space
      direction={'vertical'}
      style={{ width: '100%', padding: 20, marginTop: 25 }}
    >
      <Row>
        <Col span={15}>
          <Row>
            <Title level={5}>플랜별 기능상세</Title>
          </Row>
        </Col>
        <Col span={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Title level={5}>Developer</Title>
        </Col>
        {/* <Col span={3} style={{ display: 'flex', justifyContent: 'center' }}>
            <Title level={5}>Essential</Title>
          </Col> */}
        <Col span={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Title level={5}>Business</Title>
        </Col>
        <Col span={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Title level={5}>Enterprise</Title>
        </Col>
      </Row>
      <Row>
        <Text style={{ color: '#888' }}>공통</Text>
      </Row>
      <Features name={'common'} />
      <br />
      <Row>
        <Text style={{ color: '#888' }}>서비스</Text>
      </Row>
      <Features name={'custom'} />
      <br />
      <Row>
        <Text style={{ color: '#888' }}>보안</Text>
      </Row>
      <Features name={'security'} />
      <br />
      <Row>
        <Text style={{ color: '#888' }}>지원</Text>
      </Row>
      <Features name={'support'} />
    </Space>
  );
};

const Features = ({ name }) => {
  return (
    <>
      {pricing.features[name].map((feature, idx) => {
        const [
          title,
          developer,
          essential,
          business,
          enterprise,
          description,
          isReady,
        ] = feature;
        return (
          <div
            style={{ borderBottom: '1px solid #eee', padding: '7px 0px' }}
            key={idx}
          >
            <Row>
              <Col span={15}>
                <Row justify={'start'} align={'middle'}>
                  <Text
                    style={
                      {
                        // color: isReady ? '#000' : '#888',
                      }
                    }
                  >
                    {title}
                  </Text>
                  <Tooltip
                    placement="right"
                    title={description}
                    trigger="hover"
                  >
                    <QuestionCircleOutlined
                      style={{ marginLeft: 10, color: '#bbb' }}
                    />
                  </Tooltip>
                  {isReady ? null : (
                    <Tag style={{ marginLeft: 10 }}>준비중</Tag>
                  )}
                </Row>
              </Col>
              <Col
                span={3}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {developer ? <CheckOutlined /> : <></>}
              </Col>
              {/* <Col
                  span={3}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {essential ? <CheckOutlined /> : <></>}
                </Col> */}
              <Col
                span={3}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {business ? <CheckOutlined /> : <></>}
              </Col>
              <Col
                span={3}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {enterprise ? <CheckOutlined /> : <></>}
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default PricingTable;
