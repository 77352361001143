import React from 'react';
import { Setting } from '../screens/Settings/tabs';
import { AccountProvider } from '../contexts/BillingContext';

const SettingContainer = () => {
  return (
    <AccountProvider>
      <Setting />
    </AccountProvider>
  );
};

export default SettingContainer;
