import React, { useState } from 'react';
import Preview from './Preview';
import { ContactModal } from '../../../components';

const Mailing = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const _contact = () => {
    setModalOpen(!isModalOpen);
  };
  return (
    <>
      <Preview
        mainTitle={`메일링`}
        tag={`NEW`}
        color={'magenta'}
        subTitle={`메일링을 통해 지속적으로 사용자와 소통하고\n사용자의 전환율을 높여보세요.`}
        description={`회원가입 환영부터 3일, 7일, 14일 간격으로 메일링을 통해 사용자와 소통하세요`}
        // disabled
        buttonText={`메일링 활성화`}
        imgUrl={'../../images/mailing.svg'}
        setStep={_contact}
      />
      <ContactModal isOpen={isModalOpen} setOpen={setModalOpen} />
    </>
  );
};

export default Mailing;
