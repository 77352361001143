import React from 'react';
import 'antd/dist/reset.css';
import { Space, Layout, Row, Col, Typography, Grid, Button } from 'antd';
import { Outlet } from 'react-router-dom';
import { GetStartedButton, Logo } from '../components';

const { Header, Footer, Content } = Layout;
const { Paragraph } = Typography;
const { useBreakpoint } = Grid;

export default function MainLayout() {
  const { xs, xxl } = useBreakpoint();

  return (
    <Layout style={{ background: '#fff' }}>
      <Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          height: 80,
          background: 'white',
          padding: '10px 20px',
        }}
      >
        <Row justify="space-between">
          <Col>
            <Logo />
          </Col>
          <Col>
            <PricingPlanButton>요금 안내</PricingPlanButton>
            <GetStartedButton menu>시작하기</GetStartedButton>
          </Col>
        </Row>
      </Header>
      <Content
        style={{
          paddingTop: xs ? 150 : 100,
          textAlign: xs ? 'left' : 'center',
          margin: '1em',
        }}
      >
        <Outlet />
      </Content>
      <Footer style={style.footer}>
        <div
          style={{
            maxWidth: xxl ? 1100 : 800,
            margin: 'auto',
            width: '100%',
            padding: '0px 1em',
          }}
        >
          <Paragraph style={{ color: '#7B7B7B' }}>
            주식회사 호패 | 대표 심재훈 | 서울특별시 강남구 강남대로 364
            미왕빌딩 1701호
            <br />
            사업자등록번호 262-88-02303 | support@furo.one | @2022 Hopae Inc.
          </Paragraph>
          <Paragraph style={{ color: '#7B7B7B' }}>
            <a
              style={{
                color: '#7B7B7B',
                fontWeight: 700,
              }}
              href="https://hopae.notion.site/Furo-029991b281614cd58955771950942a92"
              target={'_blank'}
              rel="noreferrer"
            >
              서비스 이용약관
            </a>{' '}
            |{' '}
            <a
              style={{ fontWeight: 700, color: '#393939' }}
              href="https://hopae.notion.site/Furo-7b7ccce881ab435c867255b6244810e1"
              target={'_blank'}
              rel="noreferrer"
            >
              개인정보처리방침
            </a>
          </Paragraph>
        </div>

        {/* <Text type="secondary">
          Powered by <Text>⚡</Text>
          <a style={{ color: '#E95A6C' }}>Furo</a>
        </Text> */}
      </Footer>
    </Layout>
  );
}

function PricingPlanButton({ children }) {
  const { xs } = useBreakpoint();

  return (
    <Button
      style={{
        height: 50,
        borderRadius: 12,
        fontWeight: '600',
        marginRight: xs ? 0 : 10,
        width: xs ? '6em' : '10em',
      }}
      type="link"
      onClick={() => (window.location.href = './pricing')}
    >
      {children}
    </Button>
  );
}

const style = {
  footer: {
    textAlign: 'left',
    background: '#F0F2F5',
    borderTop: '1px solid #00000008',
    marginTop: '5em',
    padding: '50px 15px',
    fontSize: 12,
  },
};
