import { Avatar } from 'antd';

const CustomAvatar = ({ style, children, raised, ...rest }) => {
  return (
    <Avatar
      {...rest}
      style={{
        boxShadow: raised && 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        // boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
        ...style,
      }}
    >
      {children}
    </Avatar>
  );
};

export default CustomAvatar;
