import React, { useState } from 'react';
import {
  FunnelChart,
  DonutChart,
  StackedAreaChart,
  LineChart,
  SparkLineChart,
} from '@shopify/polaris-viz';
import { UsergroupAddOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Col, Row, Typography, Card, Divider, Statistic, Select } from 'antd';
import { PRIMARY_COLOR } from '../../../data';

const { Text, Title } = Typography;

const StatisticBoard = ({ analytics }) => {
  const [monthlyVisitors, setMonthlyVisitors] = useState(124523);
  const [monthlySignup, setMonthlySignup] = useState(23402);
  const [totalUsers, setTotalUsers] = useState(69238);
  const [totalUsersLabel, setTotalUsersLabel] = useState('daily');
  const [loginMethodLabel, setLoginMethodLabel] = useState('monthly');
  const [conversionRateLabel, setConversionRateLabel] = useState('daily');
  const [activeUsersLabel, setActiveUsersLabel] = useState('daily');

  return (
    <>
      <Row justify="space-between">
        <Title level={4}>사용자 통계</Title>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Card>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
              }}
            >
              <Statistic
                title="월간 방문자 수"
                value={monthlyVisitors}
                valueStyle={{ color: PRIMARY_COLOR, fontWeight: 600 }}
                prefix={<ArrowUpOutlined />}
              />
              <Statistic
                title="월간 신규가입 회원 수"
                value={monthlySignup}
                valueStyle={{ fontWeight: 600 }}
                //   prefix={<UsergroupAddOutlined />}
              />
              <Statistic
                title="전체 회원 수"
                value={totalUsers}
                valueStyle={{ fontWeight: 600 }}
                //   prefix={<UsergroupAddOutlined />}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={60} style={{ marginBottom: 40 }}>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div style={styles.wrapper}>
            <SubTitle>전체 사용자 추이</SubTitle>
            <SelectBox _onChange={(value) => setTotalUsersLabel(value)} />
          </div>
          {/* <TotalUsers data={analytics.total_users[totalUsersLabel]} /> */}
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div style={styles.wrapper}>
            <SubTitle>활성 사용자 추이</SubTitle>
            <SelectBox _onChange={(value) => setActiveUsersLabel(value)} />
          </div>
          <ActiveUsers data={analytics.active_users[activeUsersLabel]} />
        </Col>
      </Row>
      <Row gutter={60}>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div style={styles.wrapper}>
            <SubTitle>로그인 방식</SubTitle>
            <SelectBox _onChange={(value) => setLoginMethodLabel(value)} />
          </div>
          <LoginMethod data={analytics.login_method[loginMethodLabel]} />
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div style={styles.wrapper}>
            <SubTitle>사용자 전환률</SubTitle>
            <SelectBox _onChange={(value) => setConversionRateLabel(value)} />
          </div>
          {/* <ConversionRate
            data={analytics.conversion_rate[conversionRateLabel]}
          /> */}
        </Col>
      </Row>
    </>
  );
};

const ActiveUsers = ({ data }) => {
  return (
    <StackedAreaChart
      theme="Light"
      data={data}
      isAnimated
      skipLinkText="Skip chart content"
      //   yAxisOptions={{
      //     labelFormatter: function noRefCheck() {},
      //   }}
    />
  );
};

const TotalUsers = ({ data }) => {
  return <LineChart theme="Light" data={data} />;
};

const LoginMethod = ({ data }) => {
  return (
    <DonutChart
      theme="Light"
      comparisonMetric={{
        // accessibilityLabel: 'trending down 10%',
        metric: '10%',
        trend: 'positive',
      }}
      data={data}
      legendPosition="right"
    />
  );
};

const ConversionRate = ({ data }) => {
  return <FunnelChart theme="Light" data={data} />;
};

const SubTitle = ({ children }) => {
  return <Text strong>{children}</Text>;
};

const SelectBox = ({ _onChange }) => {
  return (
    <Select
      onChange={(value) => _onChange(value)}
      options={[
        {
          value: 'daily',
          label: '일간',
        },
        {
          value: 'monthly',
          label: '월간',
        },
        {
          value: 'yearly',
          label: '연간',
        },
      ]}
      size="small"
      style={{ display: 'none' }}
    />
  );
};

export default StatisticBoard;

const styles = {
  wrapper: {
    paddingBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
};
