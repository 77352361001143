import React, { useState } from 'react';
import { Typography, Space, Row, Divider, Card } from 'antd';
import PasswordModal from '../PasswordModal';
import { useFuro } from 'furo-react';

const { Title, Text } = Typography;

const Account = () => {
  const { user } = useFuro();

  const [email, setEmail] = useState(user.email);
  const [hasPassword, setHasPassword] = useState(
    user.sign_in_methods.idpw?.enabled ?? false,
  );

  return (
    <div>
      <Title level={5}>내 계정</Title>
      <Card>
        <Space direction="vertical">
          <Text style={{ fontWeight: 600 }}>개인 정보</Text>
          <div>
            <Row>
              <Text type="secondary" style={{ fontSize: 13 }}>
                이메일
              </Text>
            </Row>
            <Text style={{ fontSize: 16 }}>{email}</Text>
          </div>
        </Space>
        <Divider />
        <Space direction="vertical">
          <div>
            <Row>
              <Text style={{ fontWeight: 600 }}>비밀번호</Text>
            </Row>
            <Row>
              <Text type="secondary" style={{ fontSize: 13 }}>
                임시 로그인 코드를 사용하고 싶지 않으면 영구 비밀번호를 설정할
                수 있습니다.
              </Text>
            </Row>
          </div>
          <Row>
            <Space>
              {hasPassword ? (
                <>
                  <PasswordModal
                    type="update"
                    setHasPassword={setHasPassword}
                  />
                  <PasswordModal
                    type="delete"
                    setHasPassword={setHasPassword}
                  />
                </>
              ) : (
                <PasswordModal type="create" setHasPassword={setHasPassword} />
              )}
            </Space>
          </Row>
        </Space>
      </Card>
    </div>
  );
};

export default Account;
