export function elapsedTime(date) {
  if (!date) return '접속 이력 없음';
  const start = new Date(date);
  const end = new Date(); // 현재 날짜

  const diff = end - start; // 경과 시간

  const times = [
    { time: '분', milliSeconds: 1000 * 60 },
    { time: '시간', milliSeconds: 1000 * 60 * 60 },
    { time: '일', milliSeconds: 1000 * 60 * 60 * 24 },
    { time: '개월', milliSeconds: 1000 * 60 * 60 * 24 * 30 },
    { time: '년', milliSeconds: 1000 * 60 * 60 * 24 * 365 },
  ].reverse();

  // 년 단위부터 알맞는 단위 찾기
  for (const value of times) {
    const betweenTime = Math.floor(diff / value.milliSeconds);

    // 큰 단위는 0보다 작은 소수 단위 나옴
    if (betweenTime > 0) {
      return `${betweenTime}${value.time} 전`;
    }
  }

  // 모든 단위가 맞지 않을 시
  return '방금 전';
}

export const truncateEthAddress = (address) => {
  const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
  if (!address) return '';
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const decimalFormatter = (number) => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

/**
 *
 * @param {string} url 기존 iframe url
 * @param {object} styleObj 스타일 속성 객체 ex) {theme: "dark"}
 * @returns 스타일 쿼리문이 업데이트 된 iframe url
 */
export const getUpdatedUrl = (url, styleObj) => {
  const [baseUrl, styleQuery] = url.split('&style=');

  const newStyleObj = styleQuery
    ? { ...JSON.parse(decodeURIComponent(styleQuery)), ...styleObj }
    : styleObj;

  return baseUrl + '&style=' + encodeURIComponent(JSON.stringify(newStyleObj));
};
