import { Tag, Tooltip } from 'antd';
import { SALES_CONTACT_URI } from '../data';

const EnvTag = ({ project, tooltip = false }) => {
  const checkEnv = (project) => {
    if (project.pricingPlan === 'free' || project.pricingPlan === undefined) {
      return { env: 'DEV', color: 'red' };
    } else {
      return { env: 'Live', color: 'green' };
    }
  };
  if (tooltip && checkEnv(project).env === 'DEV') {
    return (
      <Tooltip
        title={
          <span>
            현재 프로젝트는 개발용입니다. <br />
            업그레이드 문의는{' '}
            <a href={SALES_CONTACT_URI} target={'_blank'}>
              여기서
            </a>{' '}
            도와드릴게요.
          </span>
        }
        placement="right"
        overlayStyle={{ maxWidth: 500 }}
      >
        <Tag color="red">DEV</Tag>
      </Tooltip>
    );
  } else {
    return <Tag color={checkEnv(project).color}>{checkEnv(project).env}</Tag>;
  }
};

export default EnvTag;
