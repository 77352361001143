import React, { useState } from 'react';
import { Card, Col, Row, Avatar, Switch, message, Form, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { useFuro } from 'furo-react';
import {
  CheckCircleTwoTone,
  SaveOutlined,
  SmileTwoTone,
} from '@ant-design/icons';
import { updateProject } from '../../hooks/useAPI';
import SettingModal from './SettingModal';
import { ContactModal } from '../../components';

const LoginMethodCard = ({ platform, methods = {} }) => {
  let { projectId } = useParams();
  const { getAccessTokenSilently } = useFuro();
  const [enabled, setEnabled] = useState(
    platform.name === 'magic_link' ||
      (methods && methods[platform.name]?.enabled),
  );

  const [switchLoading, setSwitchLoading] = useState(false);
  const [apiKey, setApiKey] = useState(methods[platform.name]?.client_id);
  const [currentApiKey, setCurrentApiKey] = useState(
    methods[platform.name]?.client_id,
  );
  const [secret, setSecret] = useState(methods[platform.name]?.client_secret);
  const [isModalOpen, setModalOpen] = useState(false);

  /**
   * @summary 세일즈 모달 열기
   */
  const _contact = () => {
    setModalOpen(!isModalOpen);
  };

  /**
   * @summary 로그인 방식 설정
   * @param {boolean} enabled 현재 활성화 여부
   * @param {string} apiKey OAuth 서드파티 API Key(혹은 Client ID)
   * @param {object} opt 옵션(ex. {allow_ip_detection: false})
   * @returns
   */
  const _setMethod = async (enabled, apiKey, opt) => {
    let data = Object.assign(methods);
    if (apiKey || apiKey?.trim() === '') {
      data[platform.name] = {
        ...data[platform.name],
        client_id: apiKey,
      };
    }
    if (secret || secret?.trim() === '') {
      data[platform.name] = {
        ...data[platform.name],
        client_secret: secret,
      };
    }
    data[platform.name] = {
      ...data[platform.name],
      ...opt,
      enabled: enabled || platform.name === 'magic_link',
    };

    const [response, error] = await updateProject(
      projectId,
      { sign_in_methods: data },
      {
        getToken: getAccessTokenSilently,
      },
    );
    if (error)
      message.error(
        `변경사항 저장에 실패하였습니다. 이 현상이 지속될 시 관리자에게 문의하세요`,
      );
    else if (response.status === 200) {
      // message.success('Successfully saved');
      message.success('변경사항이 저장되었습니다.');
      setEnabled(enabled || platform.name === 'magic_link');
      setApiKey(apiKey);
      setSecret(secret);
    } else
      message.error(
        `변경사항 저장에 실패하였습니다. 이 현상이 지속될 시 관리자에게 문의하세요`,
      );

    return response;
  };

  /**
   * @summary 카드의 활성화 스위치 클릭 시 동작
   * @param {boolean} enabled 현재 활성화 여부
   * @returns
   */
  const _handleToggle = async (enabled) => {
    if (platform.requireApiKey) {
      if (!apiKey && enabled) {
        setEnabled(false);
        // return message.error(`Please set API Key to activate`);
        return message.warning(
          `${platform.name.replace(/^[a-z]/, (char) =>
            char.toUpperCase(),
          )} 로그인 활성화를 위해서는 API Key를 입력하세요.`,
        );
      }
      await _setMethod(enabled, apiKey);
    } else {
      await _setMethod(enabled);
    }
  };

  /**
   * @summary 설정 모달의 저장 버튼 클릭 시 동작
   */
  const _handleSave = async ({ data }) => {
    if (!currentApiKey) {
      if (enabled) return await _setMethod(false, currentApiKey, data);
      else
        return message.warning(
          `${platform.name.replace(/^[a-z]/, (char) =>
            char.toUpperCase(),
          )} 로그인 활성화를 위해서는 API Key를 입력하세요.`,
        );
    }
    await _setMethod(apiKey ? enabled : true, currentApiKey, data);
  };

  /**
   * @summary client ID의 인풋이 바뀌었을 때 client ID 설정
   * @param {object} event client ID onChange 이벤트 객체
   */
  const _handleChange = (event) => {
    setCurrentApiKey(event.target.value);
  };

  /**
   * @summary client Secret의 인풋이 바뀌었을 때 client Secret 설정
   * @param {object} event client Secret onChange 이벤트 객체
   */
  const _handleSecretChange = (event) => {
    setSecret(event.target.value);
  };

  /**
   * @summary 설정 모달 오픈
   */
  const _onSettingClick = () => {
    if (!enabled) {
      message.warning(
        `먼저 ${platform.name.replace(/^[a-z]/, (char) =>
          char.toUpperCase(),
        )} 로그인을 활성화하세요.`,
      );
    }
  };

  return (
    <Card
      style={{ marginTop: 16, opacity: platform.disabled && 0.4 }}
      actions={
        !platform.disabled && [
          <Actions
            onClick={_onSettingClick}
            platform={platform}
            methods={methods}
            onSave={_handleSave}
            onChange={_handleChange}
            onSecretChange={_handleSecretChange}
          />,
        ]
      }
    >
      <Card.Meta
        avatar={<Avatar size={48} src={platform.avatar} />}
        title={
          <Row>
            <Col flex="auto">{platform.title}</Col>
            <Col flex="50px">
              <Switch
                loading={switchLoading}
                onChange={_handleToggle}
                disabled={platform.disabled}
                style={{ float: 'right', marginTop: 4 }}
                checked={enabled}
                size="small"
              />
            </Col>
          </Row>
        }
        description={platform.type}
      />
      {platform.disabled ? (
        <Message
          onClick={_contact}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
        />
      ) : platform.requireApiKey ? (
        <APIKey
          currentApiKey={currentApiKey}
          onChange={_handleChange}
          apiKey={apiKey}
          onSave={_handleSave}
        />
      ) : (
        <div style={{ marginTop: 15 }}></div>
      )}
    </Card>
  );
};

/**
 * @summary 왼쪽에는 설정, 오른쪽에는 연동 가이드가 있는 컴포넌트
 * @param {*} props onClick, platform, methods, onSave(_handleSave), onChange(_handleChange), onSecretChange(_handleSecretChange)
 * @returns
 */
const Actions = (props) => {
  return (
    <div style={{ margin: 0 }}>
      <div style={styles.left} onClick={props.onClick}>
        {props.platform.settingEnable && (
          <SettingModal
            method={props.methods[props.platform.name]}
            {...props}
          />
        )}
      </div>
      <div
        style={styles.right}
        onClick={
          props.platform.docs
            ? () => window.open(props.platform.docs)
            : undefined
        }
      >
        연동 가이드
      </div>
    </div>
  );
};

const Message = (props) => {
  return (
    <div style={styles.disabled} onClick={props.onClick}>
      준비중 <SmileTwoTone />
      <ContactModal isOpen={props.isModalOpen} setOpen={props.setModalOpen} />
    </div>
  );
};

const APIKey = (props) => {
  return (
    <Form layout="vertical" style={{ marginTop: 16, marginBottom: -24 }}>
      <Form.Item label={'API Key'} name="api-key" initialValue={props.apiKey}>
        <Input
          value={props.currentApiKey}
          onChange={props.onChange}
          addonAfter={
            <a>
              {props.apiKey && props.apiKey === props.currentApiKey ? (
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ cursor: 'default' }}
                />
              ) : (
                <SaveOutlined onClick={props.onSave} />
              )}
            </a>
          }
        />
      </Form.Item>
    </Form>
  );
};

export default LoginMethodCard;

const styles = {
  disabled: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    marginTop: 40,
    cursor: 'pointer',
  },
  left: { float: 'left', paddingLeft: 24 },
  right: { float: 'right', paddingRight: 24 },
};
