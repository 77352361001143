import React from 'react';
import { Row, Col, Card, Progress, Typography, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { decimalFormatter } from '../../utils/util';
import moment from 'moment';

const { Text, Title } = Typography;

const getUsageInfo = (usageType) => {
  switch (usageType) {
    case 'mau':
      return [
        'Total MAU',
        '당월 서비스에 로그인 이력이 있는 고유한 사용자 수의 합계입니다.',
      ];
    case 'email':
      return ['Email API', '당월 서비스에서 호출한 이메일 API 횟수입니다.'];
  }
};

const Usage = ({ usages, subscription_plan }) => {
  const started_at = usages[0].result.range.gte;
  const ended_at = usages[0].result.range.lt;
  usages = usages.map((usage) => {
    const [usage_name, description] = getUsageInfo(usage.type);
    return {
      ...subscription_plan.available_resources[usage.type],
      usage_name,
      description,
      consumed: usage.result.value,
    };
  });

  return (
    <div style={styles.section}>
      <Title level={5}>{`사용량 (${moment(started_at).format('MM/DD')}-${moment(
        ended_at,
      ).format('MM/DD')})`}</Title>

      <Card>
        {/* {usages.map((usage, idx) => {
          console.log(usage);
          return <UsageGuage usage={usage} key={idx} />;
        })} */}
        {usages.map((usage, idx) => {
          const {
            unit_price,
            usage_limit,
            usage_unit,
            usage_name,
            description,
            consumed,
          } = usage;
          const percent = (consumed / usage_limit) * 100;
          return (
            <Card.Grid
              hoverable={false}
              style={{
                width: '100%',
                textAlign: 'left',
              }}
              key={idx}
            >
              <Row>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Text level={5}>{usage_name}</Text>
                  <Tooltip
                    placement="right"
                    title={description}
                    trigger="hover"
                  >
                    <QuestionCircleOutlined
                      style={{ marginLeft: 5, color: '#bbb' }}
                    />
                  </Tooltip>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <Progress
                    percent={percent}
                    format={() => `${percent.toFixed(2)}%`}
                    style={{ width: '95%' }}
                    status={percent > 100 ? 'exception' : 'normal'}
                  />
                  <br />
                  <Text>{`${decimalFormatter(
                    consumed,
                  )} ${usage_unit} / ${decimalFormatter(
                    usage_limit,
                  )} ${usage_unit}`}</Text>
                </Col>
              </Row>
            </Card.Grid>
          );
        })}
      </Card>
    </div>
  );
};

const UsageGuage = ({ usage }) => {
  const {
    unit_price,
    usage_limit,
    usage_unit,
    usage_name,
    description,
    consumed,
  } = usage;
  const percent = (consumed / usage_limit) * 100;

  return (
    <Card.Grid
      hoverable={false}
      style={{
        width: '100%',
        textAlign: 'left',
      }}
    >
      <Row>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Text level={5}>{usage_name}</Text>
          <Tooltip placement="right" title={description} trigger="hover">
            <QuestionCircleOutlined style={{ marginLeft: 5, color: '#bbb' }} />
          </Tooltip>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Progress
            percent={percent}
            format={() => `${percent.toFixed(2)}%`}
            style={{ width: '95%' }}
            status={percent > 100 ? 'exception' : 'normal'}
          />
          <br />
          <Text>{`${decimalFormatter(
            consumed,
          )} ${usage_unit} / ${decimalFormatter(
            usage_limit,
          )} ${usage_unit}`}</Text>
        </Col>
      </Row>
    </Card.Grid>
  );
};

export default Usage;

const styles = {
  section: { margin: '20px 0px' },
};
