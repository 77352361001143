import React, { useContext, useEffect, useState } from 'react';
import {
  Badge,
  Table,
  Typography,
  Row,
  Col,
  Space,
  Card,
  Divider,
  Tag,
  Grid,
} from 'antd';
import moment from 'moment';
import { decimalFormatter } from '../../../utils/util';
import {
  InvoiceContext,
  AccountContext,
  OverviewContext,
} from '../../../contexts/BillingContext';
import { DownloadOutlined } from '@ant-design/icons';
const { useBreakpoint } = Grid;

function getPaymentStatus(status) {
  switch (status) {
    case 'paid':
      return ['success', '결제 완료'];
    case 'unpaid':
      return ['default', '결제 필요'];
    default:
      return ['default', '결제 필요'];
  }
}

const { Text, Title } = Typography;

const InvoiceContainer = () => {
  const { data: account } = useContext(AccountContext);
  const { data: invoices, loading, setData } = useContext(InvoiceContext);
  const { data: overview } = useContext(OverviewContext);
  const [selectedInvoice, setSelectedInvoice] = useState();

  useEffect(() => {
    setData([...invoices]);
  }, [account, overview]);

  if (loading) {
    return <>Loading ...</>;
  }
  return (
    <Space direction={'vertical'} style={{ width: '100%' }} size={20}>
      <InvoiceList data={invoices} setSelectedInvoice={setSelectedInvoice} />
      <Invoice selectedInvoice={selectedInvoice} account={account} />
    </Space>
  );
};

const InvoiceList = ({ data, setSelectedInvoice }) => {
  const columns = [
    {
      title: '청구 일자',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (date) => moment(date).format('YYYY-MM-DD'),
      width: '20%',
    },
    // {
    //   title: '플랜',
    //   dataIndex: 'title',
    //   key: 'title',
    // },
    // {
    //   title: '청구 기간',
    //   dataIndex: 'billing_period',
    //   key: 'billing_period',
    //   render: ({ started_at, ended_at }) =>
    //     `${moment(started_at).format('YYYY-MM-DD')} ~
    //       ${moment(ended_at).format('YYYY-MM-DD')}`,
    // },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (data) => {
        const [status, text] = getPaymentStatus(data);
        return <Badge status={status} text={text} />;
      },
      width: '20%',
    },
    {
      title: '청구 금액',
      dataIndex: 'total_cost',
      key: 'total_cost',
      render: (cost) => `₩${decimalFormatter(cost)}`,
      width: '20%',
    },
    {
      title: '결제 일자',
      dataIndex: 'payed_at',
      key: 'payed_at',
      render: (date) => date && moment(date).format('YYYY-MM-DD'),
      width: '20%',
    },
    // {
    //   title: 'PDF',
    //   render: () => (
    //     <a>
    //       다운로드 <DownloadOutlined />
    //     </a>
    //   ),
    // },
    {
      title: '',
      render: (record) => (
        <Space
          size="middle"
          style={{ width: '100%', justifyContent: 'center' }}
        >
          <a onClick={() => setSelectedInvoice(record)}>보기</a>
          {/* <a>
            PDF 파일 <DownloadOutlined />
          </a> */}
        </Space>
      ),
      width: '20%',
    },
  ];
  return (
    <div>
      <Title level={5}>청구 이력</Title>
      <Card bodyStyle={{ padding: 0 }}>
        <Table
          columns={columns}
          dataSource={data}
          showSorterTooltip={false}
          // onRow={(record) => {
          //   return {
          //     onClick: () => setSelectedInvoice(record),
          //   };
          // }}
        />
      </Card>
    </div>
  );
};

export const Invoice = ({ selectedInvoice, preview, account }) => {
  const { xs } = useBreakpoint();

  if (!selectedInvoice) {
    return <></>;
  }

  const {
    invoice_id,
    title,
    bill_from,
    bill_to,
    billing_period,
    items,
    status,
    created_at,
    ...cost
  } = selectedInvoice;

  const [paymentStatus, text] = getPaymentStatus(status);

  // const { data: account } = useContext(AccountContext);

  if (preview) {
    return (
      <Card>
        <Space
          direction="vertical"
          size={'large'}
          style={{
            display: 'flex',
          }}
        >
          <Row>
            <Title level={2}>청구서</Title>
          </Row>
          <Row>
            <span style={{ fontWeight: 600, fontSize: 20 }}>
              ₩{decimalFormatter(cost.total_cost)} 금액이{' '}
              {moment(created_at).format('YYYY-MM-DD')} 에 결제될 예정이에요
            </span>
          </Row>
          <Row>
            <Col span={24}>
              <Space direction="vertical" size={15} style={{ width: '100%' }}>
                <Row>
                  <Col span={6}>
                    <Text style={styles.columns}>항목</Text>
                  </Col>
                  <Col span={10}>
                    <Text style={styles.columns}>내용</Text>
                  </Col>
                  <Col span={2}>
                    <Text style={styles.columns}>수량</Text>
                  </Col>
                  <Col span={3} style={{ textAlign: 'end' }}>
                    <Text style={styles.columns}>단가</Text>
                  </Col>
                  <Col span={3} style={{ textAlign: 'end' }}>
                    <Text style={styles.columns}>금액</Text>
                  </Col>
                </Row>
                <Divider style={{ margin: 0 }} />
                {items.map((item, idx) => (
                  <Row key={idx}>
                    <Col span={6}>
                      <Text style={{ fontWeight: 600 }}>{item.name}</Text>
                      <br />
                      <Text style={{ color: '#999', fontSize: 12 }}>
                        {moment(item.started_at).format('YYYY/MM/DD')} -{' '}
                        {moment(item.ended_at).format('YYYY/MM/DD')}
                      </Text>
                    </Col>
                    <Col span={10}>{item.description}</Col>
                    <Col span={2}>{decimalFormatter(item.quantity)}</Col>
                    <Col span={3} style={{ textAlign: 'end' }}>
                      {'₩' + decimalFormatter(item.unit_price)}
                    </Col>
                    <Col span={3} style={{ textAlign: 'end' }}>
                      {'₩' + decimalFormatter(item.cost)}
                    </Col>
                  </Row>
                ))}
                <Divider />

                {xs ? (
                  <>
                    <Row>
                      <Col span={16}>
                        <Text style={{ fontWeight: 600 }}>소계</Text>
                      </Col>
                      <Col span={8} style={{ textAlign: 'end' }}>
                        {'₩' + decimalFormatter(cost.initial_cost)}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={16}>
                        <Text style={{ fontWeight: 600 }}>크레딧 사용</Text>
                      </Col>
                      <Col span={8} style={{ textAlign: 'end' }}>
                        <Text>
                          {'-₩' + decimalFormatter(cost.applied_credit)}
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={16}>
                        <Text style={{ fontWeight: 600 }}>합계</Text>
                      </Col>
                      <Col span={8} style={{ textAlign: 'end' }}>
                        <Text>{'₩' + decimalFormatter(cost.total_cost)}</Text>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col span={10} />
                      <Col span={6} />
                      <Col span={2}>
                        <Text style={{ fontWeight: 600 }}>소계</Text>
                      </Col>
                      <Col span={3} />
                      <Col span={3} style={{ textAlign: 'end' }}>
                        {'₩' + decimalFormatter(cost.initial_cost)}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10} />
                      <Col span={6} />
                      <Col span={2}>
                        <Text style={{ fontWeight: 600 }}>크레딧 사용</Text>
                      </Col>
                      <Col span={3} />
                      <Col span={3} style={{ textAlign: 'end' }}>
                        <Text>
                          {'-₩' + decimalFormatter(cost.applied_credit)}
                        </Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10} />
                      <Col span={6} />
                      <Col span={2}>
                        <Text style={{ fontWeight: 600 }}>합계</Text>
                      </Col>
                      <Col span={3} />
                      <Col span={3} style={{ textAlign: 'end' }}>
                        <Text>{'₩' + decimalFormatter(cost.total_cost)}</Text>
                      </Col>
                    </Row>
                  </>
                )}
              </Space>
            </Col>
          </Row>
        </Space>
      </Card>
    );
  }

  return (
    <Card>
      <Space
        direction="vertical"
        size={'large'}
        style={{
          display: 'flex',
        }}
      >
        <Row>
          <Title level={2}>청구서</Title>
        </Row>
        <Row>
          <Col span={24}>
            <Item title={'청구서 ID'} content={invoice_id} />
            <br />
            <Item
              title={'청구 일자'}
              content={moment(created_at).format('YYYY-MM-DD')}
            />
            <br />
            <Item
              title={'결제 기한'}
              content={moment(created_at).format('YYYY-MM-DD')}
            />
            <br />
            <Item
              title={'결제 상태'}
              content={<Badge status={paymentStatus} text={text} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <span style={{ fontWeight: 600 }}>청구자</span>
            <br />
            <span>주식회사 호패</span>
            <br />
            <span>서울시 강남구 강남대로364 미왕빌딩 1701호</span>
            <br />
            <span>support@hopae.io</span>
            <br />
            <span>262-88-02303</span>
          </Col>
          <Col span={12}>
            <span style={{ fontWeight: 600 }}>사용자</span>
            <br />
            <span>{account.name}</span>
            <br />
            <span>
              {account.address && account.address_detail
                ? account.address + ' ' + account.address_detail
                : account.address
                ? account.address
                : null}
            </span>
            <br />
            <span>{account.email}</span>
            <br />
            <span>{account.business_registration_number}</span>
          </Col>
        </Row>
        <Row>
          <span style={{ fontWeight: 600, fontSize: 20 }}>
            ₩{decimalFormatter(cost.total_cost)} 금액을{' '}
            {moment(created_at).format('YYYY-MM-DD')} 까지 결제해주세요
          </span>
        </Row>
        <Row>
          <Col span={24}>
            <Space direction="vertical" size={15} style={{ width: '100%' }}>
              <Row>
                <Col span={6}>
                  <Text style={styles.columns}>항목</Text>
                </Col>
                <Col span={10}>
                  <Text style={styles.columns}>내용</Text>
                </Col>
                <Col span={2}>
                  <Text style={styles.columns}>수량</Text>
                </Col>
                <Col span={3} style={{ textAlign: 'end' }}>
                  <Text style={styles.columns}>단가</Text>
                </Col>
                <Col span={3} style={{ textAlign: 'end' }}>
                  <Text style={styles.columns}>금액</Text>
                </Col>
              </Row>
              <Divider style={{ margin: 0 }} />
              {items.map((item, idx) => (
                <Row key={idx}>
                  <Col span={6}>
                    <Text style={{ fontWeight: 600 }}>{item.name}</Text>
                    <br />
                    <Text style={{ color: '#999', fontSize: 12 }}>
                      {moment(item.started_at).format('YYYY/MM/DD')} -{' '}
                      {moment(item.ended_at).format('YYYY/MM/DD')}
                    </Text>
                  </Col>
                  <Col span={10}>{item.description}</Col>
                  <Col span={2}>{decimalFormatter(item.quantity)}</Col>
                  <Col span={3} style={{ textAlign: 'end' }}>
                    {'₩' + decimalFormatter(item.unit_price)}
                  </Col>
                  <Col span={3} style={{ textAlign: 'end' }}>
                    {'₩' + decimalFormatter(item.cost)}
                  </Col>
                </Row>
              ))}
              <Divider />

              {xs ? (
                <>
                  <Row>
                    <Col span={16}>
                      <Text style={{ fontWeight: 600 }}>소계</Text>
                    </Col>
                    <Col span={8} style={{ textAlign: 'end' }}>
                      {'₩' + decimalFormatter(cost.initial_cost)}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16}>
                      <Text style={{ fontWeight: 600 }}>크레딧 사용</Text>
                    </Col>
                    <Col span={8} style={{ textAlign: 'end' }}>
                      <Text>
                        {'-₩' + decimalFormatter(cost.applied_credit)}
                      </Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16}>
                      <Text style={{ fontWeight: 600 }}>합계</Text>
                    </Col>
                    <Col span={8} style={{ textAlign: 'end' }}>
                      <Text>{'₩' + decimalFormatter(cost.total_cost)}</Text>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col span={10} />
                    <Col span={6} />
                    <Col span={2}>
                      <Text style={{ fontWeight: 600 }}>소계</Text>
                    </Col>
                    <Col span={3} />
                    <Col span={3} style={{ textAlign: 'end' }}>
                      {'₩' + decimalFormatter(cost.initial_cost)}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10} />
                    <Col span={6} />
                    <Col span={2}>
                      <Text style={{ fontWeight: 600 }}>크레딧 사용</Text>
                    </Col>
                    <Col span={3} />
                    <Col span={3} style={{ textAlign: 'end' }}>
                      <Text>
                        {'-₩' + decimalFormatter(cost.applied_credit)}
                      </Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10} />
                    <Col span={6} />
                    <Col span={2}>
                      <Text style={{ fontWeight: 600 }}>합계</Text>
                    </Col>
                    <Col span={3} />
                    <Col span={3} style={{ textAlign: 'end' }}>
                      <Text>{'₩' + decimalFormatter(cost.total_cost)}</Text>
                    </Col>
                  </Row>
                </>
              )}
            </Space>
          </Col>
        </Row>
      </Space>
    </Card>
  );
};

const Item = ({ title, content }) => {
  return (
    <>
      <span style={{ width: 120, display: 'inline-block', fontWeight: 600 }}>
        {title}
      </span>
      <span>{content}</span>
    </>
  );
};

const styles = {
  columns: { fontWeight: 600 },
  section: { margin: '20px 0px' },
};

export default InvoiceContainer;
