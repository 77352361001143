import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/reset.css';
import './index.css';

import App from './App';

if (process.env.REACT_APP_NODE_ENV === 'development') {
  localStorage.setItem('development', true);
  localStorage.setItem(
    `furo-${process.env.REACT_APP_FURO_CLIENT_ID}-token`,
    process.env.REACT_APP_DEVELOPER_ACCESS_TOKEN,
  );
  localStorage.setItem(
    `furo-${process.env.REACT_APP_FURO_CLIENT_ID}-refresh`,
    process.env.REACT_APP_DEVELOPER_REFRESH_TOKEN,
  );
}
ReactDOM.render(<App />, document.getElementById('root'));
