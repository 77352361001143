import { useState } from 'react';
import { Modal, message, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { Button } from '../../components';
import { useFuro } from 'furo-react';
import { deleteProject } from '../../hooks/useAPI';
const { Title } = Typography;

const DeleteProjectModal = ({ projectId }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { getAccessTokenSilently } = useFuro();

  const _showModal = () => {
    setVisible(true);
  };

  const _handleOk = async () => {
    setConfirmLoading(true);
    const { res, err } = await deleteProject(projectId, {
      getToken: getAccessTokenSilently,
    });
    setConfirmLoading(false);
    // console.log(res);
    if (err || res.status !== 200) {
      message.error({
        message: '프로젝트 삭제에 실패하였습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    } else {
      setVisible(false);
      message.success({
        message: '프로젝트가 성공적으로 삭제되었습니다.',
      });
      window.location.href = window.location.origin + '/projects';
    }
  };

  const _handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={_showModal} danger>
        프로젝트 삭제 <DeleteOutlined />
      </Button>
      <Modal
        title="DELETE"
        open={visible}
        onOk={_handleOk}
        confirmLoading={confirmLoading}
        onCancel={_handleCancel}
      >
        <Title level={3}>정말로 프로젝트를 삭제하시겠습니까?</Title>
      </Modal>
    </>
  );
};
export default DeleteProjectModal;
