import styledCSS from '../styles/Modal.module.css';

const Preview = (props) => {
  const { previewUrl } = props;
  return (
    <iframe
      frameBorder="0"
      id="preview"
      title="Login Preview"
      src={previewUrl}
      onLoadStart={() => console.log('iframe load start')}
      style={{
        width: '100%',
        height: '100%',
      }}
      // scrolling="no"
    />
  );
};

export default Preview;
