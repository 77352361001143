import React, { useState, useContext } from 'react';
import {
  Button,
  Modal,
  Form,
  Space,
  InputNumber,
  Select,
  Row,
  Col,
  Input,
  notification,
  Alert,
  Typography,
} from 'antd';
import { CreditCardOutlined, CheckOutlined } from '@ant-design/icons';
import { registerCard } from '../../hooks/useAPI';
import { OverviewContext, AccountContext } from '../../contexts/BillingContext';
import mixpanel from '../../libs/mixpanel';
import { useFuro } from 'furo-react';

const { Text } = Typography;

const PaymentMethodModal = ({
  button,
  visible,
  setVisible,
  setCardRegistered,
  isDefault,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { data: account, getAccessTokenSilently } =
    useContext(AccountContext) ?? {};
  const { data: overview, setData: setOverview } =
    useContext(OverviewContext) ?? {};
  const [okBtnDisabled, setokBtnDisabled] = useState(true);
  const { user } = useFuro();

  const _onOk = async () => {
    mixpanel.track('Payment Method Register', {
      UID: user.uid,
      distinct_id: user.uid,
    });
    setConfirmLoading(true);
    try {
      // get values from form
      const formValues = await form.validateFields();
      const values = formValues;
      values.card_number = values.card_number.replaceAll(' ', '');

      // call register card api (set card as default if some props exists)
      const [card, err] = await registerCard(
        account.account_id,
        values,
        {
          getToken: getAccessTokenSilently,
        },
        isDefault,
      );

      // handle error
      if (err) {
        setConfirmLoading(false);
        notification.error({
          message: '결제수단 등록에 실패하였습니다.',
          description: '유효한 카드정보를 입력하세요',
        });
        mixpanel.track('Payment Method Register Fail', {
          UID: user.uid,
          distinct_id: user.uid,
          Error: JSON.stringify(err.response.data),
        });
      }
      // handle success
      else if (card) {
        let cards = [...overview.cards, card];
        // if (account.default_card_id) {
        //   cards = cards.map((card) =>
        //     card.card_id === account.default_card_id
        //       ? { ...card, default: true }
        //       : card,
        //   );
        // } else {
        //   cards = cards.map((card) => {
        //     return { ...card, default: true };
        //   });
        // }

        setOverview({ ...overview, cards });
        setConfirmLoading(false);
        setVisible(false);
        if (setCardRegistered) setCardRegistered(true);
        notification.success({
          message: '결제수단이 성공적으로 등록되었습니다.',
          description: '설정 탭에서 반영된 내용을 살펴보세요.',
        });
        mixpanel.track('Payment Method Register Success', {
          UID: user.uid,
          distinct_id: user.uid,
        });
      }
    } catch (err) {
      mixpanel.track('Payment Method Register Fail', {
        UID: user.uid,
        distinct_id: user.uid,
        Error: JSON.stringify(err.response.data),
      });
      console.error(err);
      notification.error({
        message: '결제수단 등록에 실패하였습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    } finally {
      setConfirmLoading(false);
      setVisible(false);
      form.resetFields();
    }
  };

  return (
    <>
      {button && (
        <Button
          onClick={() => {
            mixpanel.track('Payment Method Modal Open', {
              UID: user.uid,
              distinct_id: user.uid,
            });
            setVisible(true);
          }}
        >
          결제 수단 추가
        </Button>
      )}
      <Modal
        title="결제 수단 등록"
        centered
        open={visible}
        onCancel={() => setVisible(false)}
        okText="등록"
        cancelText="취소"
        onOk={_onOk}
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: okBtnDisabled }}
      >
        <FormLayout form={form} setokBtnDisabled={setokBtnDisabled} />
        <Notice />
      </Modal>
    </>
  );
};

const Notice = () => {
  return (
    <Alert
      message={
        <Space direction="vertical" size={2}>
          <div style={{ display: 'flex' }}>
            <CheckOutlined style={{ marginRight: 5, marginTop: 3 }} />
            <Text>
              이용료 결제용 카드 등록(Furo 이용료는 모두 '카드결제'로 진행)
            </Text>
          </div>
          <div style={{ display: 'flex' }}>
            <CheckOutlined style={{ marginRight: 5, marginTop: 3 }} />
            <Text>
              결제용 카드는 신용/체크, 법인/개인 관계 없이 모두 등록 가능
            </Text>
          </div>
          <div style={{ display: 'flex' }}>
            <CheckOutlined style={{ marginRight: 5, marginTop: 3 }} />
            <Text>
              기명식 법인카드로 등록하는 경우, 사용자 주민번호 앞 6자리로 기재
            </Text>
          </div>
          <div style={{ display: 'flex' }}>
            <CheckOutlined style={{ marginRight: 5, marginTop: 3 }} />
            <Text>
              법인카드 등록이 어려운 경우, 개인카드로 비용처리 혹은 추후
              법인카드로 변경 후 재결제 가능
            </Text>
          </div>
          <div style={{ display: 'flex' }}>
            <CheckOutlined style={{ marginRight: 5, marginTop: 3 }} />
            <Text>등록 오류 시 오른쪽 아래 채널톡을 통해 1:1 문의</Text>
          </div>
        </Space>
      }
      type="info"
    />
  );
};

const FormLayout = ({ form, setokBtnDisabled }) => {
  const onInputCardNumber = ({ target }) => {
    const rawText = [...target.value.split(' ').join('')];
    const creditCard = [];
    rawText.forEach((t, i) => {
      if (i % 4 === 0 && i !== 0) creditCard.push(' '); // Add space
      creditCard.push(t);
    });
    target.value = creditCard.join('');
  };
  return (
    <Form
      layout="vertical"
      form={form}
      onFieldsChange={() => {
        const isEmpty = Object.keys(form.getFieldsValue(true)).length < 5;
        const hasError = form
          .getFieldsError()
          .some((field) => field.errors.length > 0);
        setokBtnDisabled(isEmpty || hasError);
      }}
    >
      <Form.Item
        label="카드 번호"
        required
        name="card_number"
        rules={[
          {
            required: true,
            message: '카드 번호를 입력하세요',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const reg = /[^0-9]/g;
              if (reg.test(value.replaceAll(' ', ''))) {
                return Promise.reject(new Error('숫자만 입력하세요'));
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input
          addonBefore={<CreditCardOutlined />}
          placeholder="카드 번호를 입력하세요"
          maxLength={19}
          minLength={18}
          onInput={onInputCardNumber}
        />
      </Form.Item>

      <Row gutter={15}>
        <Col span={12}>
          <Form.Item label="카드 유효기간">
            <Input.Group>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    noStyle
                    name={'expiration_month'}
                    rules={[
                      {
                        required: true,
                        message: '월을 입력하세요',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const reg = /[^0-9]/g;

                          if (reg.test(value)) {
                            return Promise.reject(
                              new Error('숫자만 입력하세요'),
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="MM"
                      style={{ width: '100%' }}
                      maxLength={2}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    noStyle
                    name={'expiration_year'}
                    rules={[
                      {
                        required: true,
                        message: '연을 입력하세요',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const reg = /[^0-9]/g;
                          if (reg.test(value)) {
                            return Promise.reject(
                              new Error('숫자만 입력하세요'),
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="YY"
                      style={{ width: '100%' }}
                      maxLength={2}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="카드 비밀번호 앞 2자리"
            required
            name="pwd_2digit"
            rules={[
              {
                required: true,
                message: '카드 비밀번호를 입력하세요',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const reg = /[^0-9]/g;
                  if (reg.test(value)) {
                    return Promise.reject(new Error('숫자만 입력하세요'));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input.Password style={{ width: '100%' }} maxLength={2} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="주민번호 앞 6자리(또는 사업자 등록번호 10자리)"
        required
        name="birth"
        rules={[
          {
            required: true,
            message: '주민번호 또는 사업자 등록번호를 입력하세요',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              const reg = /[^0-9]/g;
              if (reg.test(value)) {
                return Promise.reject(new Error('숫자만 입력하세요'));
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input style={{ width: '100%' }} maxLength={10} />
      </Form.Item>
    </Form>
  );
};

export default PaymentMethodModal;
