import { Button, Grid } from 'antd';
const { useBreakpoint } = Grid;

export default function GetStartedButton({ children, menu }) {
  const { xs } = useBreakpoint();

  return (
    <Button
      style={{
        minWidth: menu && xs ? '6em' : '10em',
        height: 50,
        borderRadius: 12,
        fontSize: '1.1em',
        fontWeight: '600',
      }}
      type="primary"
      onClick={() => (window.location.href = './projects')}
    >
      {children}
    </Button>
  );
}
