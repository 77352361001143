import React, { useState } from 'react';
import { Button as ButtonComponent } from '../../components';
import { Modal, Typography } from 'antd';
import styledCSS from '../../styles/Modal.module.css';
import PricingPlan from './PricingPlan';
import mixpanel from '../../libs/mixpanel';
import { useFuro } from 'furo-react';

const { Title, Text } = Typography;

const UpgradePlanModal = ({ currentPlan }) => {
  const [modalVisible, setModalVisible] = useState(false);
  currentPlan = currentPlan.replace('Furo ', '');
  const { user } = useFuro();

  return (
    <>
      <div>
        <ButtonComponent
          type="primary"
          onClick={() => {
            mixpanel.time_event('Pricing Plan Modal Opened', {
              UID: user.uid,
              distinct_id: user.uid,
            });
            setModalVisible(true);
          }}
        >
          플랜 변경
        </ButtonComponent>
      </div>
      <Modal
        centered
        open={modalVisible}
        onCancel={() => {
          mixpanel.track('Pricing Plan Modal Opened', {
            UID: user.uid,
            distinct_id: user.uid,
          });
          setModalVisible(false);
        }}
        footer={null}
        width={'clamp(300px, 85%, 1040px)'}
        bodyStyle={{
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 200px)',
        }}
        className={styledCSS.modal}
      >
        <PricingPlan
          currentPlan={currentPlan}
          setModalVisible={setModalVisible}
        />
      </Modal>
    </>
  );
};

export default UpgradePlanModal;
