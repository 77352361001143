import { useState } from 'react';
import { Modal, notification, Alert, Button } from 'antd';
import { updateProject } from '../../hooks/useAPI';
import { useFuro } from 'furo-react';
import { getProject } from '../../hooks/useAPI';
import mixpanel from '../../libs/mixpanel';

export default function TermModal(props) {
  const { projectId } = props;
  // const { project, projectId } = props;
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { user, getAccessTokenSilently } = useFuro();
  const [form] = Form.useForm();

  const { project } = getProject(projectId, {
    getToken: getAccessTokenSilently,
  });

  const _onOk = async () => {
    setConfirmLoading(true);
    try {
      // get values from form
      const values = await form.validateFields();

      // refine data
      let data = [];
      Object.entries(values)
        .sort(([i1, v1], [i2, v2]) => {
          const [_, opt1] = i1.split('__');
          const [__, opt2] = i2.split('__');
          return opt1.length - opt2.length;
        })
        .forEach(([key, value]) => {
          const [type, option] = key.split('__');

          // TODO : 라벨을 values 로 가져와서 바로 데이터 만들기
          if (option === 'link') {
            if (!!value) {
              data = [
                ...data,
                {
                  name:
                    type === 'tos'
                      ? '서비스 이용약관'
                      : '개인정보 수집·이용약관',
                  type: type,
                  link: value,
                },
              ];
            }
          } else {
            const datum = data.filter((term) => {
              return term.type === type;
            });
            if (!!datum[0]) {
              data = [...data, { ...datum[0], required: value ?? false }];
            }
          }
        });

      data = {
        terms: data.filter((term) => {
          return term.required !== undefined;
        }),
      };

      // call project terms api
      const [project, error] = await updateProject(projectId, data, {
        getToken: getAccessTokenSilently,
      });
      // handle error
      if (error) {
        const status = JSON.parse(JSON.stringify(error)).status;
        console.error(error);
        setConfirmLoading(false);
        if (status === 400) {
          notification.error({
            message: '개인정보 약관 설정에 실패하였습니다.',
            description: '유효한 URL을 입력하세요',
          });
        } else {
          notification.error({
            message: '개인정보 약관 설정에 실패하였습니다.',
            description: '이 현상이 지속될 시 관리자에게 문의하세요',
          });
        }
      }
      // handle success
      else if (project) {
        setConfirmLoading(false);
        setVisible(false);
        notification.success({
          message: '개인정보 약관 설정이 반영되었습니다.',
          description: '로그인 페이지 보기로 반영된 내용을 살펴보세요.',
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: '개인정보 약관 설정에 실패하였습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <div>
      <div style={styles.setting} onClick={() => setVisible(true)}>
        설정
      </div>
      <Modal
        title="개인정보 약관 설정"
        centered
        open={visible}
        onOk={_onOk}
        confirmLoading={confirmLoading}
        onCancel={() => setVisible(false)}
        okText="설정"
        cancelText="취소"
      >
        <Dialog form={form} terms={project.terms} />
        <img
          src="/images/catchsecu.png"
          onClick={() => {
            mixpanel.track('Catchsecu Banner Open', {
              UID: user.uid,
              distinct_id: user.uid,
            });
            window.open(
              'https://www.catchsecu.com/?utm_source=partner&utm_medium=banner&utm_campaign=furo',
            );
          }}
          style={{ cursor: 'pointer' }}
        />
        {/* <Alert
          message="현재 개인정보 약관이 없으신가요?"
          description="캐치시큐로 손쉽게 만들어 보세요"
          type="info"
          showIcon
          action={
            <Button
              size="small"
              type="ghost"
              onClick={() =>
                window.open('https://app.catchsecu.com/form/form-template')
              }
            >
              Go
            </Button>
          }
        /> */}
      </Modal>
    </div>
  );
}

import { Form, Input } from 'antd';
import { Space, Typography, Checkbox } from 'antd';

const { Paragraph, Text } = Typography;

const Dialog = ({ form, terms }) => {
  const getInitialValues = (terms) => {
    return terms.reduce((acc, term) => {
      return Object.assign(
        {
          [term.type + '__link']: term.link,
          [term.type + '__required']: term.required,
        },
        acc,
      );
    }, {});
  };

  const [termState, setTermState] = useState(getInitialValues(terms));

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={true}
      initialValues={termState}
    >
      <FormItem
        name={'서비스 이용약관'}
        type={'tos'}
        required={termState['tos__required']}
        setTermState={setTermState}
        termState={termState}
      />
      <FormItem
        name={'개인정보 수집·이용약관'}
        type={'sign_up'}
        required={termState['sign_up__required']}
        setTermState={setTermState}
        termState={termState}
      />

      {
        // TODO:사용자 지정 약관 등록 시 불러오기
        /* {terms.map((term, idx) => (
        <FormItem
          name={term.name}
          type={term.type}
          required={termState[term.type + '__required']}
          setTermState={setTermState}
          termState={termState}
          key={idx}
        />
      ))} */
      }
    </Form>
  );
};

const FormItem = ({ name, type, required, setTermState, termState }) => {
  return (
    <div>
      <Paragraph style={styles.label}>
        {name}

        <div>
          <span
            style={{
              paddingRight: 5,
              color: required ? '#1990ff' : '#aaa',
              fontSize: '12px',
            }}
          >
            필수
          </span>
          <Form.Item name={`${type}__required`} valuePropName="checked" noStyle>
            <Checkbox
              defaultChecked={required}
              onChange={(e) => {
                setTermState({
                  ...termState,
                  [type + '__required']: e.target.checked,
                });
              }}
            />
          </Form.Item>
        </div>
      </Paragraph>
      <Form.Item
        name={`${type}__link`}
        // rules={[
        //   {
        //     validator: async (_, agreed = []) => {
        //       console.log(err);
        //       if (err) {
        //         return Promise.reject(new Error('유효한 URL를 입력하세요'));
        //       }
        //     },
        //   },
        // ]}
      >
        <Input placeholder={'URL을 입력하세요'} />
      </Form.Item>
    </div>
  );
};

const styles = {
  button: {
    padding: '0px',
    paddingLeft: 15,
    paddingRight: 15,
    margin: 0,
    blockSize: 'fit-content',
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  setting: {
    float: 'left',
    paddingLeft: 24,
    color: '#1990ff',
  },
};
