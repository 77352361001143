import React, { useState, useEffect, createContext } from 'react';
import { useFuro } from 'furo-react';
import {
  getAccountOverview,
  getAccount,
  createAccount,
  searchTeams,
  getInvoices,
} from '../hooks/useAPI';

export const AccountContext = createContext(null);

/**
 * @summary Billing 모듈 사용을 위한 Account를 제공하는 Provider
 */
export const AccountProvider = ({ children }) => {
  const accountProps = useAccount();

  return (
    <AccountContext.Provider value={accountProps}>
      <>{children}</>
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { user, getAccessTokenSilently } = useFuro();

  useEffect(async () => {
    try {
      const teams = await searchTeams(user.uid, {
        getToken: getAccessTokenSilently,
      });
      const [account, err] = await getAccount(teams[0].tid, {
        getToken: getAccessTokenSilently,
      });

      // if (!account) {
      //   const [account, err] = await createAccount(teams[0].tid, {
      //     getToken: getAccessTokenSilently,
      //   });
      //   console.log('account created :::', account);
      //   setError(err);
      // } else {
      // console.log('account exists :::', account);
      // }
      setData(account);
      setError(err);
    } catch {
    } finally {
      setLoading(false);
    }
  }, []);

  return { data, error, loading, setData, getAccessTokenSilently };
};

export const OverviewContext = createContext(null);

/**
 * @summary Settings/tabs/General 탭에 들어갈 결제 Overview 데이터를 제공하는 Provider
 */
export const OverviewProvider = ({ children }) => {
  const overviewProps = useOverview();

  return (
    <OverviewContext.Provider value={overviewProps}>
      <>{children}</>
    </OverviewContext.Provider>
  );
};

const useOverview = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { user, getAccessTokenSilently } = useFuro();

  useEffect(async () => {
    try {
      const teams = await searchTeams(user.uid, {
        getToken: getAccessTokenSilently,
      });

      const [data, error] = await getAccountOverview(teams[0].tid, {
        getToken: getAccessTokenSilently,
      });

      if (error) {
        setError(error);
      } else if (data) {
        setData(data);
      }
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return { data, error, loading, setData, getAccessTokenSilently };
};

export const InvoiceContext = createContext(null);

/**
 * @summary Settings/tabs/Invoice 탭에 들어갈 결제 청구서 데이터를 제공하는 Provider
 */
export const InvoiceProvider = ({ children }) => {
  const invoiceProps = useInvoice();

  return (
    <InvoiceContext.Provider value={invoiceProps}>
      <>{children}</>
    </InvoiceContext.Provider>
  );
};

const useInvoice = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { user, getAccessTokenSilently } = useFuro();

  useEffect(async () => {
    try {
      const teams = await searchTeams(user.uid, {
        getToken: getAccessTokenSilently,
      });
      const [invoices, error] = await getInvoices(teams[0].tid, {
        getToken: getAccessTokenSilently,
      });
      setData(invoices);
      setError(error);
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return { data, error, loading, setData, getAccessTokenSilently };
};
