import React from 'react';
import {
  Avatar,
  Space,
  Layout,
  Row,
  Col,
  Typography,
  Grid,
  Button,
} from 'antd';
import styles from '../styles/Animation.module.css';
import { loginMe, developers, CTA, JOIN } from '../data/index';
import TextTransition, { presets } from 'react-text-transition';
import { GetStartedButton } from '../components';

const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const TEXTS = {
  0: {
    text: '로그인 페이지',
    color: '#E95A6C',
  },
  1: {
    text: '카카오로 로그인',
    color: '#FDCE00',
  },
  2: {
    text: '네이버로 로그인',
    color: '#2DB400',
  },
  3: {
    text: '본인 인증 페이지',
    color: '#fa8c16',
  },
};

const Home = () => {
  const { xs } = useBreakpoint();
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      4000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <Space direction="vertical" size={64}>
      {/* LOGO */}
      <Row justify="center">
        <Avatar
          size={xs ? 160 : 200}
          src="/furo.svg"
          className={styles.gradient}
        />
      </Row>

      {/* TITLE */}
      <Row justify="center">
        <Col span={24}>
          <Title style={{ fontSize: xs ? 32 : 72, fontWeight: 700, margin: 0 }}>
            <TextTransition
              text={TEXTS[index % Object.keys(TEXTS).length].text}
              sprintConfig={presets.default}
              inline={true}
              style={{
                color: TEXTS[index % Object.keys(TEXTS).length].color,
              }}
            />
          </Title>
        </Col>
        <Col span={24}>
          <Title style={{ fontSize: xs ? 32 : 72, fontWeight: 700, margin: 0 }}>
            {loginMe}
          </Title>
        </Col>
      </Row>

      {/* DESCRIPTION */}
      <Row justify="center">
        <Col span={xs ? 24 : 12}>
          <Paragraph>{developers.we}</Paragraph>
          <Paragraph>{developers.p1}</Paragraph>
          <Paragraph>{developers.p2}</Paragraph>
        </Col>
      </Row>

      {/* CTA */}
      <Layout
        style={{
          padding: 32,
          borderRadius: 16,
          maxWidth: 720,
          margin: 'auto',
        }}
      >
        <Row justify="center">
          <Col span={xs ? 24 : 12}>
            <Title>{CTA.t1}</Title>
            <Text style={{ fontSize: 16 }}>{CTA.t2}</Text>
          </Col>
          <Col span={24} style={{ paddingTop: 20 }}>
            <GetStartedButton>Create Your Project</GetStartedButton>
          </Col>
        </Row>
      </Layout>

      {/* JOIN */}
      <Row justify="center">
        <Col span={xs ? 24 : 12}>
          <Paragraph>{JOIN.p1}</Paragraph>
          <Paragraph>{JOIN.p2}</Paragraph>
          <Paragraph>{JOIN.p3}</Paragraph>
          <a href="https://page.stibee.com/subscriptions/157251">
            Subscribe to Furo
          </a>
        </Col>
      </Row>
    </Space>
  );
};

export default Home;
