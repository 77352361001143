import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Space,
  ConfigProvider,
  Modal,
  Typography,
  Input,
  Form,
  Button,
} from 'antd';
import { useState, useEffect } from 'react';
import {
  deleteApiKey,
  createApiKey,
  getApiKeys,
  updateApiOrigins,
} from '../hooks/useAPI';
import { useFuro } from 'furo-react';
import CopyButton from './CopyButton';

const { Text } = Typography;
const { TextArea, Password } = Input;

const APIKeys = (props) => {
  const { teams, project, type } = props;
  const [apiKeyTags, setApiKeyTags] = useState();

  const { getAccessTokenSilently } = useFuro();

  const [targetKey, setTargetKey] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [settingModalVisible, setSettingModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [okBtnDisabled, setokBtnDisabled] = useState(true);

  const onDeleteConfirm = (apikey) => {
    setTargetKey(apikey);
    setDeleteModalVisible(true);
  };

  const onApiKeyCreate = async () => {
    const [data] = await createApiKey(
      project.pid,
      teams[0].tid,
      {
        getToken: getAccessTokenSilently,
      },
      { type, env: 'live' },
    );

    setApiKeyTags((current) => {
      return current ? [...current, data.api_key] : [data.api_key];
    });
  };

  const onApiKeyDelete = () => {
    deleteApiKey(project.pid, targetKey, teams[0].tid, {
      getToken: getAccessTokenSilently,
    });
    setApiKeyTags(apiKeyTags.slice(0, -1));
    handleTagClose(targetKey);
    setDeleteModalVisible(false);
  };

  const onSettingConfirm = async () => {
    const { origins } = await form.validateFields();
    console.log(origins.split(','));
    updateApiOrigins(project.pid, targetKey, teams[0].tid, origins.split(','), {
      getToken: getAccessTokenSilently,
    });
    setSettingModalVisible(false);
  };

  const handleTagClose = (removedTag) => {
    const newTags = apiKeyTags.filter((tag) => tag !== removedTag);
    setApiKeyTags(newTags);
  };

  useEffect(async () => {
    if (teams) {
      const [data] = await getApiKeys(
        project.pid,
        teams[0].tid,
        {
          getToken: getAccessTokenSilently,
        },
        { type },
      );
      setApiKeyTags(data?.map((apiKeyObj) => apiKeyObj.api_key));
    }
  }, [teams]);

  return (
    <ConfigProvider>
      {apiKeyTags &&
        apiKeyTags.map((apiKeyTag) => (
          <Password
            value={apiKeyTag}
            addonAfter={
              <Space>
                <CopyButton
                  inset={false}
                  _onClick={() => {
                    navigator.clipboard.writeText(apiKeyTag);
                  }}
                />
                <DeleteOutlined onClick={() => onDeleteConfirm(apiKeyTag)} />
              </Space>
            }
            style={{ marginBottom: 10 }}
          />
        ))}

      <div style={{ float: 'right' }}>
        <Button icon={<PlusOutlined />} onClick={onApiKeyCreate} size="small">
          Create New API Key
        </Button>
      </div>
      <Modal
        title="API Key 삭제"
        open={deleteModalVisible}
        centered
        onOk={onApiKeyDelete}
        onCancel={() => setDeleteModalVisible(false)}
        okText="삭제"
        cancelText="취소"
      >
        <Text>정말로 API Key를 삭제하시겠습니까?</Text>
      </Modal>
      <Modal
        title="Allowed Origin List"
        open={settingModalVisible}
        centered
        onOk={onSettingConfirm}
        onCancel={() => setSettingModalVisible(false)}
        okText="저장"
        okButtonProps={{ disabled: okBtnDisabled }}
        cancelText="취소"
      >
        <Form
          form={form}
          onFieldsChange={() => {
            const isEmpty = form.getFieldValue('origins').length < 1;
            setokBtnDisabled(isEmpty);
          }}
        >
          <Form.Item name={'origins'}>
            <TextArea
              placeholder="ex) http://localhost:3000, http://localhost:3001"
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export default APIKeys;
