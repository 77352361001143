import React, { useState } from 'react';
import { Layout, Menu, Row, Col, Typography, Tabs } from 'antd';
import { UserOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Pricing, Account } from './tabs';
import {
  GeneralContainer as General,
  InvoiceContainer as Invoice,
  CreditContainer as Credit,
  SettingContainer as Setting,
} from '../../containers';

const { Content, Sider } = Layout;
const { Text } = Typography;

function SettingsContainer() {
  return (
    <div style={{ padding: '0px 20px' }}>
      <Title
        title="설정"
        description="지금 바로 플랜을 구독해 Furo의 모든 기능을 누려보세요."
      />
      <Row
        style={{
          marginTop: 30,
          borderRadius: 8,
        }}
      >
        <Col span={24}>
          {/* <General /> */}
          <SettingsTab />
          {/* <SettingsSider /> */}
        </Col>
      </Row>
    </div>
  );
}

const Title = (props) => {
  const { title, description, right } = props;
  return (
    <Row align="middle" justify="space-between">
      <Col xs={16} sm={16}>
        <Typography.Title level={2} style={{ marginBottom: 6 }}>
          {title}
        </Typography.Title>
        <Text type="secondary">{description}</Text>
      </Col>
      <Col>{right}</Col>
    </Row>
  );
};

const tabItems = [
  {
    label: '내 계정',
    key: 'item-1',
    children: <Account />,
  },
  {
    label: '구독',
    key: 'item-2',
    children: <General />,
  },
  {
    label: '청구서',
    key: 'item-3',
    children: <Invoice />,
  },
  // {
  //   label: '크레딧',
  //   key: 'item-3',
  //   children: <Credit />,
  // },
  // {
  //   label: (
  //     <>
  //       <SettingOutlined style={{ marginRight: 6 }} />
  //       결제 정보
  //     </>
  //   ),
  //   key: 'item-4',
  //   children: <Setting />,
  // },
  {
    label: '결제 정보',
    key: 'item-4',
    children: <Setting />,
  },
];

const SettingsTab = () => {
  return (
    <Tabs
      items={tabItems}
      // tabBarExtraContent={<Button>정보 수정</Button>}
    />
  );
};

function getMenu(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const menus = [
  getMenu('일반', '1', <UserOutlined />),
  getMenu('결제', 'sub', <CreditCardOutlined />, [
    getMenu('플랜', '2'),
    getMenu('청구서', '3'),
    getMenu('크레딧', '4'),
  ]),
];

const SettingsSider = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('1');

  function renderMenu(selectedMenu) {
    switch (selectedMenu) {
      case '1':
        return <General />;
      case '2':
        return <Pricing />;
      case '3':
        return <Invoice />;
      case '4':
        return <Credit />;
      default:
        return <General />;
    }
  }

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        theme="light"
        trigger={null}
      >
        <Menu
          defaultSelectedKeys={['1']}
          mode="inline"
          items={menus}
          onSelect={({ key }) => {
            setSelectedMenu(key);
          }}
        />
      </Sider>
      <Layout style={{ backgroundColor: '#fff' }}>
        <Content style={{ margin: '0 16px' }}>
          <div style={{ padding: 24, minHeight: 360 }}>
            {renderMenu(selectedMenu)}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SettingsContainer;
