import React, { useContext, useState } from 'react';
import {
  Badge,
  Table,
  Typography,
  Row,
  Col,
  Space,
  Card,
  Statistic,
} from 'antd';
import { OverviewContext } from '../../../contexts/BillingContext';
import moment from 'moment';
import { decimalFormatter } from '../../../utils/util';
import CreditModal from '../CreditModal';

const { Title } = Typography;

function getCreditStatus(status) {
  switch (status) {
    case 'active':
      return ['success', '활성'];
    default:
      return ['default', '비활성'];
  }
}

const columns = [
  {
    title: '이름',
    dataIndex: 'credit_name',
    key: 'credit_name',
  },
  {
    title: '금액',
    dataIndex: 'amount',
    key: 'amount',
    render: (cost) => `₩${decimalFormatter(cost)}`,
  },
  {
    title: '사용 기간',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (created_at, record) =>
      `${moment(created_at).format('YYYY-MM-DD')} ~
        ${moment(record.expires_at).format('YYYY-MM-DD')}`,
  },
  {
    title: '상태',
    dataIndex: 'status',
    key: 'status',
    render: (data) => {
      const [status, text] = getCreditStatus(data);
      return <Badge status={status} text={text} />;
    },
  },

  {
    title: '쿠폰 ID',
    dataIndex: 'coupon_id',
    key: 'coupon_id',
  },
];

const Credit = () => {
  const { data, loading } = useContext(OverviewContext);

  if (loading) {
    return <>Loading ...</>;
  }

  const { balance, data: credits } = data.credit;

  return (
    <Space direction={'vertical'} style={{ width: '100%' }} size={20}>
      <Summary balance={balance} />
      <CreditHistory credits={credits} />
    </Space>
  );
};

const Summary = ({ balance }) => {
  return (
    <Card>
      <Row justify={'space-between'}>
        <Col lg={6} md={6} sm={6} xs={24}>
          <Statistic title="크레딧" value={`₩${decimalFormatter(balance)}`} />
        </Col>
        <Col
          lg={6}
          md={6}
          sm={6}
          xs={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CreditModal />
        </Col>
      </Row>
    </Card>
  );
};

const CreditHistory = ({ credits }) => {
  return (
    <div>
      <Title level={5}>크레딧 관리</Title>
      <Card>
        <Table
          columns={columns}
          dataSource={credits}
          showSorterTooltip={false}
        />
      </Card>
    </div>
  );
};

export default Credit;
