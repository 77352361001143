import React from 'react';
import PricingPlan from '../PricingPlan';
import { Row, Grid } from 'antd';
const { useBreakpoint } = Grid;

const Pricing = () => {
  const { xxl } = useBreakpoint();
  return (
    <Row justify={'center'}>
      <div
        style={{ maxWidth: xxl ? 1100 : 800, margin: 'auto', width: '100%' }}
      >
        <PricingPlan screen />
      </div>
    </Row>
  );
};

export default Pricing;
