import React from 'react';
import {
  InvoiceProvider,
  AccountProvider,
  OverviewProvider,
} from '../contexts/BillingContext';
import { Invoice } from '../screens/Settings/tabs';

const InvoiceContainer = () => {
  return (
    <AccountProvider>
      <InvoiceProvider>
        <OverviewProvider>
          <Invoice />
        </OverviewProvider>
      </InvoiceProvider>
    </AccountProvider>
  );
};

export default InvoiceContainer;
