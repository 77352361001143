import React, { useEffect, useState } from 'react';
import { Space, Tabs, Input, Divider, Typography } from 'antd';
import { getUnsplashImages, pickUnsplashImage } from '../hooks/useAPI';
import { useFuro } from 'furo-react';
import unsplashDummy from '../data/dummy';
import { getUpdatedUrl } from '../utils/util';
import Loading from './Loading';

const { Search } = Input;
const { Text, Link } = Typography;

const ImagePicker = (props) => {
  const items = [
    { label: 'Unsplash', key: 'item-1', children: <ImageList {...props} /> },
    // { label: '업로드', key: 'item-2', children: <UploadCover /> },
  ];
  return (
    <div>
      <Tabs items={items} />
      {/* <Actions /> */}
      {/* <ImageList {...props} /> */}
    </div>
  );
};

const Actions = () => {
  return (
    <Row style={styles.btnWrapper} gutter={10}>
      <Col span={8}>
        <Button style={{ width: '100%' }} type="dashed">
          이미지 제거
        </Button>
      </Col>
      <Col span={8}>
        <Button style={{ width: '100%' }}>취소</Button>
      </Col>
      <Col span={8}>
        <Button style={{ width: '100%' }} type="primary">
          저장
        </Button>
      </Col>
    </Row>
  );
};

const ImageList = (props) => {
  const { getAccessTokenSilently } = useFuro();
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);

  const search = async (query = '') => {
    try {
      const [data, error] = await getUnsplashImages(30, query, {
        getToken: getAccessTokenSilently,
      });
      setImages(data);
      if (error) {
        console.error(error);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    search();
  }, []);

  if (loading) {
    return <Loading style={{ height: 300, width: 400 }} />;
  }

  return (
    <Space direction="vertical" size={10} style={{ width: '100%' }}>
      <Search
        placeholder="이미지를 검색하세요"
        allowClear
        onSearch={(value, e) => {
          e.stopPropagation();
          search(value);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <Space
        wrap
        size={10}
        style={{
          width: 400,
          height: 300,
          overflowY: 'scroll',
        }}
      >
        {images
          ? images.map((image, idx) => (
              <Pic
                imgSrc={image.urls}
                author={image.user.name}
                key={idx}
                links={image.links}
                {...props}
              />
            ))
          : unsplashDummy.map((image, idx) => (
              <Pic
                imgSrc={image.urls}
                author={image.user.name}
                key={idx}
                links={image.links}
                {...props}
              />
            ))}
      </Space>
    </Space>
  );
};

const Pic = (props) => {
  const { getAccessTokenSilently } = useFuro();
  const pick = async (location) => {
    try {
      const [data, error] = await pickUnsplashImage(location, {
        getToken: getAccessTokenSilently,
      });
      // console.log(data);
      if (error) {
        console.error(error);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onImgClick = () => {
    props.setImage(props.imgSrc.full);
    // console.log('image pick', props.imgSrc.full);
    pick(props.links.download_location);
  };

  return (
    <div
      style={{
        width: 120,
        cursor: 'pointer',
      }}
    >
      <div
        style={styles.picWrapper}
        onClick={(e) => {
          onImgClick();
          e.stopPropagation();
        }}
      >
        <img src={props.imgSrc.thumb} style={styles.img} />
      </div>
      <Text style={{ fontSize: 12 }}>
        Author:{' '}
        <Link
          href={props.links.html}
          style={{ color: '#000', textDecoration: 'underline' }}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {props.author.length < 6
            ? props.author
            : props.author.substr(0, 6) + '...'}
        </Link>
      </Text>
    </div>
  );
};

export default ImagePicker;

const styles = {
  btnWrapper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: 10,
    marginLeft: -10,
  },
  picWrapper: {
    width: 120,
    borderRadius: 5,
    height: 70,
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  wrapper: {
    width: 430,
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 2,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  },
};
