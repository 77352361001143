import { createContext, useState } from 'react';
import { Modal, Form } from 'antd';
import {
  EmailPassword,
  GoogleLogin,
  KakaoLogin,
  CommonLoginForm,
  MetamaskLogin,
  MagicLink,
} from '../SettingForms';
import { PRIMARY_COLOR } from '../../data';

export const VisibleContext = createContext();

export default function SettingModal(props) {
  const { platform, method, onSave, onChange, onSecretChange } = props;
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();

  const _onOk = async () => {
    setConfirmLoading(true);
    try {
      const values = await form.validateFields();
      onSave({ data: values });
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmLoading(false);
      setVisible(false);
    }
  };

  return (
    <>
      <div
        style={{
          blockSize: 'fit-content',
          color: PRIMARY_COLOR,
        }}
        onClick={() => {
          if (method?.enabled) setVisible(true);
        }}
      >
        설정
      </div>
      <VisibleContext.Provider value={{ visible }}>
        <Modal
          title={platform.title + ' 설정'}
          centered
          open={visible}
          onOk={_onOk}
          confirmLoading={confirmLoading}
          onCancel={() => {
            form.resetFields();
            setVisible(false);
          }}
          okText="저장"
          cancelText="취소"
          width={platform.name === 'magic_link' ? 600 : 520}
        >
          <FormLayout
            name={platform.name}
            method={method}
            onSave={onSave}
            onChange={onChange}
            onSecretChange={onSecretChange}
            form={form}
          />
        </Modal>
      </VisibleContext.Provider>
    </>
  );
}

const FormLayout = ({
  name,
  method,
  onSave,
  onChange,
  onSecretChange,
  form,
}) => {
  switch (name) {
    case 'emailpw':
      return <EmailPassword />;
    case 'google':
      return (
        // <GoogleLogin method={method} onSave={onSave} onChange={onChange} />
        <CommonLoginForm
          platformName={name}
          method={method}
          onSave={onSave}
          onChange={onChange}
          onSecretChange={onSecretChange}
        />
      );
    case 'kakao':
      // return <KakaoLogin method={method} onSave={onSave} onChange={onChange} />;
      return (
        <CommonLoginForm
          platformName={name}
          method={method}
          onSave={onSave}
          onChange={onChange}
          onSecretChange={onSecretChange}
        />
      );
    case 'naver':
      return (
        <CommonLoginForm
          platformName={name}
          method={method}
          onSave={onSave}
          onChange={onChange}
          onSecretChange={onSecretChange}
        />
      );
    case 'metamask':
      return (
        <MetamaskLogin method={method} onSave={onSave} onChange={onChange} />
      );
    case 'magic_link':
      return (
        <MagicLink
          method={method}
          onSave={onSave}
          onChange={onChange}
          form={form}
        />
      );
    default:
      return <>잘못된 접근입니다.</>;
  }
};
