import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select, Typography } from 'antd';
import { useFuro } from 'furo-react';
import { searchTeams, updateProject } from '../../../hooks/useAPI';
import { CopyButton, APIKeys } from '../../../components';

const { Title } = Typography;

const Develop = (props) => {
  const { project } = props;
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { user, getAccessTokenSilently } = useFuro();
  const [teams, setTeams] = useState();

  useEffect(async () => {
    const teamList = await searchTeams(user.uid, {
      getToken: getAccessTokenSilently,
    });
    setTeams(teamList);
  }, []);

  const requestUpdateProject = async (pid) => {
    setConfirmLoading(true);
    try {
      // get values from form
      const values = await form.validateFields();
      // call update project api
      const [response, error] = await updateProject(pid, values, {
        getToken: getAccessTokenSilently,
      });
      // props.setProject(response.data);
      // handle error
      if (error) {
        console.error(error);
        setConfirmLoading(false);
        const { message } = error.response.data;
        notification.error({
          message: '프로젝트 설정변경에 실패하였습니다.',
          description: JSON.stringify(message),
        });
      }
      // handle success
      else if (response.data) {
        setConfirmLoading(false);
        notification.success({
          message: '프로젝트가 설정변경이 완료 되었습니다.',
          description: '변경된 프로젝트를 확인해 보세요.',
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: '프로젝트 설정변경에 실패하였습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    } finally {
      setConfirmLoading(false);
    }
  };
  return (
    <>
      <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
        <Form.Item
          label="Domain"
          tooltip="회원 인증을 진행하는 인증 서버의 도메인입니다."
        >
          <Input
            placeholder="input placeholder"
            disabled
            value={'https://auth.furo.one'}
            style={{ color: 'black' }}
          />
        </Form.Item>
        <Form.Item
          label="Client ID"
          tooltip="애플리케이션에 부여된 고유한 식별자입니다"
        >
          <Input
            placeholder="input placeholder"
            value={project.pid}
            disabled
            style={{ color: 'black' }}
          />
          <CopyButton
            _onClick={() => {
              navigator.clipboard.writeText(project.pid);
            }}
          />
        </Form.Item>
        <Form.Item
          label="JWT Secret"
          tooltip="애플리케이션의 회원 토큰의 유효성을 검증하는 비밀키입니다."
        >
          <Input.Password
            placeholder="input placeholder"
            value={project.client_secret}
          />
        </Form.Item>

        <Form.Item
          label="Default Callback URI"
          name="callback_url"
          initialValue={project.callback_url}
          required
          tooltip="로그인 후 사용자를 애플리케이션으로 이동시킬 때 사용하는 URI입니다."
        >
          <Input placeholder="input placeholder" />
        </Form.Item>

        <Form.Item
          label="Allowed Origins"
          name="origin_allow_list"
          initialValue={project.origin_allow_list}
          tooltip="인증 서버에 회원 인증 요청할 수 있도록 허가한 주소 목록입니다."
        >
          <Select
            mode="tags"
            placeholder="ex) http://localhost:3000"
            tokenSeparators={[' ', ',']}
            style={{
              width: '100%',
            }}
            open={false}
          />
        </Form.Item>

        <Form.Item
          label="Public API Key"
          tooltip="프론트엔드 SDK 및 OAuth 에서 사용하는 API 키입니다"
        >
          <APIKeys project={project} teams={teams} type={'public'} />
        </Form.Item>

        <Form.Item
          label="Admin API Key"
          tooltip="백엔드에서 사용하는 API 키입니다"
        >
          <APIKeys project={project} teams={teams} type={'admin'} />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 14, offset: 5 }}>
          <Button
            type="primary"
            onClick={() => requestUpdateProject(project.pid)}
          >
            {/* Save Changes */}
            변경사항 저장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Develop;
