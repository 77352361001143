import { createElement } from 'react';
import { Space, Card, Avatar, Col, Row, Typography } from 'antd';
import { presets } from '../../data';

const { Title, Text } = Typography;

const PresetSetting = (props) => {
  const { except = [] } = props;
  const len = presets.length - except.length;

  return (
    <Row gutter={[20, 20]}>
      {presets.map((preset) => {
        return (
          !except.includes(preset.name) && (
            <Col key={preset.name} span={24 / len}>
              <PresetCard
                name={preset.name}
                label={preset.label}
                description={preset.description}
                img={preset.imgSrc && <img src={preset.imgSrc} />}
                icon={
                  preset.icon &&
                  createElement(preset.icon, { style: { color: 'black' } })
                }
                {...props}
              />
            </Col>
          )
        );
      })}
    </Row>
  );
};

const PresetCard = (props) => {
  const { name, label, description, img, icon } = props;

  return (
    <Card
      hoverable
      style={
        name === props.preset
          ? { ...styles.selectedCard, height: '100%' }
          : { height: '100%' }
      }
      bodyStyle={{ height: '100%' }}
      onClick={() => props.setPreset(name)}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify={'center'}>
          <Avatar
            size={40}
            src={img}
            style={{ ...styles.avatar, padding: icon ? 0 : 8 }}
            icon={icon}
          />
        </Row>
        <Row justify={'center'}>
          <Title level={5}>{label}</Title>
        </Row>
        <Row justify={'center'}>
          <Text type="secondary" style={styles.description}>
            {description}
          </Text>
        </Row>
      </Space>
    </Card>
  );
};

export default PresetSetting;

const styles = {
  avatar: { border: '1px solid #D9D9D9', backgroundColor: '#fff' },
  description: { whiteSpace: 'pre-line', textAlign: 'center' },
  selectedCard: {
    height: '100%',
    border: '1px solid #286FF8',
    boxShadow: '0px 0px 0px 2px rgba(40, 111, 248, 0.25)',
  },
};
