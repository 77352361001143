import React, { useContext } from 'react';
import { Row, Statistic, Col, Divider, Card, Tag, Grid } from 'antd';
import UpgradePlanModal from '../UpgradePlanModal';
import { decimalFormatter } from '../../../utils/util';
import { OverviewContext } from '../../../contexts/BillingContext';
import moment from 'moment';
import { getCredits } from '../../../hooks/useAPI';
import { useFuro } from 'furo-react';
import PaymentMethod from '../PaymentMethod';
import Usage from '../Usage';
import { Loading } from '../../../components';

const { useBreakpoint } = Grid;

const General = () => {
  const { data, loading, error } = useContext(OverviewContext);
  const { getAccessTokenSilently } = useFuro();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <>Something went wrong...</>;
  }

  const { account, cards, subscription, subscription_plan, usage } = data;

  const {
    data: credits,
    isLoading,
    isError,
  } = getCredits(account.account_id, {
    getToken: getAccessTokenSilently,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <>Something went wrong...</>;
  }

  const { price } = subscription_plan;

  return (
    <>
      <Summary
        currentPlan={subscription.subscription_plan_name}
        expiresAt={subscription.ended_at}
        credit={credits.balance}
        // amount={expectedAmount}
        cost={price}
        autoRenew={subscription.auto_renew}
      />
      <Divider />
      {usage && <Usage usages={usage} subscription_plan={subscription_plan} />}
      <PaymentMethod method={cards} />
      {/* <Invoice /> */}
      {/* <div style={{ justifyContent: 'end', display: 'flex' }}>
        <a style={{ color: '#bbb' }}>구독 취소</a>
      </div> */}
    </>
  );
};

const DividerComponent = () => {
  const { xs } = useBreakpoint();
  return (
    <Divider
      type={xs ? 'horizontal' : 'vertical'}
      style={{ height: xs ? 0 : 80 }}
    />
  );
};

const Summary = ({
  currentPlan,
  expiresAt,
  credit,
  amount,
  cost,
  autoRenew,
}) => {
  return (
    <Card>
      <Row>
        <Col lg={6} md={6} sm={6} xs={24}>
          <Statistic
            title="현재 플랜"
            value={currentPlan}
            // style={{ paddingBottom: 22 }}
          />
        </Col>
        <Col lg={6} md={6} sm={6} xs={24}>
          <Statistic
            title="크레딧"
            value={`₩${decimalFormatter(credit)}`}
            style={{ paddingBottom: 22 }}
          />
        </Col>
        <Col lg={6} md={6} sm={6} xs={24}>
          <Statistic
            title="결제 예상 금액"
            value={`₩${decimalFormatter(Math.max(0, cost - credit))}`}
            style={{ paddingBottom: expiresAt ? 0 : 22 }}
          />
          {autoRenew && <ExpiresDate expiresAt={expiresAt} />}
        </Col>
        <Col
          lg={6}
          md={6}
          sm={6}
          xs={24}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <UpgradePlanModal currentPlan={currentPlan} />
        </Col>
      </Row>
    </Card>
  );
};

const ExpiresDate = ({ expiresAt }) => {
  const { xs } = useBreakpoint();
  return expiresAt ? (
    <div
      style={{
        width: 170,
        paddingBottom: xs ? 22 : 0,
      }}
    >
      <Tag color="default">결제 예정일</Tag>
      {moment(expiresAt).format('YYYY-MM-DD')}
    </div>
  ) : (
    <></>
  );
};

export default General;
