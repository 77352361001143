import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSigninMethods } from '../../../hooks/useAPI';
import { useFuro } from 'furo-react';
import { platforms } from '../../../data';
import LoginMethodCard from '../LoginMethodCard';
import { Col, Row, Typography } from 'antd';

const { Text } = Typography;

const LoginMethod = (props) => {
  const { projectId } = useParams();
  const { getAccessTokenSilently } = useFuro();

  // const { sign_in_methods: methods } = props.project;

  const { methods, isLoading, isError } = getSigninMethods(projectId, {
    getToken: getAccessTokenSilently,
  });

  if (isError) return <>Error occured.</>;
  if (isLoading) return null;
  // if (!social) return <SocialPreview projectId={projectId} />;
  return (
    <>
      <LoginMethodCardGroup value={platforms.basic} methods={methods} />
      <LoginMethodCardGroup value={platforms.social} methods={methods} />
      <LoginMethodCardGroup value={platforms.web3} methods={methods} />
    </>
  );
};

const LoginMethodCardGroup = ({ value, methods }) => {
  return (
    <div>
      <Text type="secondary">{value.title}</Text>
      <Row gutter={16} style={{ padding: '0px 2px 10px 2px' }}>
        {value.data.map((platform, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={8}>
            <LoginMethodCard platform={platform} methods={methods} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default LoginMethod;
