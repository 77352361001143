import React from 'react';
import { Space, Typography, Row, Collapse } from 'antd';
import { useFuro } from 'furo-react';
import mixpanel from '../../libs/mixpanel';

const { Panel } = Collapse;
const { Title } = Typography;

const FAQ = () => {
  const { user } = useFuro();

  return (
    <Space
      direction={'vertical'}
      style={{ width: '100%', padding: 20, marginTop: 25 }}
    >
      <Row>
        <Title level={5}>FAQ</Title>
      </Row>

      <div style={{ margin: '0px -15px' }}>
        <Collapse
        // ghost
        // defaultActiveKey={['1']}
        // expandIcon={() => <QuestionCircleOutlined />}
        >
          <Panel
            header="환불 진행은 어떻게 이루어지나요?"
            key="1"
            onClick={() => {
              mixpanel.track('FAQ Clicked', {
                UID: user.uid,
                distinct_id: user.uid,
                question: '환불 진행은 어떻게 이루어지나요?',
              });
            }}
          >
            <p>
              오른쪽 하단의 채널톡이나 고객지원팀(support@furo.one)을 통해
              문의주시면 담당자가 빠르게 진행을 도와드리겠습니다.
            </p>
          </Panel>
          <Panel
            header="사용량에 따라 청구가 된다는 게 어떤 의미인가요?"
            key="2"
            onClick={() => {
              mixpanel.track('FAQ Clicked', {
                UID: user.uid,
                distinct_id: user.uid,
                question: '사용량에 따라 청구가 된다는 게 어떤 의미인가요?',
              });
            }}
          >
            <p>
              Furo를 구독하시면 플랜별 기본 요금이 결제 주기마다 청구됩니다.{' '}
              <br /> 사용량 기반 청구란 기본 요금과 별개로 월별 초과 사용량을
              기반으로 월별로 청구가 이루어지는 방식을 의미합니다.
            </p>
          </Panel>
          <Panel
            header="사용 중이던 플랜을 업그레이드(혹은 다운그레이드) 하려면 어떻게 해야 하나요?"
            key="3"
            onClick={() => {
              mixpanel.track('FAQ Clicked', {
                UID: user.uid,
                distinct_id: user.uid,
                question:
                  '사용 중이던 플랜을 업그레이드(혹은 다운그레이드) 하려면 어떻게 해야 하나요?',
              });
            }}
          >
            <p>
              Developer, Business &gt; Enterprise : 견적을 문의해 주시면 빠르게
              맞춤형 지원을 도와드리겠습니다.
              <br />
              Business, Enterprise &gt; Developer : 채널톡이나
              고객지원팀(support@furo.one) 문의주시면 빠르게 도와드리겠습니다.
            </p>
          </Panel>
        </Collapse>
      </div>
    </Space>
  );
};

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  answer: {
    // paddingLeft: 24,
    textAlign: 'left',
  },
};

export default FAQ;
