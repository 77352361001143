import React from 'react';
import {
  Form,
  Space,
  Row,
  Col,
  Radio,
  Select,
  Divider,
  Typography,
  Input,
} from 'antd';
import { ImagePickerGroup } from '../components';
import UploadLogo from '../screens/UploadLogo';
import { PRIMARY_COLOR } from '../data';

export default function DesignView({ children, ...props }) {
  let subComponentList = Object.keys(AppView);
  let subComponents = subComponentList.map((key) => {
    return React.Children.map(children, (child) => {
      return child;
    });
  });

  return <>{subComponents.map((component) => component)}</>;
}

DesignView.FormLayout = (props) => {
  // props는 항상 받으면 펼쳐놓을것
  const { update, form } = props;
  const { Text } = Typography;

  const initialValues = {
    primary_color: PRIMARY_COLOR,
    current_type: 'simple',
    side_alignment: 'left',
    theme: 'light',
    ...props.project.style,
    logo: props.project.logo,
  };

  const isSide = () => {
    if (
      form.getFieldsValue(true) &&
      form.getFieldValue('current_type') === 'side'
    ) {
      return true;
    } else if (
      form.getFieldsValue(true) &&
      form.getFieldValue('current_type') === 'simple'
    ) {
      return false;
    } else if (initialValues.current_type === 'side') {
      return true;
    }
    return false;
  };

  return (
    <Form
      layout="vertical"
      form={props.form}
      onFieldsChange={() => {
        const sortedValues = Object.fromEntries(
          Object.entries(form.getFieldsValue(true)).sort(
            ([k1, v1], [k2, v2]) => k1 - k2,
          ),
        );
        update(sortedValues);
        // update(form.getFieldsValue(true));
      }}
      initialValues={initialValues}
    >
      <Form.Item label={<Text strong>로고</Text>} name="logo">
        <UploadLogo
          project={props.project}
          setImage={(url) => {
            form.setFieldValue('logo', url);
            update(form.getFieldsValue(true));
          }}
        />
      </Form.Item>
      <Divider />
      <Form.Item label={<Text strong>메인 색상</Text>} name="primary_color">
        <Input
          prefix={
            <Form.Item name="primary_color" noStyle>
              <input
                type="color"
                style={{
                  width: 23,
                  padding: 0,
                  border: 'none',
                  background: 'none',
                }}
              />
            </Form.Item>
          }
          maxLength={7}
        />
      </Form.Item>
      <Divider />
      <Form.Item label={<Text strong>타입</Text>} name="current_type">
        <Radio.Group style={{ width: '100%' }}>
          <Row gutter={20}>
            <Col span={12}>
              <Skeleton imgSrc={'/images/simple.svg'} />
              <Radio value={'simple'}>기본</Radio>
            </Col>
            <Col span={12}>
              <Skeleton imgSrc={'/images/side.svg'} />
              <Radio value={'side'}>사이드</Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>
      {isSide() && (
        <>
          <Row justify={'space-between'} align={'middle'}>
            <Text
              strong
              style={{
                lineHeight: 2.5,
              }}
            >
              방향
            </Text>
            <Space>
              <img
                src={`/images/${
                  props.form.getFieldValue('side_alignment') ?? 'left'
                }.png`}
              />
              <Form.Item name="side_alignment" noStyle>
                <Select
                  style={{
                    width: 90,
                  }}
                  options={[
                    {
                      value: 'left',
                      label: 'Left',
                    },
                    {
                      value: 'right',
                      label: 'Right',
                    },
                  ]}
                />
              </Form.Item>
            </Space>
          </Row>

          <div style={{ marginBottom: 16 }} />
          <Form.Item
            label={
              <Space>
                <Text strong>커버 이미지</Text>
                {props.imageLoading ? <InlineLoading /> : null}
              </Space>
            }
            name="side_image"
          >
            <ImagePickerGroup
              project={props.project}
              setImage={(url) => {
                form.setFieldValue('side_image', url);
                update(form.getFieldsValue(true));
              }}
            />
          </Form.Item>
        </>
      )}

      <Divider />
      <Form.Item label={<Text strong>테마</Text>} name="theme">
        <Radio.Group style={{ width: '100%' }}>
          <Row gutter={10}>
            {themes.map((theme) => (
              <Col key={theme.name} span={12} style={{ marginBottom: 10 }}>
                <Skeleton imgSrc={theme.image} />
                <Radio value={theme.value}>{theme.name}</Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

const themes = [
  {
    value: 'light',
    name: 'Light',
    image: '/images/light.png',
  },
  {
    value: 'dark',
    name: 'Dark',
    image: '/images/dark.png',
  },
  {
    value: 'vivid',
    name: 'Vivid',
    image: '/images/vivid.png',
  },
  {
    value: 'clear',
    name: 'Clear',
    image: '/images/clear.png',
  },
];

const Skeleton = ({ imgSrc }) => {
  return (
    <img
      src={imgSrc}
      style={{
        marginBottom: 10,
      }}
    />
  );
};
