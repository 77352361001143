import React, { useState, useContext } from 'react';
import {
  Typography,
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  notification,
  Space,
} from 'antd';
import { updateAccount } from '../../../hooks/useAPI';
import { AccountContext } from '../../../contexts/BillingContext';

const { Title, Text } = Typography;

const Setting = () => {
  const [form] = Form.useForm();
  const {
    data: account,
    setData: setAccount,
    loading,
    getAccessTokenSilently,
  } = useContext(AccountContext);

  if (loading) {
    return <>Loading...</>;
  }

  const onSubmit = async () => {
    const values = await form.validateFields();

    try {
      const [updatedAccount, error] = await updateAccount(
        account.account_id,
        values,
        {
          getToken: getAccessTokenSilently,
        },
      );
      if (error) {
        notification.error({
          message: '결제정보를 설정하는 데 실패했습니다.',
          description: '이 현상이 지속될 시 관리자에게 문의하세요',
        });
      } else if (updatedAccount) {
        setAccount(updatedAccount);
        // setOverview({ ...overview, account: updatedAccount });
        notification.success({
          message: '결제정보가 성공적으로 설정되었습니다.',
        });
      }
    } catch (e) {
      console.error(e);
      notification.error({
        message: '결제정보를 설정하는 데 실패했습니다.',
        description: '이 현상이 지속될 시 관리자에게 문의하세요',
      });
    }
  };
  return (
    <div>
      <Title level={5}>결제 정보</Title>
      <Form form={form} onFinish={onSubmit}>
        <Card>
          <FormItem
            label={'상호(법인명)'}
            name="name"
            placeHolder={'상호명(법인명)을 입력하세요'}
            initialValue={account.name}
          />
          <FormItem
            label={'사업자등록번호'}
            name="business_registration_number"
            placeHolder={'XXX-XX-XXXXX'}
            initialValue={account.business_registration_number}
          />
          <Card.Grid
            hoverable={false}
            style={{
              width: '100%',
            }}
          >
            <Row>
              <Col lg={12} md={12} sm={24} xs={24} style={styles.labelWrapper}>
                <Text level={5}>사업장 주소</Text>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Space
                  direction="vertical"
                  size={'small'}
                  style={{ width: '100%' }}
                >
                  <Form.Item
                    required
                    name={'address'}
                    noStyle
                    initialValue={account.address ? account.address : null}
                  >
                    <Input placeholder="기본 주소를 입력하세요" />
                  </Form.Item>
                  <Form.Item
                    required
                    name={'address_detail'}
                    noStyle
                    initialValue={
                      account.address_detail ? account.address_detail : null
                    }
                  >
                    <Input placeholder="나머지 주소를 입력하세요" />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Card.Grid>
        </Card>
        <div style={styles.btnWrapper}>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              저장
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

const FormItem = ({ label, name, placeHolder, initialValue }) => {
  return (
    <Card.Grid
      hoverable={false}
      style={{
        width: '100%',
      }}
    >
      <Row>
        <Col lg={12} md={12} sm={24} xs={24} style={styles.labelWrapper}>
          <Text level={5}>{label}</Text>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Form.Item required name={name} noStyle initialValue={initialValue}>
            <Input placeholder={placeHolder} />
          </Form.Item>
        </Col>
      </Row>
    </Card.Grid>
  );
};

const styles = {
  labelWrapper: { display: 'flex', alignItems: 'center' },
  btnWrapper: { padding: '10px 0', display: 'flex', justifyContent: 'end' },
};

export default Setting;
