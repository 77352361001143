import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
// import '@shopify/polaris-viz/build/esm/styles.css';

import { FuroProvider, useFuro } from 'furo-react';

import Layout from './screens/Layout';
import Home from './screens/Home';
import { NotFound, SurveySuccess } from './screens/Util';
import Settings from './screens/Settings/Settings';
import { Pricing } from './screens/Settings/tabs';
import MainLayout from './screens/MainLayout';

import { ProjectList, Project } from './screens/Project/index';
import { PolarisVizProvider } from '@shopify/polaris-viz';
import { TeamsProvider } from './contexts/TeamsContext';
import { ProjectProvider } from './contexts/ProjectContext';
import { ConfigProvider } from 'antd';

/**
 * @summary 로그인 여부를 확인하고, 안되어 있는 경우 로그인 페이지로 리다이렉트
 */
function RequireAuth({ children }) {
  const { isLoading, isAuthenticated, loginWithRedirect } = useFuro();

  useEffect(() => {
    if (isLoading || isAuthenticated) return <>Loading...</>;
    if (!isLoading) {
      setTimeout(() => {
        if (!isAuthenticated) loginWithRedirect();
      }, 1000);
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return isAuthenticated ? children : <></>;
}

function RouteApp({ auth, component }) {
  if (auth === false) {
    return <Layout>{component}</Layout>;
  } else {
    return (
      <RequireAuth>
        <Layout>{component}</Layout>
      </RequireAuth>
    );
  }
}

export default function App() {
  return (
    <FuroProvider
      domain={process.env.REACT_APP_FURO_DOMAIN || 'https://dev.auth.furo.one'}
      clientId={
        process.env.REACT_APP_FURO_CLIENT_ID ||
        '430d15be2a5da0f2dcd2b41d5af6687f'
      }
      redirectUri={window.location.origin + '/projects'}
      apiUrl={process.env.REACT_APP_API_BASE_URL || 'https://dev.auth.furo.one'}
    >
      <ConfigProvider theme={{}}>
        <TeamsProvider>
          <PolarisVizProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<MainLayout />}>
                  <Route index element={<Home />} />
                  <Route path="pricing" element={<Pricing />} />
                </Route>
                <Route
                  path="projects"
                  element={<RouteApp component={<ProjectList />} />}
                />

                <Route
                  path="projects/:projectId"
                  element={<RouteApp component={<Project />} />}
                />
                <Route
                  path="projects/:projectId/:tab"
                  element={<RouteApp component={<Project />} />}
                />
                {/* UserList tab */}
                <Route
                  path="projects/:projectId/3/:userTab"
                  element={<RouteApp component={<Project />} />}
                />
                <Route
                  path="survey/success"
                  element={<RouteApp component={<SurveySuccess />} />}
                />
                <Route
                  path="settings"
                  element={<RouteApp component={<Settings />} />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </PolarisVizProvider>
        </TeamsProvider>
      </ConfigProvider>
    </FuroProvider>
  );
}

// const App = ({ element: Component, ...rest }) => {
//   const { width } = useWindowDimensions();

//   if (width < 720) {
//     return (
//       <div
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'center',
//           alignItems: 'center',
//           flex: 1,
//           height: '90vh',
//         }}
//       >
//         {/* <img
//           style={{ width: 100, position: 'absolute', top: 20, left: 20 }}
//           src={require('./images/logo.png')}
//           alt="logo"
//         />
//         <img
//           style={{ width: 280 }}
//           src={require('./images/sorry-mobile.png')}
//           alt="sorry"
//         /> */}
//         <h2 style={{ marginTop: 30 }}>
//           모바일은 준비중{' '}
//           <span role="img" aria-label="smily face">
//             &#x26CF;
//           </span>
//         </h2>
//         <p>
//           태블릿이나 PC화면에서 다시 방문해주세요.{' '}
//           <span role="img" aria-label="smily face">
//             &#x1F609;
//           </span>
//         </p>
//       </div>
//     );
//   }
//   return (
//     <Route
//       {...rest}
//       element={(routeProps) => (
//         <Layout>
//           <Component {...routeProps} />
//         </Layout>
//       )}
//     />
//   );
// };

// function getWindowDimensions() {
//   const { innerWidth: width, innerHeight: height } = window;
//   return {
//     width,
//     height,
//   };
// }

// function useWindowDimensions() {
//   const [windowDimensions, setWindowDimensions] = React.useState(
//     getWindowDimensions(),
//   );

//   React.useEffect(() => {
//     function handleResize() {
//       setWindowDimensions(getWindowDimensions());
//     }

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return windowDimensions;
// }

// export default App;
