import React, { useState } from 'react';
import { Modal, Button, Form, Input, Typography, notification } from 'antd';
import { setPassword } from '../../hooks/useAPI';
import { useFuro } from 'furo-react';

const { Text } = Typography;

const renderMessage = (type) => {
  switch (type) {
    case 'create': {
      return {
        success: {
          message: '비밀번호가 성공적으로 설정되었습니다.',
          description: '이제 비밀번호로 로그인이 가능합니다.',
        },
        fail: {
          message: '비밀번호 설정에 실패하였습니다.',
          description: '이 현상이 지속될 시 관리자에게 문의하세요.',
        },
      };
    }
    case 'delete': {
      return {
        success: {
          message: '비밀번호가 성공적으로 삭제되었습니다.',
          description: '이제 매직링크로 로그인이 가능합니다.',
        },
        fail: {
          message: '비밀번호 삭제에 실패하였습니다.',
          description: '올바른 정보를 입력하세요.',
        },
      };
    }
    case 'update': {
      return {
        success: {
          message: '비밀번호가 성공적으로 설정되었습니다.',
          description: '이제 새로운 비밀번호로 로그인이 가능합니다.',
        },
        fail: {
          message: '비밀번호 설정에 실패하였습니다.',
          description: '올바른 정보를 입력하세요.',
        },
      };
    }
  }
};

const PasswordModal = ({ type = 'create', setHasPassword }) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [okBtnDisabled, setokBtnDisabled] = useState(true);
  const { user, getAccessTokenSilently } = useFuro();

  const _onOk = async () => {
    setConfirmLoading(true);
    try {
      const values = await form.validateFields();

      if (values.password_confirm) delete values.password_confirm;

      const [response, err] = await setPassword(
        user.uid,
        {
          ...values,
          clearPassword: type === 'delete',
        },
        {
          getToken: getAccessTokenSilently,
        },
      );

      const { success, fail } = renderMessage(type);

      if (err) {
        notification.error({
          message: fail.message,
          description: fail.description,
        });
      } else if (response) {
        setHasPassword(type !== 'delete');
        notification.success({
          message: success.message,
          description: success.description,
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: fail.message,
        description: fail.description,
      });
    } finally {
      setConfirmLoading(false);
      _onCancel();
    }
  };

  const _onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  switch (type) {
    case 'create':
      return (
        <>
          <Button onClick={() => setOpen(true)}>비밀번호 설정</Button>
          <Modal
            title="비밀번호 설정"
            centered
            open={open}
            onCancel={_onCancel}
            onOk={_onOk}
            cancelText="취소"
            okText="저장"
            confirmLoading={confirmLoading}
            okButtonProps={{ disabled: okBtnDisabled }}
          >
            <FormLayout
              form={form}
              type={type}
              setokBtnDisabled={setokBtnDisabled}
            />
          </Modal>
        </>
      );
    case 'delete':
      return (
        <>
          <Button danger onClick={() => setOpen(true)}>
            비밀번호 삭제
          </Button>

          <Modal
            title="비밀번호 삭제"
            centered
            open={open}
            onCancel={_onCancel}
            onOk={_onOk}
            cancelText="취소"
            okText="저장"
            confirmLoading={confirmLoading}
            okButtonProps={{ disabled: okBtnDisabled }}
          >
            <div style={styles.wrapper}>
              <Text>
                삭제한 후에는 이메일 매직링크를 통해 로그인할 수 있습니다.
              </Text>
            </div>
            <FormLayout
              form={form}
              type={type}
              setokBtnDisabled={setokBtnDisabled}
            />
          </Modal>
        </>
      );
    case 'update':
      return (
        <>
          <Button onClick={() => setOpen(true)}>비밀번호 변경</Button>
          <Modal
            title="비밀번호 변경"
            centered
            open={open}
            onCancel={_onCancel}
            onOk={_onOk}
            cancelText="취소"
            okText="저장"
            confirmLoading={confirmLoading}
            okButtonProps={{ disabled: okBtnDisabled }}
          >
            <FormLayout
              form={form}
              type={type}
              setokBtnDisabled={setokBtnDisabled}
            />
          </Modal>
        </>
      );
  }
};

const FormLayout = ({ form, type = 'create', setokBtnDisabled }) => {
  switch (type) {
    case 'create':
      return (
        <Form
          layout="vertical"
          form={form}
          onFieldsChange={() => {
            const isEmpty = Object.keys(form.getFieldsValue(true)).length < 2;
            const hasError = form
              .getFieldsError()
              .some((field) => field.errors.length > 0);
            setokBtnDisabled(isEmpty || hasError);
          }}
        >
          <Form.Item
            label="비밀번호"
            name="newPassword"
            hasFeedback
            rules={[{ required: true, message: '비밀번호를 입력하세요.' }]}
          >
            <Input.Password placeholder="비밀번호를 입력해주세요." />
          </Form.Item>
          <Form.Item
            label="비밀번호 확인"
            dependencies={['newPassword']}
            name="password_confirm"
            hasFeedback
            rules={[
              { required: true, message: '비밀번호를 한번 더 입력하세요.' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('비밀번호가 일치하지 않습니다.'),
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="비밀번호를 한번 더 입력해주세요." />
          </Form.Item>
        </Form>
      );
    case 'delete':
      return (
        <Form
          layout="vertical"
          form={form}
          onFieldsChange={() => {
            const isEmpty = Object.keys(form.getFieldsValue(true)).length < 1;
            const hasError = form
              .getFieldsError()
              .some((field) => field.errors.length > 0);
            setokBtnDisabled(isEmpty || hasError);
          }}
        >
          <Form.Item
            label="비밀번호 확인"
            rules={[{ required: true, message: '현재 비밀번호를 입력하세요.' }]}
            name="oldPassword"
            hasFeedback
          >
            <Input.Password placeholder="비밀번호를 한번 더 입력해주세요." />
          </Form.Item>
        </Form>
      );
    case 'update':
      return (
        <Form
          layout="vertical"
          form={form}
          onFieldsChange={() => {
            const isEmpty = Object.keys(form.getFieldsValue(true)).length < 3;
            const hasError = form
              .getFieldsError()
              .some((field) => field.errors.length > 0);
            setokBtnDisabled(isEmpty || hasError);
          }}
        >
          <Form.Item
            label="현재 비밀번호"
            rules={[{ required: true, message: '현재 비밀번호를 입력하세요.' }]}
            name="oldPassword"
            hasFeedback
          >
            <Input.Password placeholder="현재 비밀번호를 입력해주세요." />
          </Form.Item>
          <Form.Item
            label="새 비밀번호"
            rules={[
              { required: true, message: '변경할 비밀번호를 입력하세요.' },
            ]}
            name="newPassword"
            hasFeedback
          >
            <Input.Password placeholder="변경할 비밀번호를 입력해주세요." />
          </Form.Item>
          <Form.Item
            label="새 비밀번호 확인"
            required
            name="password_confirm"
            rules={[
              { required: true, message: '비밀번호를 한번 더 입력하세요.' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('비밀번호가 일치하지 않습니다.'),
                  );
                },
              }),
            ]}
            dependencies={['newPassword']}
            hasFeedback
          >
            <Input.Password placeholder="비밀번호를 한번 더 입력해주세요.." />
          </Form.Item>
        </Form>
      );
  }
};

export default PasswordModal;

const styles = {
  wrapper: { marginTop: 16, marginBottom: 16 },
};
