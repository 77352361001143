import { Space, Form, Switch } from 'antd';

const FormLayoutDemo = (props) => {
  const { initialValues } = props;

  const [form] = Form.useForm();

  const _handleToggle = async (enabled) => {
    if (!key) {
      setEnabled(false);
      return message.error(`Please set API Key to activate`);
    }
    await _setSocial(key, enabled);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      requiredMark={true}
    >
      <Space direction="vertical" size={20}>
        <Form.Item
          label="회원가입시 이메일 인증"
          valuePropName="checked"
          tooltip="잠깐! 회원가입 절차에서 이메일 인증을 활성화하는 경우 약 40%의 사용자가 회원가입을 포기한다는 사실을 아시나요? 이 옵션은 반드시 필요한 경우에만 활성화하는 것을 권장합니다."
          size={20}
        >
          <Switch onChange={() => {}} />
        </Form.Item>
      </Space>
    </Form>
  );
};

export default FormLayoutDemo;
