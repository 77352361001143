import React, { useState } from 'react';
import {
  Switch,
  Card,
  Avatar,
  Tag,
  Col,
  Row,
  Typography,
  message,
  Form,
  Input,
} from 'antd';
import {
  SaveOutlined,
  CheckCircleTwoTone,
  SmileTwoTone,
} from '@ant-design/icons';

import { Button } from '../../../components';
import { useParams } from 'react-router-dom';
import { useFuro } from 'furo-react';
import { settings } from '../../../data';
import TermModal from '../TermModal';
import {
  getIntegration,
  setIntegration,
  updateProject,
} from '../../../hooks/useAPI';
import { ContactModal } from '../../../components';

const { Title } = Typography;
const { Meta } = Card;

export default function Settings(props) {
  let { projectId } = useParams();
  const [switchLoading, setSwitchLoading] = useState(false);

  const { project } = props;
  // const { integration } = props.project;

  const { getAccessTokenSilently } = useFuro();
  const { integration, isLoading, isError } = getIntegration(projectId, {
    getToken: getAccessTokenSilently,
  });

  const [enabled, setEnabled] = useState(
    integration && integration?.slack?.enabled,
  );
  const [url, setUrl] = useState(integration?.slack?.url);
  const [currentUrl, setCurrentUrl] = useState(integration?.slack?.url);
  const [buttonLoading, setButtonLoading] = useState(false);

  if (isError) return <>Error occured.</>;
  if (isLoading) return null;

  const _setSlack = async (url, enabled) => {
    let data = Object.assign(integration);
    data['slack'] = {
      enabled,
      url,
    };
    const [response, error] = await updateProject(
      projectId,
      { integration: data },
      {
        getToken: getAccessTokenSilently,
      },
    );
    if (error) message.error('Something went wrong');
    else if (response.status === 200) {
      message.success('저장이 완료되었습니다.');
      setEnabled(enabled);
      setUrl(url);
    } else message.error('Something went wrong');
    return response;
  };

  const _initSlack = async () => {
    setButtonLoading(true);
    let data = integration
      ? Object.assign(integration)
      : {
          slack: {
            enabled: false,
            url: null,
          },
        };
    const [response, error] = await updateProject(
      projectId,
      { integration: data },
      {
        getToken: getAccessTokenSilently,
      },
    );
    // props.setProject(response.data);
    if (error) message.error('Something went wrong');
    else if (response.status === 200) {
      setButtonLoading(false);
      return response;
    } else {
      setButtonLoading(false);
      message.error('Something went wrong');
    }
    return response;
  };

  const _handleToggle = async (enabled) => {
    if (!url && enabled) {
      setEnabled(false);
      return message.error(`정확한 값을 넣어주세요.`);
    }
    await _setSlack(url, enabled);
  };

  const _handleChange = (event) => {
    setCurrentUrl(event.target.value);
  };

  const _handleSave = async () => {
    await _setSlack(currentUrl, url ? enabled : true);
  };

  return (
    <>
      <Row justify="space-between">
        <Title level={4}>{settings.title}</Title>
      </Row>
      <Row gutter={16} justify="left">
        {settings.data.map((setting, index) => {
          switch (setting.name) {
            case 'slack':
              return (
                <Col key={index} xs={24} sm={24} md={12} lg={8}>
                  <Card
                    style={{ marginTop: 16 }}
                    actions={
                      integration?.slack
                        ? [
                            <div
                              style={styles.right}
                              onClick={() =>
                                window.open(
                                  'https://docs.furo.one/guides/slack',
                                )
                              }
                            >
                              연동 가이드
                            </div>,
                          ]
                        : [
                            <Button
                              type="primary"
                              style={styles.activeBtn}
                              onClick={_initSlack}
                              loading={buttonLoading}
                            >
                              {!buttonLoading && `활성화`}
                            </Button>,
                          ]
                    }
                  >
                    <Meta
                      avatar={
                        <Avatar
                          size={40}
                          src={
                            'https://www.itprotoday.com/sites/itprotoday.com/files/appIcon_desktop.png'
                          }
                        />
                      }
                      title={
                        <Row>
                          <Col flex="auto">Slack 연동</Col>
                          <Col flex="auto">
                            <Tag color="red">NEW</Tag>
                          </Col>
                          <Col flex="50px">
                            {integration?.slack && (
                              <Switch
                                loading={switchLoading}
                                onChange={_handleToggle}
                                disabled={!integration?.slack}
                                style={{ float: 'right', marginTop: 4 }}
                                checked={enabled}
                                size="small"
                              />
                            )}
                          </Col>
                        </Row>
                      }
                      description={
                        !integration?.slack
                          ? '새로운 사용자가 가입할 때마다 Slack 채널에서 메세지를 받아보세요.'
                          : 'Slack Webhook'
                      }
                    />
                    {integration?.slack && (
                      <Form
                        layout="vertical"
                        style={{ marginTop: 16, marginBottom: -24 }}
                      >
                        <Form.Item
                          label={'API Key'}
                          name="api-key"
                          initialValue={url}
                        >
                          <Input
                            value={currentUrl}
                            onChange={_handleChange}
                            addonAfter={
                              <a>
                                {url && url === currentUrl ? (
                                  <CheckCircleTwoTone
                                    twoToneColor="#52c41a"
                                    style={{ cursor: 'default' }}
                                  />
                                ) : (
                                  <SaveOutlined onClick={_handleSave} />
                                )}
                              </a>
                            }
                          />
                        </Form.Item>
                      </Form>
                    )}
                  </Card>
                </Col>
              );
            case 'privacy':
              return (
                <Col key={index} xs={24} sm={24} md={12} lg={8}>
                  <SettingCard
                    setting={setting}
                    left={<TermModal project={project} projectId={projectId} />}
                    right={
                      <div
                        style={styles.right}
                        onClick={() =>
                          window.open('https://docs.furo.one/guides/privacy')
                        }
                      >
                        연동 가이드
                      </div>
                    }
                    isNew
                  />
                </Col>
              );
            default:
              return (
                <Col key={index} xs={24} sm={24} md={12} lg={8}>
                  <SettingCard setting={setting} />
                </Col>
              );
          }
        })}
      </Row>
    </>
  );
}

const SettingCard = ({ setting, left, right, isNew = false }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const _contact = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <Card
      style={{
        marginTop: 16,
        // opacity: setting.disabled && 0.4
      }}
      actions={!setting.disabled && [left, right]}
    >
      <Meta
        avatar={<Avatar size={40} src={setting.avatar} />}
        title={
          <Row>
            <Col flex="auto">{setting.title}</Col>
            {isNew && (
              <Col flex="auto">
                <Tag color="red">NEW</Tag>
              </Col>
            )}
          </Row>
        }
        description={setting.description}
      />
      {setting.disabled ? (
        <Message
          onClick={_contact}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
        />
      ) : (
        <></>
        // <div style={{ marginTop: 15 }}></div>
      )}
    </Card>
  );
};

const Message = (props) => {
  return (
    <div style={styles.disabled} onClick={props.onClick}>
      준비중 <SmileTwoTone />
      <ContactModal isOpen={props.isModalOpen} setOpen={props.setModalOpen} />
    </div>
  );
};

const styles = {
  disabled: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    marginTop: 40,
    cursor: 'pointer',
  },
  right: {
    float: 'right',
    paddingRight: 24,
  },
  activeBtn: {
    padding: '0px',
    margin: 0,
    blockSize: 'fit-content',
    width: '22%',
  },
};
